import { Box, Flex, Image, Text } from "@chakra-ui/react";
import React from "react";

import Logo from "../../assets/images/final1.svg";

import "./landing.scss";

const LandingPage = () => {
  return (
    <Flex className="landingPage">
      <Box>
        <Image src={Logo} alt="Logo" />
      </Box>
      <Text as="h2">coming soon...</Text>
    </Flex>
  );
};

export default LandingPage;
