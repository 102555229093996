import {
  Box,
  Flex,
  Text,
  Skeleton,
  Image,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import {
  AddPlusIcon,
  Bell,
  Calendar,
  DownArrow,
  Ellipsis,
  Folder,
  LeftArrow,
  Life,
  LinkIcon,
  Plus,
  Search,
  ThemeIcon,
  User,
} from "../../components/Icons";
import { useNavigate } from "react-router-dom";
import { MyContext } from "../../context";
import Logo from "../../assets/images/finlLogo.svg";
import VersionPopup from "../../components/Popups";

const ReviewHeader = ({ title, projectsView }) => {
  const { theme, setTheme } = useContext(MyContext);
  const [openVersionFilter, setOpenVersionFilter] = useState(false);
  const navigate = useNavigate();

  const handleThemeChange = () => {
    if (theme === "dark") {
      setTheme("dark");
    } else {
      setTheme("dark");
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", (eve) => {
      if (eve.keyCode === 27) {
        if (openVersionFilter === true) {
          setOpenVersionFilter(false);
        }
      }
    });
  }, [openVersionFilter]);

  return (
    <Flex
      justify="space-between"
      p="16px 12px"
      borderBottom="prborder.100"
      className="reviewHeader"
    >
      <Flex gap="12px">
        <Box
          p="0 20px 0 8px"
          onClick={() => navigate("/projects")}
          cursor="pointer"
        >
          <Image src={Logo} alt="final" minW={30} minH={30}/>
        </Box>
        <Flex alignItems="center" gap="8px">
          {/* <Box
            className="assetIcon"
            color="#fff"
            _hover={{ backgroundColor: "var(--chakra-colors-pr-300)" }}
            onClick={() => navigate("/")}>
            ←
          </Box> */}
          <Skeleton isLoaded>
            <Flex className="assetName">
              {/* <Text as="p">
                Project name <Text as="span">^</Text>{" "}
              </Text> */}
              <Text as="h3" color="pr.400">
                D8_ATL_Exterior_Performance
              </Text>
              <Box className="breadCrumPointer">
                <Text as="span" color="pr.700">
                  →
                </Text>
              </Box>
              <Text as="p" color="pr.300">
                Exterior
              </Text>
            </Flex>
          </Skeleton>
          <Text
            fontSize="16px"
            lineHeight="18px"
            letterSpacing="0.00em"
            color="pr.300"
          >
            {title}
          </Text>
        </Flex>

        {/* <Box w="2px" alignSelf="stretch" backgroundColor="#CACACA"></Box> */}
        {/* <Flex gap="12px" alignItems="center">
          <Flex alignItems="center">
            <Text
              as="span"
              className="profileIcon"
              backgroundColor="#7B7B7B"></Text>
            <Text
              as="span"
              backgroundColor="#B8B7B7"
              className="profileIcon"></Text>
            <Text
              as="span"
              backgroundColor="#CD6969"
              className="profileIcon"></Text>
            <Text
              as="span"
              backgroundColor="#CF95E3"
              className="profileIcon"></Text>
          </Flex>
          <Skeleton isLoaded>
            <Flex
              className="assetIconFlex"
              _hover={{ backgroundColor: "var(--chakra-colors-pr-300)" }}>
              <Text
                fontSize="13px"
                lineHeight="13px"
                letterSpacing="0.00em"
                color="grey.800">
                Share
              </Text>
              <Plus w="7px" h="7px" color="grey.800" />
            </Flex>
          </Skeleton>
        </Flex> */}
      </Flex>

      {projectsView === "list" ? (
        <Flex alignItems="center" gap="8px">
          <Flex
            className="assetIconFlex"
            _hover={{ backgroundColor: "pr.200" }}
          >
            <User w="11px" h="12px" color="pr.300" />
            <Text as="h3" fontSize="12px" letterSpacing="0.36px" color="pr.300">
              4
            </Text>
            <DownArrow w="9px" h="5px" color="pr.300" />
          </Flex>
          <Text className="assetState">In review</Text>
          <Flex
            p="8px 0 8px 8px"
            position="relative"
            // _hover={{ backgroundColor: "pr.200" }}
          >
            <Flex
              className="assetIconFlex"
              onClick={() => setOpenVersionFilter(!openVersionFilter)}
            >
              <Text
                as="h3"
                fontSize="12px"
                letterSpacing="0.36px"
                lineHeight={1}
                color="pr.800"
              >
                V2
              </Text>
              <DownArrow w="9px" h="5px" color="pr.800" />
            </Flex>
            {openVersionFilter ? (
              <VersionPopup setOpenVersionFilter={setOpenVersionFilter} />
            ) : (
              ""
            )}
          </Flex>
          <Flex as="h3" className="assetVersionUpdate" color="pr.300">
            <Text as="span"></Text> There is new version available
          </Flex>
        </Flex>
      ) : (
        <Flex className="breadCrum">
          <UnorderedList pt="0" lineHeight="normal">
            <ListItem color="pr.300">
              All
              <span>100</span>
            </ListItem>
            <ListItem color="pr.300">
              Exterior
              <span>10</span>
            </ListItem>
            <ListItem color="pr.300" className="activeFilter">
              Interior
              <span>10</span>
            </ListItem>
            <ListItem color="pr.300">
              360
              <span>10</span>
            </ListItem>
            <ListItem color="pr.300">
              ATL
              <span>10</span>
            </ListItem>
            <ListItem color="pr.300">
              Feature shots
              <span>10</span>
            </ListItem>
            <ListItem color="pr.300">
              Exterior
              <span>10</span>
            </ListItem>
            <ListItem color="pr.300">
              Exterior
              <span>10</span>
            </ListItem>
          </UnorderedList>
          <UnorderedList m="0">
            <ListItem className="addIconFilter" p="0">
              {/* <AddIcon /> */}
              <Flex className="addIcon" w="20px" h="20px">
                {/* <Box as="label">{"+"}</Box> */}
                <AddPlusIcon />
              </Flex>
              <span>&nbsp;</span>
            </ListItem>
          </UnorderedList>
        </Flex>
      )}

      {/* <Flex align="center" gap="8px">
        </Flex> */}
      <Flex alignItems="center" gap="8px">
        <Box
          onClick={() => {
            if (theme === "dark") {
              setTheme("light");
            } else {
              setTheme("dark");
            }
          }}
          cursor="pointer"
        >
          <ThemeIcon color="pr.300" />
        </Box>
        <Skeleton isLoaded>
          <Flex
            className="assetIconFlex"
            _hover={{ backgroundColor: "pr.200" }}
          >
            <Text as="h3" color="pr.300">
              Awaiting approval <Text as="span"></Text>
            </Text>
            <Text as="p">(3)</Text>
          </Flex>
        </Skeleton>
        {/* <Box w="2px" alignSelf="stretch" backgroundColor="#CACACA"></Box> */}
        <Flex alignItems="center" gap="12px">
          {/* <Box
            className="assetIcon"
            _hover={{ backgroundColor: "pr.200" }}>
            <Search w="10px" h="9px" color="grey.800" />
          </Box> */}
          <Flex className="assetIconFlex">
            <Calendar w="11px" h="12px" color="pr.800" />
            <Text as="h3" color="pr.300">
              Due: 23 Apr, 2023
            </Text>
          </Flex>
          {/* <Box
            className="assetIcon"
            _hover={{ backgroundColor: "pr.200" }}>
            <Bell w="9px" h="9px" color="grey.800" />
          </Box> */}
          {/* <Box
            className="assetIcon"
            _hover={{ backgroundColor: "var(--chakra-colors-pr-300)" }}>
            <Life w="8px" h="9px" color="grey.800" />
          </Box> */}
          {/* <Box
            className="assetIcon"
            _hover={{ backgroundColor: "var(--chakra-colors-pr-300)" }}
            onClick={handleThemeChange}>
            <ThemeIcon w="7px" h="10px" color="grey.800" />
          </Box> */}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ReviewHeader;
