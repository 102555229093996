import React, { createContext, useState } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export const MyContext = createContext();

const Context = ({ children }) => {
  const [theme, setTheme] = useState("dark");

  const staggeredCards = (classname, delay = 0) => {
    ScrollTrigger.batch(classname, {
      onRefresh: (batch) =>
        gsap.to(batch, { opacity: 1, stagger: { each: 0.015, delay: delay } }),
    });
    // const container = cardContainer?.current;
    // if (!container) {
    //   return;
    // }

    // const cards = Array.from(container.querySelectorAll(classname));

    // ScrollTrigger.batch(cards, {
    //   scroller: container, // Set the container as the scroller
    //   onEnter: (batch) => {
    //     gsap.to(batch, {
    //       opacity: 1,
    //       autoAlpha: 1,
    //       stagger: 0.015,
    //       ease: "sine.inOut",
    //       delay: delay,
    //     });
    //   },
    //   start: "20% 100%",
    // });
  };

  return (
    <MyContext.Provider value={{ theme, setTheme, staggeredCards }}>
      {children}
    </MyContext.Provider>
  );
};

export default Context;
