import React, { createContext, useContext, useState } from "react";
import { useParams } from "react-router";
import axios from "axios";
import { v1 as uuid } from "uuid";
import useFinalAxiosWrapper from "../utils/FinalApi";
import {
  ASSET_DETAILS_API,
  GET_COMMENTS_BY_ASSET_API,
  GET_ANNOTATIONS_BY_ASSET_API,
  CREATE_COMMENT_API,
  CREATE_ANNOTATION_API,
} from "../constants/url";
import { ProjectContext } from "./ProjectContext";

export const Selected3dModelContext = createContext();

export const Selected3dModelContextProvider = ({ children }) => {
  const { post } = useFinalAxiosWrapper();
  const { assetId } = useParams();
  const [assetDetails, setAssetDetails] = React.useState(null);
  const { setSelectedAsset } = useContext(ProjectContext);

  //new Annotation states
  const [newAnnotation, setNewAnnotation] = useState([]);
  const [annotationToShow, setAnnotationToShow] = useState(null);
  const [markersToShow, setMarkersToShow] = useState([]);

  // Api states
  const [selectedCommentIndex, setSelectedCommentIndex] = useState(null);
  const [allComments, setAllComments] = useState([]);
  const [selectedComment, setSelectedComment] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [allAnnotations, setAllAnnotations] = useState([]);
  const [newComment, setNewComment] = useState(null);
  const [replyComment, setReplyComment] = useState("");
  const [selectedColor, setSelectedColor] = useState({
    color: "red",
    code: "#FF0000",
  });

  const fetchAssetData = React.useCallback(async () => {
    const response = await post(ASSET_DETAILS_API, {
      assetId: assetId,
    });
    const assetData = await response.data.asset;
    setAssetDetails(assetData);
    setSelectedAsset(assetData);
  }, [assetId]);

  const fetchComments = React.useCallback(async () => {
    const response = await post(GET_COMMENTS_BY_ASSET_API, {
      assetId: assetId,
    });
    const comments = await response.data.comments;
    setAllComments(comments);
  }, [assetId]);

  const fetchAnnotations = React.useCallback(async () => {
    const response = await post(GET_ANNOTATIONS_BY_ASSET_API, {
      assetId: assetId,
    });
    const annotations = await response.data.annotations;
    const updatedAnnotations = annotations?.map((annotation) => {
      return { annotation: annotation.annotation[0], id: annotation._id };
    });
    const markers = annotations?.map((annotation) => {
      return {
        position: {
          x: annotation.position.x,
          y: annotation.position.y,
          z: annotation.position.z,
        },
        id: annotation._id,
        normal: {
          x: annotation.annotation[0].x,
          y: annotation.annotation[0].y,
          z: annotation.annotation[0].z,
        },
        uv: null,
        color: annotation.annotation[0].color,
      };
    });
    setAllAnnotations(updatedAnnotations);
    setMarkersToShow(markers);
  }, [assetId]);

  const addComment = async (annotationId) => {
    let commentObj = {
      assetId: assetId,
      annotationId: annotationId || uuid(),
      comment: newComment,
      createdBy: "cc314db7-1d2b-4199-83f4-12622b044d20",
      replies: [],
    };

    if (newComment) {
      await post(CREATE_COMMENT_API, commentObj).then(() => {
        setNewAnnotation([]);
        setMarkersToShow(null);
        fetchComments();
        fetchAnnotations();
      });
    }
  };

  const addAnnotation = async () => {
    if (newComment && annotationToShow) {
      const response = await post(CREATE_ANNOTATION_API, {
        assetId: assetId,
        createdBy: "8da9da6b-5bcc-424c-b677-338c8e21942f",
        position: {
          x: annotationToShow.position.x,
          y: annotationToShow.position.y,
          z: annotationToShow.position.z,
        },
        annotation: [
          {
            x: annotationToShow.normal.x,
            y: annotationToShow.normal.y,
            z: annotationToShow.normal.z,
            color: selectedColor.code,
          },
        ],
        _id: uuid(),
      });
      const annotationData = await response.data;
      addComment(annotationData._id);
    }
  };

  const addReply = async () => {
    let commentObj = {
      comment: replyComment,
      annotation: selectedComment.annotation,
      parentId: selectedComment._id,
      createdBy: "cc314db7-1d2b-4199-83f4-12622b044d20",
    };

    if (replyComment) {
      await post(CREATE_COMMENT_API, commentObj).then(() => {
        fetchComments();
        setReplyComment("");
      });
    }
    return;
  };

  React.useEffect(() => {
    if (!selectedComment) {
      setSelectedMarker(null);
    }
    const selectedMarker = markersToShow.find(
      (marker) => marker.id === selectedComment?.annotation
    );
    setSelectedMarker(selectedMarker);
  }, [selectedComment]);

  React.useEffect(() => {
    fetchAssetData();
    fetchComments();
    fetchAnnotations();
  }, [fetchAssetData]);

  React.useEffect(() => {
    if (newAnnotation?.length > 1) {
      setAnnotationToShow(newAnnotation[newAnnotation.length - 1]);
    } else if (newAnnotation.length) {
      setAnnotationToShow(newAnnotation[0]);
    }
  }, [newAnnotation]);

  return (
    <Selected3dModelContext.Provider
      value={{
        selectedAssetId: assetDetails?._id,
        assetDetails,
        newAnnotation,
        setNewAnnotation,
        newComment,
        setNewComment,
        selectedColor,
        setSelectedColor,
        annotationToShow,
        allComments,
        setAllComments,
        addAnnotation,
        selectedComment,
        setSelectedComment,
        selectedMarker,
        setSelectedCommentIndex,
        selectedCommentIndex,
        setSelectedMarker,
        replyComment,
        setReplyComment,
        addReply,
      }}
    >
      {children}
    </Selected3dModelContext.Provider>
  );
};
