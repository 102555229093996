import {
  Box,
  Flex,
  Image,
  ListItem,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import React, { useState, useContext } from "react";
import { ProjectContext } from "../../context/ProjectContext";
import {
  AddIcon,
  AddPlusIcon,
  FolderIcon,
  GridviewIcon,
  LeftArrow,
  ListviewIcon,
  PlusSvg,
  SortIcon,
} from "../../components/Icons";
import {
  GET_ASSETS_BY_PROJECT_API,
  GET_PROJECTS_BY_OWNER_API,
} from "../../constants/url";
import useFinalAxiosWrapper from "../../utils/FinalApi";
import AddProjectContainer from "../../components/AddProject/AddProjectContainer";

const ProjectSidebar = ({
  setCollapseSidebar,
  collapseSidebar,
  openFilter,
  setOpenFilter,
  projectsView,
  setProjectsView,
  projects,
}) => {
  const { selectedProject, setSelectedProject } = useContext(ProjectContext);
  const [openSideBar, setOpenSideBar] = useState(true);
  const [selectedFilter, setSelectedFilter] = useState({
    name: "All",
    count: null,
  });
  const [showImageUpload, setShowImageUpload] = useState(false);
  const [showAddProjectModal, setShowAddProjectModal] = useState(false);
  const [openChildList, setOpenChildList] = useState("");

  const { get, post } = useFinalAxiosWrapper();

  const handleSelectFilter = (val, length) => {
    setOpenFilter(false);
    setSelectedFilter({ name: val, count: length });
  };

  const onAddProjectClose = () => {
    setShowAddProjectModal(false);
  };

  return (
    <Box
      className={collapseSidebar ? "sidebar collapse" : "sidebar"}
      // borderRight="prborder.100"
    >
      {/* <Box
        className="sidebarCollapseIcon"
        onClick={() => setCollapseSidebar(!collapseSidebar)}>
        <Text as="span">{"<"}</Text>
      </Box> */}
      {collapseSidebar ? (
        ""
      ) : (
        <>
          <Flex className="sidebarHeading">
            {/* <Text as="h3">Projects</Text>
            <Text as="p">+</Text> */}
            <Text
              as="span"
              className="mobileProjectNameBack"
              onClick={() => {
                setOpenSideBar(true);
              }}
            >
              {"←"}
            </Text>
            {projects?.length ? (
              <>
                <Flex as="h2">
                  {/* {selectedProject}{" "} */}
                  {/* <Text
                  as="span"
                  style={{
                    transform: openFilter ? "rotateX(180deg)" : "rotateY(0deg)",
                  }}>
                  {"^"}
                </Text> */}
                </Flex>
                <Flex className="projectFooterFiles">
                  <Flex
                    className={
                      projectsView === "list"
                        ? "projectFooterViews activeView"
                        : "projectFooterViews"
                    }
                    onClick={() => setProjectsView("list")}
                  >
                    <ListviewIcon />
                  </Flex>
                  <Flex
                    className={
                      projectsView === "grid"
                        ? "projectFooterViews activeView"
                        : "projectFooterViews"
                    }
                    onClick={() => setProjectsView("grid")}
                  >
                    <GridviewIcon />
                  </Flex>
                </Flex>
              </>
            ) : (
              ""
            )}
          </Flex>
          <Box className="sidebarFilters">
            <Flex className="sidebarFiltered">
              <Flex
                as="h2"
                onClick={() => setOpenFilter(!openFilter)}
                color="pr.300"
              >
                {selectedFilter.name}
                <sup>10</sup>
                <span
                  className="chakra-text css-0"
                  style={{
                    transform: openFilter ? "rotateX(180deg)" : "rotateX(0deg)",
                  }}
                >
                  ^
                </span>
              </Flex>
              <Flex as="h4" className="projectFilesTitle">
                Name{" "}
                <Text as="span">
                  <SortIcon />
                </Text>
              </Flex>
            </Flex>
            <Flex
              className={openFilter ? "breadCrum activeBreadcrum" : "breadCrum"}
            >
              <Text as="h2">KIA Seltos Facelift 2023</Text>
              <Box className="breadCrumPointer">
                <Text as="span">→</Text>
              </Box>
              <UnorderedList>
                <ListItem
                  color="pr.500"
                  onClick={() => handleSelectFilter("Exterior", "10")}
                >
                  Exterior
                  <span>10</span>
                </ListItem>
                <ListItem
                  color="pr.500"
                  onClick={() => handleSelectFilter("Interior", "10")}
                >
                  Interior
                  <span>10</span>
                </ListItem>
                <ListItem
                  color="pr.500"
                  onClick={() => handleSelectFilter("360", "10")}
                >
                  360
                  <span>10</span>
                </ListItem>
                <ListItem
                  color="pr.500"
                  onClick={() => handleSelectFilter("ATL", "10")}
                >
                  ATL
                  <span>10</span>
                </ListItem>
                <ListItem
                  color="pr.500"
                  onClick={() => handleSelectFilter("Feature shots", "10")}
                >
                  Feature shots
                  <span>10</span>
                </ListItem>
                <ListItem
                  color="pr.500"
                  onClick={() => handleSelectFilter("Exterior", "10")}
                >
                  Exterior
                  <span>10</span>
                </ListItem>
                <ListItem
                  color="pr.500"
                  onClick={() => handleSelectFilter("Exterior", "10")}
                >
                  Exterior
                  <span>10</span>
                </ListItem>
              </UnorderedList>
            </Flex>
          </Box>
          <Box
            className={
              openSideBar ? "projectsList activeSidebar" : "projectsList"
            }
            // backgroundColor="pr.100"
          >
            <Flex className="projectsListHeading">
              <Text as="h4" color="#9A9A9A">
                Projects
              </Text>
              {/* <Flex as="p">
                Name{" "}
                <Text as="span">
                  <SortIcon />
                </Text>
              </Flex> */}
            </Flex>
            {/* <Flex
              className="projectsListAdd"
              onClick={() => {
                setShowAddProjectModal(true);
              }}
            >
              <Text as="h5" color="#9F9F9F">
                {"+"}
              </Text>
            </Flex> */}
            <UnorderedList className="projectsListContainer">
              {projects.map((project) => (
                <ListItem
                  color={
                    selectedProject?._id === project._id
                      ? "newPr.1300"
                      : "newPr.900"
                  }
                  backgroundColor={
                    selectedProject?._id === project._id
                      ? "newPr.1400"
                      : "newPr.1100"
                  }
                  fontWeight={selectedProject?._id === project._id ? 700 : 400}
                  _hover={{
                    backgroundColor: "newPr.1400",
                  }}
                  key={project._id}
                  onClick={() => {
                    setSelectedProject(project);
                    setOpenSideBar(false);
                    setOpenChildList(project?._id);
                    // if (openChildList !== project?._id) {
                    // } else {
                    //   setOpenChildList("");
                    // }
                  }}
                >
                  <Text>
                    {project.name}
                    {/* <Text
                      as="label"
                    >
                      {">"}
                    </Text> */}
                  </Text>

                  {/* <Text as="span" color="#9F9F9F">
                    {"+"}
                  </Text> */}
                  <UnorderedList
                    className="projectsListChild"
                    h={selectedProject?._id === project?._id ? "100%" : 0}
                  >
                    <ListItem color="newPr.900">
                      <FolderIcon color="newPr.900" /> Leaking Sink
                      <Text as="sup"> 05</Text>
                    </ListItem>
                    <ListItem color="newPr.900">
                      <FolderIcon color="newPr.900" /> WC - Feviseal
                      <Text as="sup"> 10</Text>
                    </ListItem>
                    <ListItem color="newPr.900">
                      <FolderIcon color="newPr.900" />
                      Wash Basin Gap Filling - Feviseal
                      <Text as="sup"> 12</Text>
                    </ListItem>
                    <ListItem color="newPr.900">
                      <FolderIcon color="newPr.900" /> Plumber - Feviseal
                      <Text as="sup"> 03</Text>
                    </ListItem>
                    <ListItem color="newPr.900">
                      <FolderIcon color="newPr.900" /> Home Owner - Feviseal
                      <Text as="sup"> 08</Text>
                    </ListItem>
                    <ListItem color="newPr.900">
                      <FolderIcon color="newPr.900" /> Leaking Sink
                      <Text as="sup"> 16</Text>
                    </ListItem>
                    <ListItem color="newPr.900">
                      <FolderIcon color="newPr.900" /> WC - Feviseal
                      <Text as="sup"> 10</Text>
                    </ListItem>
                    <ListItem color="newPr.900">
                      <FolderIcon color="newPr.900" />
                      Wash Basin Gap Filling - Feviseal
                      <Text as="sup"> 11</Text>
                    </ListItem>
                  </UnorderedList>
                </ListItem>
              ))}
              {/* <ListItem
                w="20px"
                h="20px"
                className="addIcon"
                onClick={() => {
                  setShowAddProjectModal(true);
                }}
              >
                <AddPlusIcon />
              </ListItem> */}
            </UnorderedList>
          </Box>

          {showAddProjectModal ? (
            <Box>
              <AddProjectContainer
                onClose={onAddProjectClose}
                // fetchProjects={fetchProjects}
              />
            </Box>
          ) : null}
        </>
      )}
    </Box>
  );
};

export default ProjectSidebar;
