import React, { createContext } from "react";
import { v1 as uuid } from "uuid";
import { useParams } from "react-router";
import useFinalAxiosWrapper from "../utils/FinalApi";
import {
  ASSET_DETAILS_API,
  CREATE_ANNOTATION_API,
  CREATE_COMMENT_API,
  GET_ANNOTATIONS_BY_ASSET_API,
  GET_COMMENTS_BY_ASSET_API,
} from "../constants/url";
import { ProjectContext } from "./ProjectContext";

export const SelectedVideoContext = createContext();

export const SelectedVideoContextProvider = ({ children }) => {
  const { post } = useFinalAxiosWrapper();
  const { videoId } = useParams();
  const [assetDetails, setAssetDetails] = React.useState(null);
  const [allAnnotations, setAllAnnotations] = React.useState([]);
  const [allComments, setAllComments] = React.useState([]);

  const [timeStamp, setTimeStamp] = React.useState(null);
  const [latestArrow, setLatestArrow] = React.useState(null);
  const [latestLine, setLatestLine] = React.useState(null);
  const [latestMarker, setLatestMarker] = React.useState(null);
  const [markers, setMarkers] = React.useState([]);
  const [lines, setLines] = React.useState([]);
  const [arrowAnnotation, setArrowAnnotation] = React.useState([]);
  const [selectedComment, setSelectedComment] = React.useState(null);
  const [selectedAnnotation, setSelectedAnnotation] = React.useState(null);

  const [isPlaying, setIsPlaying] = React.useState(false);
  const [mainPlayer, setMainPlayer] = React.useState({});

  const [selectedMode, setSelectedMode] = React.useState("pen");
  const [newComment, setNewComment] = React.useState("");
  const [replyComment, setReplyComment] = React.useState("");
  const [selectedColor, setSelectedColor] = React.useState("#FF0000");

  const [selectedCommentIndex, setSelectedCommentIndex] = React.useState(null);

  const { setSelectedAsset } = React.useContext(ProjectContext);

  React.useEffect(() => {
    if (selectedComment) {
      console.log("selectedComment", selectedComment);
      //
      if (mainPlayer.current) {
        // Start video if required
        if (mainPlayer.current.hasStarted() === false) {
          mainPlayer.current.hasStarted(true);
          // Show konvajs-content parent
          let konvaContent = document.getElementsByClassName("konvajs-content");
          let konvaContentParent = konvaContent[0].parentElement;
          konvaContentParent.style.display = "block";
        }
        // Pause video
        mainPlayer.current.pause();
      }
      //
      const annotationsToShow = allAnnotations.filter(
        (annotation) => annotation.id === selectedComment.annotation
      );
      const updatedSelectedAnnotation = {
        ...annotationsToShow[0].annotation,
        marker: annotationsToShow[0].position,
        timeStart: annotationsToShow[0].timeStart,
      };
      setSelectedAnnotation(updatedSelectedAnnotation);
      //
    } else {
      setSelectedAnnotation(null);
    }
  }, [allAnnotations, selectedComment]);

  const fetchAssetData = React.useCallback(async () => {
    const response = await post(ASSET_DETAILS_API, {
      assetId: videoId,
    });
    const assetData = await response.data.asset;
    setAssetDetails(assetData);
    setSelectedAsset(assetData);
  }, [videoId]);

  const fetchComments = React.useCallback(async () => {
    const response = await post(GET_COMMENTS_BY_ASSET_API, {
      assetId: videoId,
    });
    const comments = await response.data.comments;
    setAllComments(comments);
  }, [videoId]);

  const fetchAnnotations = React.useCallback(async () => {
    const response = await post(GET_ANNOTATIONS_BY_ASSET_API, {
      assetId: videoId,
    });
    const annotations = await response.data.annotations;
    const updatedAnnotations = annotations?.map((annotation) => {
      return {
        annotation: annotation.annotation[0],
        id: annotation._id,
        timeStart: annotation.timeStart,
        marker: annotation.position,
      };
    });
    setAllAnnotations(updatedAnnotations);
  }, [videoId]);

  const addComment = async (annotaionId) => {
    let commentObj = {
      assetId: videoId,
      annotationId: annotaionId || null,
      comment: newComment,
      createdBy: "cc314db7-1d2b-4199-83f4-12622b044d20",
      replies: [],
    };

    if (newComment) {
      await post(CREATE_COMMENT_API, commentObj).then(() => {
        setLatestMarker(null);
        setMarkers([]);
        setLines([]);
        setArrowAnnotation([]);
        setTimeStamp(null);
        setLatestLine(null);
        setNewComment(null);
        setLatestArrow(null);
        fetchComments();
        fetchAnnotations();
      });
    }
  };

  const addAnnotation = async () => {
    if (newComment) {
      const arrowAnnotations = arrowAnnotation.map((arrow) => {
        return arrow.points;
      });
      const markerPos = latestMarker.position;
      const updatedLines = lines.filter((line) => {
        if (line) {
          return true;
        }
        return false;
      });
      //
      const response = await post(CREATE_ANNOTATION_API, {
        assetId: videoId,
        createdBy: "8da9da6b-5bcc-424c-b677-338c8e21942f",
        position: markerPos,
        timeStart: timeStamp,
        annotation: {
          lines: updatedLines,
          arrows: { points: arrowAnnotations },
        },
        _id: uuid(),
      });
      const annotationData = await response.data;
      addComment(annotationData._id);
      //
    }
  };

  const addReply = async () => {
    let commentObj = {
      comment: replyComment,
      annotation: selectedComment.annotation,
      parentId: selectedComment._id,
      createdBy: "cc314db7-1d2b-4199-83f4-12622b044d20",
    };

    if (replyComment) {
      await post(CREATE_COMMENT_API, commentObj).then(() => {
        fetchComments();
        setReplyComment("");
      });
    }
    return;
  };

  React.useEffect(() => {
    fetchAssetData();
    fetchComments();
    fetchAnnotations();
  }, [fetchAnnotations, fetchAssetData, fetchComments]);
  return (
    <SelectedVideoContext.Provider
      value={{
        assetDetails,
        allAnnotations,
        allComments,
        selectedColor,
        setSelectedColor,
        selectedMode,
        setSelectedMode,
        timeStamp,
        setTimeStamp,
        latestArrow,
        setLatestArrow,
        latestLine,
        setLatestLine,
        latestMarker,
        setLatestMarker,
        markers,
        setMarkers,
        lines,
        setLines,
        arrowAnnotation,
        setArrowAnnotation,
        newComment,
        setNewComment,
        addAnnotation,
        setSelectedComment,
        selectedComment,
        replyComment,
        setReplyComment,
        addReply,
        selectedAnnotation,
        setSelectedAnnotation,
        selectedCommentIndex,
        setSelectedCommentIndex,
        isPlaying,
        setIsPlaying,
        mainPlayer,
        setMainPlayer,
      }}
    >
      {children}
    </SelectedVideoContext.Provider>
  );
};
