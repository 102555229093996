import { createIcon } from "@chakra-ui/icons";

export const Folder = createIcon({
  viewBox: "0 0 10 7",
  path: (
    <path
      d="M8.9375 3.73438L7.4375 6.73438C7.35938 6.90625 7.1875 7 6.98438 7H1C0.4375 7 0 6.5625 0 6V1C0 0.453125 0.4375 0 1 0H2.82812C3.09375 0 3.34375 0.109375 3.53125 0.296875L4.29688 1H6.5C7.04688 1 7.5 1.45312 7.5 2V2.5H6.75V2C6.75 1.875 6.625 1.75 6.5 1.75H4L3 0.828125C2.95312 0.78125 2.89062 0.75 2.82812 0.75H1C0.859375 0.75 0.75 0.875 0.75 1V5.5L1.85938 3.28125C1.9375 3.10938 2.10938 3 2.29688 3H8.5C8.85938 3 9.10938 3.39062 8.9375 3.73438Z"
      fill="currentColor"
    />
  ),
});

export const LeftArrow = createIcon({
  viewBox: "0 0 6 8",
  path: (
    <path
      d="M4.5 8C4.35938 8 4.23438 7.95312 4.14062 7.85938L1.14062 4.85938C0.9375 4.67188 0.9375 4.34375 1.14062 4.15625L4.14062 1.15625C4.32812 0.953125 4.65625 0.953125 4.84375 1.15625C5.04688 1.34375 5.04688 1.67188 4.84375 1.85938L2.20312 4.5L4.84375 7.15625C5.04688 7.34375 5.04688 7.67188 4.84375 7.85938C4.75 7.95312 4.625 8 4.5 8Z"
      fill="currentColor"
    />
  ),
});

export const DownArrow = createIcon({
  viewBox: "0 0 9 5",
  path: (
    <path
      d="M4.5 5C4.35938 5 4.23438 4.95312 4.14062 4.85938L1.14062 1.85938C0.9375 1.67188 0.9375 1.34375 1.14062 1.15625C1.32812 0.953125 1.65625 0.953125 1.84375 1.15625L4.5 3.79688L7.14062 1.15625C7.32812 0.953125 7.65625 0.953125 7.84375 1.15625C8.04688 1.34375 8.04688 1.67188 7.84375 1.85938L4.84375 4.85938C4.75 4.95312 4.625 5 4.5 5Z"
      fill="currentColor"
    />
  ),
});

export const LinkIcon = createIcon({
  viewBox: "0 0 10 9",
  path: (
    <path
      d="M2.6875 2.5625C3.5625 1.6875 5 1.6875 5.875 2.5625C6.65625 3.34375 6.76562 4.57812 6.125 5.48438L6.10938 5.5C5.95312 5.73438 5.64062 5.78125 5.40625 5.625C5.1875 5.45312 5.125 5.14062 5.29688 4.92188L5.3125 4.90625C5.67188 4.39062 5.60938 3.71875 5.17188 3.28125C4.6875 2.78125 3.89062 2.78125 3.39062 3.28125L1.64062 5.03125C1.14062 5.51562 1.14062 6.3125 1.64062 6.8125C2.07812 7.25 2.76562 7.29688 3.26562 6.95312L3.28125 6.92188C3.51562 6.76562 3.82812 6.8125 3.98438 7.04688C4.14062 7.26562 4.09375 7.57812 3.875 7.75L3.84375 7.76562C2.9375 8.40625 1.71875 8.29688 0.9375 7.51562C0.046875 6.64062 0.046875 5.20312 0.9375 4.32812L2.6875 2.5625ZM7.29688 6.4375C6.42188 7.32812 4.98438 7.32812 4.10938 6.4375C3.32812 5.65625 3.21875 4.4375 3.85938 3.53125L3.875 3.51562C4.03125 3.28125 4.34375 3.23438 4.57812 3.39062C4.79688 3.54688 4.85938 3.85938 4.6875 4.09375L4.67188 4.10938C4.3125 4.60938 4.375 5.29688 4.8125 5.73438C5.29688 6.23438 6.09375 6.23438 6.59375 5.73438L8.34375 3.98438C8.84375 3.48438 8.84375 2.6875 8.34375 2.20312C7.90625 1.76562 7.21875 1.70312 6.71875 2.0625L6.70312 2.07812C6.46875 2.25 6.15625 2.1875 6 1.96875C5.84375 1.75 5.89062 1.4375 6.10938 1.26562L6.14062 1.25C7.04688 0.609375 8.26562 0.71875 9.04688 1.5C9.9375 2.375 9.9375 3.8125 9.04688 4.6875L7.29688 6.4375Z"
      fill="currentColor"
    />
  ),
});

export const Ellipsis = createIcon({
  viewBox: "0 0 11 3",
  path: (
    <path
      d="M7.875 1.5C7.875 0.890625 8.36719 0.375 9 0.375C9.60938 0.375 10.125 0.890625 10.125 1.5C10.125 2.13281 9.60938 2.625 9 2.625C8.36719 2.625 7.875 2.13281 7.875 1.5ZM4.125 1.5C4.125 0.890625 4.61719 0.375 5.25 0.375C5.85938 0.375 6.375 0.890625 6.375 1.5C6.375 2.13281 5.85938 2.625 5.25 2.625C4.61719 2.625 4.125 2.13281 4.125 1.5ZM2.625 1.5C2.625 2.13281 2.10938 2.625 1.5 2.625C0.867188 2.625 0.375 2.13281 0.375 1.5C0.375 0.890625 0.867188 0.375 1.5 0.375C2.10938 0.375 2.625 0.890625 2.625 1.5Z"
      fill="currentColor"
    />
  ),
});

export const Plus = createIcon({
  viewBox: "0 0 7 7",
  path: (
    <path
      d="M6.75 3.5C6.75 3.78125 6.51562 4.01562 6.25 4.01562H4V6.26562C4 6.53125 3.76562 6.75 3.5 6.75C3.21875 6.75 3 6.53125 3 6.26562V4.01562H0.75C0.46875 4.01562 0.25 3.78125 0.25 3.5C0.25 3.23438 0.46875 3.01562 0.75 3.01562H3V0.765625C3 0.484375 3.21875 0.25 3.5 0.25C3.76562 0.25 4 0.484375 4 0.765625V3.01562H6.25C6.51562 3 6.75 3.23438 6.75 3.5Z"
      fill="currentColor"
    />
  ),
});

export const Search = createIcon({
  viewBox: "0 0 10 9",
  path: (
    <path
      d="M8.8125 7.4375C9.04688 7.6875 9.04688 8.07812 8.8125 8.32812C8.5625 8.5625 8.17188 8.5625 7.92188 8.32812L6.0625 6.45312C5.42188 6.875 4.64062 7.09375 3.79688 6.98438C2.35938 6.78125 1.20312 5.60938 1.01562 4.1875C0.75 2.0625 2.54688 0.265625 4.67188 0.53125C6.09375 0.71875 7.26562 1.875 7.46875 3.3125C7.57812 4.15625 7.35938 4.9375 6.9375 5.5625L8.8125 7.4375ZM2.23438 3.75C2.23438 4.85938 3.125 5.75 4.23438 5.75C5.32812 5.75 6.23438 4.85938 6.23438 3.75C6.23438 2.65625 5.32812 1.75 4.23438 1.75C3.125 1.75 2.23438 2.65625 2.23438 3.75Z"
      fill="currentColor"
    />
  ),
});

export const Bell = createIcon({
  viewBox: "0 0 9 9",
  path: (
    <path
      d="M5 1V1.3125C6.14062 1.54688 7 2.54688 7 3.75V4.04688C7 4.78125 7.26562 5.5 7.75 6.04688L7.85938 6.17188C8 6.32812 8.03125 6.53125 7.95312 6.71875C7.875 6.89062 7.6875 7 7.5 7H1.5C1.29688 7 1.10938 6.89062 1.03125 6.71875C0.953125 6.53125 0.984375 6.32812 1.125 6.17188L1.23438 6.04688C1.71875 5.5 2 4.78125 2 4.04688V3.75C2 2.54688 2.84375 1.54688 4 1.3125V1C4 0.734375 4.21875 0.5 4.5 0.5C4.76562 0.5 5 0.734375 5 1ZM4.5 8.5C4.23438 8.5 3.96875 8.40625 3.78125 8.21875C3.59375 8.03125 3.5 7.76562 3.5 7.5H5.5C5.5 7.76562 5.39062 8.03125 5.20312 8.21875C5.01562 8.40625 4.75 8.5 4.5 8.5Z"
      fill="currentColor"
    />
  ),
});

export const Life = createIcon({
  viewBox: "0 0 8 9",
  path: (
    <path
      d="M7.34375 7.15625C7.54688 7.34375 7.54688 7.67188 7.34375 7.85938C7.15625 8.0625 6.82812 8.0625 6.64062 7.85938L6.4375 7.67188C5.76562 8.1875 4.92188 8.5 3.98438 8.5C3.0625 8.5 2.21875 8.1875 1.54688 7.67188L1.34375 7.85938C1.15625 8.0625 0.828125 8.0625 0.640625 7.85938C0.4375 7.67188 0.4375 7.34375 0.640625 7.15625L0.828125 6.95312C0.3125 6.28125 0 5.4375 0 4.5C0 3.57812 0.3125 2.73438 0.828125 2.0625L0.640625 1.85938C0.4375 1.67188 0.4375 1.34375 0.640625 1.15625C0.828125 0.953125 1.15625 0.953125 1.34375 1.15625L1.54688 1.34375C2.21875 0.828125 3.0625 0.5 3.98438 0.5C4.92188 0.5 5.76562 0.828125 6.4375 1.34375L6.64062 1.15625C6.82812 0.953125 7.15625 0.953125 7.34375 1.15625C7.54688 1.34375 7.54688 1.67188 7.34375 1.85938L7.15625 2.0625C7.67188 2.73438 8 3.57812 8 4.5C8 5.4375 7.67188 6.28125 7.15625 6.95312L7.34375 7.15625ZM4.82812 6.04688C4.57812 6.1875 4.29688 6.25 3.98438 6.25C3.6875 6.25 3.40625 6.1875 3.15625 6.04688L2.25 6.95312C2.75 7.29688 3.34375 7.5 3.98438 7.5C4.64062 7.5 5.23438 7.29688 5.73438 6.95312L4.82812 6.04688ZM7 4.5C7 3.85938 6.78125 3.26562 6.4375 2.76562L5.53125 3.67188C5.67188 3.92188 5.75 4.20312 5.75 4.5C5.75 4.8125 5.67188 5.09375 5.53125 5.34375L6.4375 6.25C6.78125 5.75 7 5.15625 7 4.5ZM3.98438 1.5C3.34375 1.5 2.75 1.71875 2.25 2.0625L3.15625 2.96875C3.40625 2.82812 3.6875 2.75 3.98438 2.75C4.29688 2.75 4.57812 2.82812 4.82812 2.96875L5.73438 2.0625C5.23438 1.71875 4.64062 1.5 4 1.5H3.98438ZM2.45312 5.34375C2.3125 5.09375 2.23438 4.8125 2.23438 4.5C2.23438 4.20312 2.3125 3.92188 2.45312 3.67188L1.54688 2.76562C1.20312 3.26562 0.984375 3.85938 0.984375 4.5C0.984375 5.15625 1.20312 5.75 1.54688 6.25L2.45312 5.34375ZM3.98438 3.75C3.57812 3.75 3.23438 4.09375 3.23438 4.5C3.23438 4.92188 3.57812 5.25 3.98438 5.25C4.40625 5.25 4.75 4.92188 4.75 4.5C4.75 4.09375 4.40625 3.75 3.98438 3.75Z"
      fill="currentColor"
    />
  ),
});

export const Comment = createIcon({
  viewBox: "0 0 9 7",
  path: (
    <path
      d="M5 0C7.20312 0 8.98438 1.46875 8.98438 3.25C8.98438 5.04688 7.20312 6.5 5 6.5C4.39062 6.5 3.82812 6.39062 3.3125 6.20312C2.92188 6.51562 2.15625 7 1.125 7C1.0625 7 1.01562 6.98438 1 6.9375C0.984375 6.89062 0.984375 6.82812 1.03125 6.79688C1.03125 6.78125 1.6875 6.09375 1.875 5.29688C1.32812 4.73438 1 4.03125 1 3.25C1 1.46875 2.78125 0 5 0Z"
      fill="currentColor"
    />
  ),
});

export const Tick = createIcon({
  viewBox: "0 0 9 7",
  path: (
    <path
      d="M7.84375 1.15625C8.04688 1.34375 8.04688 1.67188 7.84375 1.85938L3.84375 5.85938C3.65625 6.0625 3.32812 6.0625 3.14062 5.85938L1.14062 3.85938C0.9375 3.67188 0.9375 3.34375 1.14062 3.15625C1.32812 2.95312 1.65625 2.95312 1.84375 3.15625L3.48438 4.79688L7.14062 1.15625C7.32812 0.953125 7.65625 0.953125 7.84375 1.15625Z"
      fill="currentColor"
    />
  ),
});

export const Send = createIcon({
  viewBox: "0 0 8 8",
  path: (
    <path
      d="M8 5.34058V7.21558C8 7.43433 7.82812 7.59058 7.625 7.59058C7.40625 7.59058 7.25 7.43433 7.25 7.2312V5.37183C7.25 4.4812 6.51562 3.76245 5.625 3.76245H1.375L3.35938 5.4812C3.51562 5.62183 3.53125 5.8562 3.40625 6.01245C3.32812 6.05933 3.21875 6.09058 3.125 6.09058C3.03125 6.09058 2.9375 6.07495 2.875 6.01245L0.125 3.65308C0.046875 3.55933 0 3.44995 0 3.34058C0 3.2312 0.046875 3.13745 0.125 3.05933L2.875 0.699951C3.01562 0.574951 3.26562 0.590576 3.40625 0.746826C3.53125 0.903076 3.51562 1.13745 3.35938 1.27808L1.375 2.96558H5.625C6.92188 2.96558 8 4.0437 8 5.34058Z"
      fill="currentColor"
    />
  ),
});

export const Scribble = createIcon({
  viewBox: "0 0 13 13",
  path: (
    <path
      d="M6.67969 2.25781C6.375 1.97656 5.90625 1.95312 5.625 2.23438L1.99219 5.5625C1.6875 5.84375 1.21875 5.82031 0.9375 5.51562C0.65625 5.21094 0.679688 4.74219 0.984375 4.46094L4.59375 1.13281C5.48438 0.3125 6.86719 0.335938 7.73438 1.20312C8.60156 2.04688 8.625 3.45312 7.78125 4.34375L3.11719 9.26562C2.97656 9.42969 2.95312 9.6875 3.09375 9.85156C3.25781 10.0625 3.58594 10.0625 3.75 9.875L8.97656 4.39062C9.79688 3.52344 11.1797 3.5 12 4.34375C12.7734 5.11719 12.8438 6.35938 12.1406 7.20312L10.0547 9.6875C9.82031 9.99219 9.84375 10.4141 10.1016 10.6953C10.3594 10.9531 10.7812 10.9766 11.0625 10.7656L11.5312 10.4141C11.8594 10.1562 12.3281 10.2266 12.5859 10.5547C12.8438 10.8828 12.7734 11.3516 12.4453 11.6094L11.9766 11.9609C11.0859 12.6406 9.84375 12.5469 9.04688 11.75C8.22656 10.9297 8.15625 9.61719 8.90625 8.72656L10.9922 6.24219C11.2031 5.98438 11.1797 5.63281 10.9453 5.39844C10.7109 5.16406 10.3125 5.16406 10.0547 5.42188L4.85156 10.9062C4.03125 11.7734 2.64844 11.7031 1.92188 10.7891C1.3125 10.0391 1.35938 8.9375 2.03906 8.23438L6.67969 3.3125C6.96094 3.00781 6.96094 2.53906 6.67969 2.25781Z"
      fill="currentColor"
    />
  ),
});

export const Square = createIcon({
  viewBox: "0 0 11 11",
  path: (
    <path
      d="M3.28906 10C3.03125 10.4688 2.53906 10.75 2 10.75C1.15625 10.75 0.5 10.0938 0.5 9.25C0.5 8.71094 0.78125 8.21875 1.25 7.96094V3.0625C0.78125 2.80469 0.5 2.3125 0.5 1.75C0.5 0.929688 1.15625 0.25 2 0.25C2.53906 0.25 3.03125 0.554688 3.28906 1H8.1875C8.44531 0.554688 8.9375 0.25 9.5 0.25C10.3203 0.25 11 0.929688 11 1.75C11 2.3125 10.6953 2.80469 10.25 3.0625V7.96094C10.6953 8.21875 11 8.71094 11 9.25C11 10.0938 10.3203 10.75 9.5 10.75C8.9375 10.75 8.44531 10.4688 8.1875 10H3.28906ZM2.75 7.96094C2.96094 8.10156 3.14844 8.28906 3.28906 8.5H8.1875C8.32812 8.28906 8.51562 8.10156 8.75 7.96094V3.0625C8.51562 2.92188 8.32812 2.73438 8.1875 2.5H3.28906C3.14844 2.73438 2.96094 2.92188 2.75 3.0625V7.96094Z"
      fill="currentColor"
    />
  ),
});

export const ArrowRightAngle = createIcon({
  viewBox: "0 0 9 9",
  path: (
    <path
      d="M8.5 1.5V6.75C8.5 7.17188 8.14844 7.5 7.75 7.5C7.32812 7.5 7 7.17188 7 6.75V3.32812L2.26562 8.03906C2.125 8.17969 1.9375 8.25 1.75 8.25C1.53906 8.25 1.35156 8.17969 1.21094 8.03906C0.90625 7.75781 0.90625 7.26562 1.21094 6.98438L5.92188 2.25H2.5C2.07812 2.25 1.75 1.92188 1.75 1.5C1.75 1.10156 2.07812 0.75 2.5 0.75H7.75C8.14844 0.75 8.5 1.10156 8.5 1.5Z"
      fill="currentColor"
    />
  ),
});

export const Pen = createIcon({
  viewBox: "0 0 8 10",
  path: (
    <path
      d="M1.5 9L6.49976 1"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
    />
  ),
});

<svg
  width="7"
  height="7"
  viewBox="0 0 7 7"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
></svg>;

export const ThemeIcon = createIcon({
  viewBox: "0 0 7 7",
  path: (
    <path
      d="M0.5 3.5C0.5 1.57812 2.0625 0 3.98438 0C4.17188 0 4.45312 0.03125 4.625 0.0625C4.78125 0.09375 4.8125 0.296875 4.67188 0.375C3.82812 0.859375 3.29688 1.76562 3.29688 2.75C3.29688 4.46875 4.84375 5.76562 6.54688 5.45312C6.70312 5.42188 6.79688 5.59375 6.70312 5.71875C6.04688 6.51562 5.0625 7 3.98438 7C2.0625 7 0.5 5.4375 0.5 3.5Z"
      fill="currentColor"
    />
  ),
});

export const SortIcon = createIcon({
  viewBox: "0 0 12 12",
  path: (
    <path
      d="M3.50626 5.8C3.05626 5.8 2.81251 5.25625 3.15001 4.91875L5.62501 2.36875C5.71876 2.25625 5.86876 2.2 6.00001 2.2C6.13126 2.2 6.26251 2.25625 6.35626 2.36875L8.83126 4.91875C9.16876 5.25625 8.92501 5.8 8.47501 5.8H3.50626ZM8.47501 7C8.92501 7 9.16876 7.5625 8.83126 7.9L6.35626 10.45C6.26251 10.5625 6.13126 10.6 6.00001 10.6C5.85001 10.6 5.71876 10.5625 5.62501 10.45L3.15001 7.9C2.81251 7.5625 3.05626 7 3.50626 7H8.47501Z"
      fill="currentColor"
    />
  ),
});

export const DownloadIcon = createIcon({
  viewBox: "0 0 12 12",
  path: (
    <path
      d="M9.15 9.7C9.39375 9.7 9.6 9.90625 9.6 10.15C9.6 10.4125 9.39375 10.6 9.15 10.6H2.85C2.5875 10.6 2.4 10.4125 2.4 10.15C2.4 9.8875 2.5875 9.7 2.85 9.7H9.15ZM8.0625 5.5C8.23125 5.3125 8.5125 5.3125 8.7 5.48125C8.8875 5.65 8.8875 5.93125 8.71875 6.11875L6.31875 8.66875C6.15 8.85625 5.83125 8.85625 5.6625 8.66875L3.2625 6.11875C3.09375 5.93125 3.09375 5.65 3.28125 5.48125C3.375 5.40625 3.4875 5.36875 3.6 5.36875C3.7125 5.36875 3.825 5.40625 3.91875 5.5L5.55 7.225V2.66875C5.55 2.40625 5.7375 2.2 6 2.2C6.24375 2.2 6.45 2.40625 6.45 2.66875V7.225L8.0625 5.5Z"
      fill="currentColor"
    />
  ),
});

export const ListviewIcon = createIcon({
  viewBox: "0 0 12 12",
  path: (
    <path
      d="M-2.86102e-06 4C-2.86102e-06 3.34375 0.524997 2.8 1.2 2.8H10.8C11.4562 2.8 12 3.34375 12 4V8.8C12 9.475 11.4562 10 10.8 10H1.2C0.524997 10 -2.86102e-06 9.475 -2.86102e-06 8.8V4Z"
      fill="currentColor"
    />
  ),
});

export const GridviewIcon = createIcon({
  viewBox: "0 0 12 12",
  path: (
    <path
      d="M5.4 4.9C5.4 5.40625 4.9875 5.8 4.5 5.8H2.7C2.19375 5.8 1.8 5.40625 1.8 4.9V3.1C1.8 2.6125 2.19375 2.2 2.7 2.2H4.5C4.9875 2.2 5.4 2.6125 5.4 3.1V4.9ZM5.4 9.7C5.4 10.2062 4.9875 10.6 4.5 10.6H2.7C2.19375 10.6 1.8 10.2062 1.8 9.7V7.9C1.8 7.4125 2.19375 7 2.7 7H4.5C4.9875 7 5.4 7.4125 5.4 7.9V9.7ZM6.6 3.1C6.6 2.6125 6.99375 2.2 7.5 2.2H9.3C9.7875 2.2 10.2 2.6125 10.2 3.1V4.9C10.2 5.40625 9.7875 5.8 9.3 5.8H7.5C6.99375 5.8 6.6 5.40625 6.6 4.9V3.1ZM10.2 9.7C10.2 10.2062 9.7875 10.6 9.3 10.6H7.5C6.99375 10.6 6.6 10.2062 6.6 9.7V7.9C6.6 7.4125 6.99375 7 7.5 7H9.3C9.7875 7 10.2 7.4125 10.2 7.9V9.7Z"
      fill="currentColor"
    />
  ),
});

export const User = createIcon({
  viewBox: "0 0 11 13",
  path: (
    <path
      d="M6.375 7.625C8.64844 7.625 10.5 9.47656 10.5 11.75C10.5 12.1719 10.1484 12.5 9.75 12.5H0.75C0.328125 12.5 0 12.1719 0 11.75C0 9.47656 1.82812 7.625 4.125 7.625H6.375ZM1.125 11.375H9.35156C9.16406 9.89844 7.89844 8.75 6.375 8.75H4.125C2.57812 8.75 1.3125 9.89844 1.125 11.375ZM5.25 6.5C3.58594 6.5 2.25 5.16406 2.25 3.5C2.25 1.85938 3.58594 0.5 5.25 0.5C6.89062 0.5 8.25 1.85938 8.25 3.5C8.25 5.16406 6.89062 6.5 5.25 6.5ZM5.25 1.625C4.19531 1.625 3.375 2.46875 3.375 3.5C3.375 4.55469 4.19531 5.375 5.25 5.375C6.28125 5.375 7.125 4.55469 7.125 3.5C7.125 2.46875 6.28125 1.625 5.25 1.625Z"
      fill="currentColor"
    />
  ),
});

export const Calendar = createIcon({
  viewBox: "0 0 11 13",
  path: (
    <path
      d="M3.5625 2H6.9375V1.0625C6.9375 0.757812 7.17188 0.5 7.5 0.5C7.80469 0.5 8.0625 0.757812 8.0625 1.0625V2H9C9.82031 2 10.5 2.67969 10.5 3.5V11C10.5 11.8438 9.82031 12.5 9 12.5H1.5C0.65625 12.5 0 11.8438 0 11V3.5C0 2.67969 0.65625 2 1.5 2H2.4375V1.0625C2.4375 0.757812 2.67188 0.5 3 0.5C3.30469 0.5 3.5625 0.757812 3.5625 1.0625V2ZM1.125 11C1.125 11.2109 1.28906 11.375 1.5 11.375H9C9.1875 11.375 9.375 11.2109 9.375 11V5H1.125V11Z"
      fill="currentColor"
    />
  ),
});

export const BatchReviewIcon = createIcon({
  viewBox: "0 0 11 13",
  path: (
    <path
      d="M10.2 1.6C10.8562 1.6 11.4 2.14375 11.4 2.8V10C11.4 10.675 10.8562 11.2 10.2 11.2H5.4C4.725 11.2 4.2 10.675 4.2 10V2.8C4.2 2.14375 4.725 1.6 5.4 1.6H10.2ZM2.4 2.95C2.4 2.70625 2.5875 2.5 2.85 2.5C3.09375 2.5 3.3 2.70625 3.3 2.95V9.85C3.3 10.1125 3.09375 10.3 2.85 10.3C2.5875 10.3 2.4 10.1125 2.4 9.85V2.95ZM0.599997 3.85C0.599997 3.60625 0.787497 3.4 1.05 3.4C1.29375 3.4 1.5 3.60625 1.5 3.85V8.95C1.5 9.2125 1.29375 9.4 1.05 9.4C0.787497 9.4 0.599997 9.2125 0.599997 8.95V3.85Z"
      fill="currentColor"
    />
  ),
});

export const TickMarkGreen = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 10C0 4.47715 4.47715 0 10 0V0C15.5228 0 20 4.47715 20 10V10C20 15.5228 15.5228 20 10 20V20C4.47715 20 0 15.5228 0 10V10Z"
      fill="#F2F4F1"
    />
    <path
      d="M14.8216 6.79911C15.0448 7.02232 15.0448 7.35714 14.8216 7.55804L8.92875 13.4509C8.72785 13.6741 8.39303 13.6741 8.19214 13.4509L5.15642 10.4152C4.93321 10.2143 4.93321 9.87946 5.15642 9.65625C5.35732 9.45536 5.69214 9.45536 5.89303 9.65625L8.5716 12.3348L14.085 6.79911C14.2859 6.59821 14.6207 6.59821 14.8216 6.79911Z"
      fill="#46B12C"
    />
  </svg>
);

export const ShareIcon = createIcon({
  viewBox: "0 0 21 24",
  path: [
    <path
      d="M6 3.5249H4C1.79086 3.5249 0 5.31576 0 7.5249V19.5249C0 21.734 1.79086 23.5249 4 23.5249H16C18.2091 23.5249 20 21.734 20 19.5249V14.5249H18V19.5249C18 20.6295 17.1046 21.5249 16 21.5249H4C2.89543 21.5249 2 20.6295 2 19.5249V7.5249C2 6.42033 2.89543 5.5249 4 5.5249H6V3.5249Z"
      fill="currentColor"
    />,
    <path
      d="M17.5251 11.075C17.4001 8.65 17.4501 6.525 18.2251 3.9L9.6751 12.425L8.2251 10.975L16.7501 2.425C14.1001 3.2 12.0001 3.25 9.5751 3.15L9.6001 0.975001C12.8501 1.15 15.6501 1.025 19.1751 0L20.6751 1.5C19.6251 4.975 19.5001 7.75 19.6751 11.05L17.5251 11.075Z"
      fill="currentColor"
    />,
  ],
});

// export const ShareIcon = () => (
//   <svg
//     width="21"
//     height="24"
//     viewBox="0 0 21 24"
//     fill="none"
//     xmlns="http://www.w3.org/2000/svg"
//   >
//     <path
//       d="M6 3.5249H4C1.79086 3.5249 0 5.31576 0 7.5249V19.5249C0 21.734 1.79086 23.5249 4 23.5249H16C18.2091 23.5249 20 21.734 20 19.5249V14.5249H18V19.5249C18 20.6295 17.1046 21.5249 16 21.5249H4C2.89543 21.5249 2 20.6295 2 19.5249V7.5249C2 6.42033 2.89543 5.5249 4 5.5249H6V3.5249Z"
//       fill="#F2F4F1"
//     />
//     <path
//       d="M17.5251 11.075C17.4001 8.65 17.4501 6.525 18.2251 3.9L9.6751 12.425L8.2251 10.975L16.7501 2.425C14.1001 3.2 12.0001 3.25 9.5751 3.15L9.6001 0.975001C12.8501 1.15 15.6501 1.025 19.1751 0L20.6751 1.5C19.6251 4.975 19.5001 7.75 19.6751 11.05L17.5251 11.075Z"
//       fill="#F2F4F1"
//     />
//   </svg>
// );

export const AddIcon = () => (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M31.7104 16C31.7104 15.1716 31.0389 14.5 30.2104 14.5C29.382 14.5 28.7104 15.1716 28.7104 16V28.7105H16C15.1716 28.7105 14.5 29.3821 14.5 30.2105C14.5 31.039 15.1716 31.7105 16 31.7105H28.7105V44.4211C28.7105 45.2495 29.382 45.9211 30.2105 45.9211C31.0389 45.9211 31.7105 45.2495 31.7105 44.4211V31.7105H44.4211C45.2495 31.7105 45.9211 31.039 45.9211 30.2105C45.9211 29.3821 45.2495 28.7105 44.4211 28.7105H31.7104V16Z"
      fill="#DFE2E2"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M30 57C44.9117 57 57 44.9117 57 30C57 15.0883 44.9117 3 30 3C15.0883 3 3 15.0883 3 30C3 44.9117 15.0883 57 30 57ZM30 60C46.5685 60 60 46.5685 60 30C60 13.4315 46.5685 0 30 0C13.4315 0 0 13.4315 0 30C0 46.5685 13.4315 60 30 60Z"
      fill="#DFE2E2"
    />
  </svg>
);

export const AddIconFont = () => (
  <svg
    width="92"
    height="92"
    viewBox="0 0 92 92"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 45.605C2 69.6874 21.5226 89.21 45.605 89.21C69.6874 89.21 89.21 69.6874 89.21 45.605C89.21 21.5226 69.6874 2 45.605 2C21.5226 2 2 21.5226 2 45.605ZM45.605 0C20.4181 0 0 20.4181 0 45.605C0 70.7919 20.4181 91.21 45.605 91.21C70.7919 91.21 91.21 70.7919 91.21 45.605C91.21 20.4181 70.7919 0 45.605 0Z"
      fill="#FF6828"
    />
    <path
      d="M43.072 59.736V48.088H32V42.584H43.072V31H48.832V42.584H60.032V48.088H48.832V59.736H43.072Z"
      fill="#FF6828"
    />
  </svg>
);

export const GoogleIcon = createIcon({
  viewBox: "0 0 17 16",
  path: (
    <path
      d="M16.125 8.1875C16.125 12.625 13.0938 15.75 8.625 15.75C4.3125 15.75 0.875 12.3125 0.875 8C0.875 3.71875 4.3125 0.25 8.625 0.25C10.6875 0.25 12.4688 1.03125 13.8125 2.28125L11.6875 4.3125C8.9375 1.65625 3.8125 3.65625 3.8125 8C3.8125 10.7188 5.96875 12.9062 8.625 12.9062C11.6875 12.9062 12.8438 10.7188 13 9.5625H8.625V6.90625H16C16.0625 7.3125 16.125 7.6875 16.125 8.1875Z"
      fill="currentColor"
    />
  ),
});

export const AppleIcon = createIcon({
  viewBox: "0 0 12 16",
  path: (
    <path
      d="M9.9375 8.40625C9.9375 8.46875 9.84375 10.3125 11.875 11.2812C11.5 12.4375 10.1875 15 8.65625 15C7.78125 15 7.28125 14.4375 6.28125 14.4375C5.25 14.4375 4.6875 15 3.90625 15C2.40625 15.0625 0.96875 12.25 0.5625 11.0938C0.25 10.2188 0.125 9.375 0.125 8.5625C0.125 5.78125 1.96875 4.4375 3.71875 4.40625C4.5625 4.40625 5.625 5 6.09375 5C6.53125 5 7.75 4.28125 8.875 4.375C10.0312 4.46875 10.9062 4.90625 11.5 5.75C10.4688 6.40625 9.9375 7.25 9.9375 8.40625ZM8.1875 3.28125C7.5625 4 6.8125 4.40625 6 4.34375C5.9375 3.5 6.25 2.71875 6.8125 2.09375C7.3125 1.53125 8.1875 1.0625 8.9375 1C8.9375 1.34375 9.03125 2.28125 8.1875 3.28125Z"
      fill="currentColor"
    />
  ),
});

export const MicroSoftIcon = createIcon({
  viewBox: "0 0 14 16",
  path: (
    <path
      d="M0 1H6.6875V7.71875H0V1ZM7.28125 1H14V7.71875H7.28125V1ZM0 8.3125H6.6875V15H0V8.3125ZM7.28125 8.3125H14V15H7.28125V8.3125Z"
      fill="currentColor"
    />
  ),
});

export const CompareIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
  >
    <g clip-path="url(#clip0_789_8249)">
      <path
        d="M5.9994 9.85278H1.84555C1.0794 9.85278 0.460938 9.2559 0.460938 8.51647V3.17126C0.460938 2.43184 1.0794 1.83496 1.84555 1.83496H5.9994"
        stroke="#F2F4F1"
      />
      <path
        d="M6 1.83496H10.1538C10.92 1.83496 11.5385 2.43184 11.5385 3.17126V8.51647C11.5385 9.2559 10.92 9.85278 10.1538 9.85278H6"
        stroke="#F2F4F1"
        stroke-dasharray="3 3"
      />
      <path d="M6 0V12" stroke="#F2F4F1" />
    </g>
    <defs>
      <clipPath id="clip0_789_8249">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const ReplyIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0996 12.4C9.94961 12.25 9.94961 11.975 10.0996 11.825L13.0246 8.9H3.59961C2.47461 8.9 1.59961 8.025 1.59961 6.9V2.5C1.59961 2.3 1.77461 2.1 1.99961 2.1C2.19961 2.1 2.39961 2.3 2.39961 2.5V6.9C2.39961 7.575 2.92461 8.1 3.59961 8.1H13.0246L10.0996 5.2C9.94961 5.05 9.94961 4.775 10.0996 4.625C10.2496 4.475 10.5246 4.475 10.6746 4.625L14.2746 8.225C14.3496 8.3 14.3996 8.4 14.3996 8.5C14.3996 8.625 14.3496 8.725 14.2746 8.8L10.6746 12.4C10.5246 12.55 10.2496 12.55 10.0996 12.4Z"
      fill="#FF6828"
    />
  </svg>
);

export const OptionsIcon = createIcon({
  viewBox: "0 0 16 16",
  width: "16",
  height: "16",
  path: (
    <path
      d="M7.99961 10.9C8.42461 10.9 8.79961 11.275 8.79961 11.7C8.79961 12.15 8.42461 12.5 7.99961 12.5C7.54961 12.5 7.19961 12.15 7.19961 11.7C7.19961 11.275 7.54961 10.9 7.99961 10.9ZM7.99961 6.9C8.42461 6.9 8.79961 7.275 8.79961 7.7C8.79961 8.15 8.42461 8.5 7.99961 8.5C7.54961 8.5 7.19961 8.15 7.19961 7.7C7.19961 7.275 7.54961 6.9 7.99961 6.9ZM7.99961 4.5C7.54961 4.5 7.19961 4.15 7.19961 3.7C7.19961 3.275 7.54961 2.9 7.99961 2.9C8.42461 2.9 8.79961 3.275 8.79961 3.7C8.79961 4.15 8.42461 4.5 7.99961 4.5Z"
      fill="currentColor"
    />
  ),
});

export const AddPlusIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="#555555" />
    <path
      d="M18.5297 27V21.3252H13V18.6437H18.5297V13H21.4064V18.6437H27V21.3252H21.4064V27H18.5297Z"
      fill="#555555"
    />
  </svg>
);

export const CursorPlus = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z"
      fill="#FF6828"
    />
    <path
      d="M18.8995 30C18.3473 30 17.8995 29.5523 17.8995 29V21.8931H11C10.4477 21.8931 10 21.4454 10 20.8931V19.0624C10 18.5101 10.4477 18.0624 11 18.0624H17.8995V11C17.8995 10.4477 18.3473 10 18.8995 10H21.0091C21.5614 10 22.0091 10.4477 22.0091 11V18.0624H29C29.5523 18.0624 30 18.5101 30 19.0624V20.8931C30 21.4454 29.5523 21.8931 29 21.8931H22.0091V29C22.0091 29.5523 21.5614 30 21.0091 30H18.8995Z"
      fill="black"
    />
  </svg>
);

// New dashboard UI
export const PlusSvg = createIcon({
  viewBox: "0 0 12 12",
  path: (
    <path
      d="M9.9 6.4C9.9 6.6625 9.69375 6.85 9.45 6.85L6.45 6.85L6.45 9.85C6.45 10.1125 6.24375 10.3188 6 10.3188C5.7375 10.3188 5.55 10.1125 5.55 9.85L5.55 6.85H2.55C2.2875 6.85 2.1 6.6625 2.1 6.41875C2.1 6.15625 2.2875 5.95 2.55 5.95H5.55V2.95C5.55 2.70625 5.7375 2.51875 6 2.51875C6.24375 2.51875 6.45 2.70625 6.45 2.95V5.95L9.45 5.95C9.69375 5.95 9.9 6.15625 9.9 6.4Z"
      fill="currentColor"
    />
  ),
});

export const AssetSortIcon = createIcon({
  viewBox: "0 0 12 12",
  path: [
    <path d="M1 5L4.5 1.5L8 5" fill="currentColor" />,
    <path d="M8 7L4.5 10.5L1 7" stroke="currentColor" />,
  ],
});

{
  /* <svg
  xmlns="http://www.w3.org/2000/svg"
  version="1.1"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  width="14"
  height="14"
  x="0"
  y="0"
  viewBox="0 0 32 32"
  style="enable-background:new 0 0 512 512"
  xml:space="preserve"
  class=""
>
  <g>
    <path
      d="M27 6H15a3 3 0 0 0-2.24-1H5a3 3 0 0 0-3 3v16a3 3 0 0 0 3 3h22a3 3 0 0 0 3-3V9a3 3 0 0 0-3-3zm1 3v.18A3 3 0 0 0 27 9h-9.76a1 1 0 0 1-.9-.55L16.12 8H27a1 1 0 0 1 1 1zm0 15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h7.76a1 1 0 0 1 .9.55l.89 1.79A3 3 0 0 0 17.24 11H27a1 1 0 0 1 1 1z"
      data-name="Layer 2"
      fill="#ffffff"
      opacity="1"
      data-original="#000000"
      class=""
    ></path>
  </g>
</svg>; */
}
export const FolderIcon = createIcon({
  viewBox: "0 0 32 32",
  path: (
    <path
      d="M27 6H15a3 3 0 0 0-2.24-1H5a3 3 0 0 0-3 3v16a3 3 0 0 0 3 3h22a3 3 0 0 0 3-3V9a3 3 0 0 0-3-3zm1 3v.18A3 3 0 0 0 27 9h-9.76a1 1 0 0 1-.9-.55L16.12 8H27a1 1 0 0 1 1 1zm0 15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h7.76a1 1 0 0 1 .9.55l.89 1.79A3 3 0 0 0 17.24 11H27a1 1 0 0 1 1 1z"
      fill="currentColor"
    />
  ),
});

// V3 dashboard
// export const GridIconNew = createIcon({
//   viewBox: "0 0 31 24",
//   rect: [
//     <rect width="14.5" height="11" fill="currentColor" />,
//     <rect x="16.5" width="14.5" height="11" fill="currentColor" />,
//     <rect y="13" width="14.5" height="11" fill="currentColor" />,
//     <rect x="16.5" y="13" width="14.5" height="11" fill="currentColor" />,
//   ],
// });

export const GridIconNew = () => (
  <svg
    width="31"
    height="24"
    viewBox="0 0 31 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="14.5" height="11" fill="#4D4D4D" />
    <rect x="16.5" width="14.5" height="11" fill="#4D4D4D" />
    <rect y="13" width="14.5" height="11" fill="#4D4D4D" />
    <rect x="16.5" y="13" width="14.5" height="11" fill="#4D4D4D" />
  </svg>
);

export const ListIconNew = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="24" height="6" fill="#1F1F1F" />
    <rect y="9" width="24" height="6" fill="#1F1F1F" />
    <rect y="18" width="24" height="6" fill="#1F1F1F" />
  </svg>
);
