import React, { createContext, useState } from "react";
import { ProjectContext } from "./ProjectContext";
import axios from "axios";
import useFinalAxiosWrapper from "../utils/FinalApi";
import {
  ASSET_DETAILS_API,
  CREATE_COMMENT_API,
  GET_COMMENTS_BY_ASSET_API,
  GET_ANNOTATIONS_BY_ASSET_API,
} from "../constants/url";

export const SelectedImageContext = createContext();

export const SelectedImageContextProvider = ({ children }) => {
  const { post } = useFinalAxiosWrapper();
  const { selectedAssetId, selectedAsset, setSelectedAsset } = React.useContext(ProjectContext);
  const [imageLoading, setImageLoading] = React.useState(true);
  const [annotationsLoading, setAnnotationsLoading] = React.useState(true);
  const [commentsLoading, setCommentsLoading] = React.useState(true);
  // const [selectedAsset, setSelectedAsset] = React.useState(null);
  const [allComments, setAllComments] = useState([]);
  const [allAnnotations, setAllAnnotations] = useState([]);
  const [selectedComment, setSelectedComment] = useState(null);
  const [replyComment, setReplyComment] = useState("");
  const [selectedAnnotation, setSelectedAnnotation] = useState(null);
  const [defaultScale, setDefaultScale] = useState(null);
  const [defaultTranslation, setDefaultTranslation] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [isMousePositionedOutside, setIsMousePositionedOutside] =
    useState(true);
  const [resetScale, setResetScale] = useState(false);
  const [imageScale, setImageScale] = useState(null);
  const [selectedCommentIndex, setSelectedCommentIndex] = useState(null);

  React.useEffect(() => {
    if (selectedComment) {
      const annotationsToShow = allAnnotations.filter(
        (annotation) => annotation.id === selectedComment.annotation
      );
      const updatedSelectedAnnotation = {
        ...annotationsToShow[0].annotation,
        marker: annotationsToShow[0].position,
      };
      setSelectedAnnotation(updatedSelectedAnnotation);
    } else {
      setSelectedAnnotation(null);
    }
  }, [allAnnotations, selectedComment]);

  const resetImageScale = () => {
    setResetScale(true);
  };

  const addReply = async () => {
    let commentObj = {
      comment: replyComment,
      annotation: selectedComment.annotation,
      parentId: selectedComment._id,
      createdBy: "cc314db7-1d2b-4199-83f4-12622b044d20",
    };

    if (replyComment) {
      await post(CREATE_COMMENT_API, commentObj).then(() => {
        getImageComments();
        getAnnotations();
        setReplyComment("");
      });
    }
    return;
    // setAllComments(updatedAllComments);
  };

  const fetchImageById = async () => {
    if (selectedAssetId) {
      const response = await post(ASSET_DETAILS_API, {
        assetId: selectedAssetId,
      });
      const assetData = await response.data.asset;
      setSelectedAsset(assetData);
      setImageLoading(false);
    }
  };

  const getImageComments = async () => {
    if (selectedAssetId) {
      const response = await post(GET_COMMENTS_BY_ASSET_API, {
        assetId: selectedAssetId,
      });
      const commentData = await response.data.comments;
      setAllComments(commentData);
      setCommentsLoading(false);
    }
  };

  const getAnnotations = async () => {
    if (selectedAssetId) {
      const response = await post(GET_ANNOTATIONS_BY_ASSET_API, {
        assetId: selectedAssetId,
      });
      const annotationData = await response.data.annotations;
      const updatedAnnotations = annotationData?.map((annotation) => {
        return {
          annotation: annotation.annotation[0],
          id: annotation._id,
          position: annotation.position,
        };
      });
      setAllAnnotations(updatedAnnotations);
      setAnnotationsLoading(false);
    }
  };

  React.useEffect(() => {
    fetchImageById();
    getImageComments();
    getAnnotations();
  }, [selectedAssetId]);

  return (
    <SelectedImageContext.Provider
      value={{
        selectedComment,
        setSelectedComment,
        allComments,
        allAnnotations,
        getImageComments,
        imageLoading,
        setImageLoading,
        annotationsLoading,
        setAnnotationsLoading,
        commentsLoading,
        setCommentsLoading,
        selectedAsset,
        getAnnotations,
        selectedComment,
        setSelectedComment,
        selectedAnnotation,
        replyComment,
        setReplyComment,
        addReply,
        defaultScale,
        setDefaultScale,
        defaultTranslation,
        setDefaultTranslation,
        resetImageScale,
        isDragging,
        setIsDragging,
        isMousePositionedOutside,
        setIsMousePositionedOutside,
        resetScale,
        setResetScale,
        imageScale,
        setImageScale,
        selectedCommentIndex,
        setSelectedCommentIndex,
        setSelectedAnnotation,
      }}
    >
      {children}
    </SelectedImageContext.Provider>
  );
};
