import { Box, Divider, Tooltip, Button } from "@chakra-ui/react";
import React from "react";
import {
  DragDrop,
  Rectangle,
  WashDryShade,
  Ballpen,
  Polygon,
  TrashX,
  CircleDot,
} from "tabler-icons-react";

const AnnotationTool = ({ selectMode, selectAnnotation, mode, resetScale }) => {
  const onDragClick = () => {
    selectMode("drag");
    selectAnnotation(null);
  };

  const onRectangleClick = () => {
    selectMode("rectangle");
    selectAnnotation(null);
  };

  const onDotClick = () => {
    selectMode("dot");
  };

  const onPenClick = () => {
    selectMode("pen");
  };

  const onDeleteClick = () => {
    selectMode("delete");
  };
  return (
    <Box
      width="40%"
      display="flex"
      border="1px solid white"
      borderRadius="4px"
      justifyContent="space-around"
      p={2}
    >
      <Box
        onClick={onDragClick}
        id="drag"
        color={mode === "drag" ? "white" : "grey"}
      >
        <Tooltip label="Drag" hasArrow placement="top">
          <span>
            <DragDrop />
          </span>
        </Tooltip>
      </Box>
      <Divider orientation="vertical" color="white" height="inherit" />
      <Box
        onClick={onRectangleClick}
        id="rect"
        color={mode === "rectangle" ? "white" : "grey"}
      >
        <Tooltip label="Rectangle" hasArrow placement="top">
          <span>
            <Rectangle />
          </span>
        </Tooltip>
      </Box>
      <Divider orientation="vertical" color="white" height="inherit" />
      <Box onClick={onDotClick} color={mode === "dot" ? "white" : "grey"}>
        <Tooltip label="Dot" hasArrow placement="top">
          <span>
            <CircleDot />
          </span>
        </Tooltip>
      </Box>
      <Divider orientation="vertical" color="white" height="inherit" />
      <Box onClick={onPenClick} color={mode === "line" ? "white" : "grey"}>
        <Tooltip label="Pen" hasArrow placement="top">
          <span>
            <Ballpen />
          </span>
        </Tooltip>
      </Box>
      <Divider orientation="vertical" color="white" height="inherit" />
      <Box onClick={onDeleteClick} color={mode === "delete" ? "white" : "grey"}>
        <Tooltip label="Delete" hasArrow placement="top">
          <span>
            <TrashX />
          </span>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default AnnotationTool;
