import React, { useContext } from "react";
import LoginHead from "./LoginHead";
import LoginForm from "./LoginForm";
import Bg from "../../assets/images/login/loginBg.avif";
import BgLight from "../../assets/images/login/loginBgLight.avif";

import "./login.scss";
import { Box } from "@chakra-ui/react";
import { MyContext } from "../../context";

const Login = () => {
  const { theme} = useContext(MyContext);
  return (
    <Box backgroundColor="#0D0D0D" width="100%" h="100%" backgroundImage={theme === "dark" ? Bg : BgLight} bgSize="cover" 
    backgroundPosition="center">
      {/* <LoginHead /> */}
      <LoginForm />
    </Box>
  );
};

export default Login;
