import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { Comment, Tick } from "../../components/Icons";

const Model3dActionPanel = ({ setOpenComment, openComment }) => {
  return (
    <Flex
      backgroundColor="grey.500"
      p="12px 16px"
      justify="space-between"
      borderRadius="0 0 12px 12px"
    >
      <Flex gap="12px">
        <Flex
          as="p"
          fontSize="12px"
          lineHeight="13px"
          letterSpacing="0.00em"
          color="pr.400"
          backgroundColor="whitepr.200"
          borderRadius="4px"
          p="8px 12px"
          alignItems="center"
          gap="10px"
        >
          In review
          <Text
            as="span"
            w="8px"
            h="8px"
            backgroundColor="#F8AD56"
            borderRadius="50%"
          ></Text>
        </Flex>
        <Text
          fontSize="13px"
          lineHeight="13px"
          letterSpacing="0.00em"
          color="pr.400"
          p="8px 12px"
          backgroundColor="pr.500"
          border="1px dashed"
          borderColor="border.125"
          borderRadius="4px"
        >
          No due date
        </Text>
      </Flex>
      <Flex alignItems="center" gap="12px">
        <Box
          onClick={() => {
            setOpenComment(!openComment);
          }}
          p="10px 9px"
          backgroundColor="whitepr.100"
          borderRadius="4px"
          cursor="pointer"
          position="relative"
        >
          <Flex
            as="span"
            fontSize="10px"
            lineHeight="11px"
            letterSpacing="0.00em"
            color="whitepr.100"
            position="absolute"
            p="1"
            w="16px"
            h="16px"
            alignItems="center"
            justify="center"
            backgroundColor="#FF570E"
            borderRadius="50%"
            top="-6px"
            right="-1px"
          >
            2
          </Flex>
          <Comment />
        </Box>
        <Flex
          cursor="pointer"
          as="p"
          alignItems="center"
          gap="10px"
          p="10px 12px"
          fontSize="13px"
          lineHeight="13px"
          letterSpacing="0.00em"
          backgroundColor="#3E9D27"
          borderRadius="4px"
          color="whitepr.100"
        >
          Approve <Tick />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Model3dActionPanel;
