import React, { useEffect, useRef, useState } from "react";
import { Box, Flex, Input, Textarea } from "@chakra-ui/react";
import { SelectedVideoContext } from "../../context/SelectedVideoContext";
import {
  ArrowRightAngle,
  Pen,
  Scribble,
  Square,
  Tick,
  LeftArrow,
} from "../../components/Icons";
import { useDrag } from "../../customHook/useDrag";

const MODESArray = ["pen", "arrow"];

const ColorsArray = [
  { color: "red", code: "#FF0000" },
  { color: "orange", code: "#FF570E" },
  { color: "yellow", code: "#FFFF00" },
  { color: "green", code: "#1ABCA1" },
  { color: "blue", code: "#00BFFF" },
];

const ModeIcons = ({ mode, onModeChange, selectedMode }) => {
  const onIconClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onModeChange(mode);
  };
  const Icon = () => {
    switch (mode) {
      case "pen":
        return (
          <Scribble
            w="13px"
            h="13px"
            color={selectedMode === "pen" ? "white" : "pr.400"}
          />
        );
      case "rectangle":
        return (
          <Square
            w="13px"
            h="13px"
            color={selectedMode === "rectangle" ? "white" : "pr.400"}
          />
        );
      case "arrow":
        return (
          <ArrowRightAngle
            w="13px"
            h="13px"
            color={selectedMode === "arrow" ? "white" : "pr.400"}
          />
        );
      case "line":
        return (
          <Pen
            w="13px"
            h="13px"
            color={selectedMode === "line" ? "white" : "pr.400"}
          />
        );
      default:
        return (
          <Pen
            w="13px"
            h="13px"
            color={selectedMode === "pen" ? "white" : "pr.400"}
          />
        );
    }
  };

  return (
    <Flex
      className="markupToolIcon"
      _hover={{ backgroundColor: "#AFAFAF" }}
      style={{
        backgroundColor: selectedMode === mode ? "#FF570E" : "transparent",
      }}
      onClick={onIconClick}
    >
      {Icon()}
    </Flex>
  );
};

const ColorItem = ({ color, selectedColor, setSelectedColor }) => {
  const onColorClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedColor(color.code);
  };
  return (
    <Box
      onClick={onColorClick}
      as="span"
      backgroundColor={color.code}
      height={selectedColor === color.code ? "20px" : "14px"}
      width={selectedColor === color.code ? "20px" : "14px"}
    ></Box>
  );
};

const MarkupColors = ({ selectedColor, setSelectedColor }) => (
  <Flex
    flexGrow={1}
    className="markupToolColor"
    as="p"
    color="pr.400"
    backgroundColor="commentBg.100"
  >
    Color
    {ColorsArray.map((color) => {
      return (
        <ColorItem
          key={color.color}
          color={color}
          selectedColor={selectedColor}
          setSelectedColor={setSelectedColor}
        />
      );
    })}
  </Flex>
);

const VideoMarkup = () => {
  const {
    selectedColor,
    setSelectedColor,
    selectedMode,
    setSelectedMode,
    newComment,
    setNewComment,
    addAnnotation,
  } = React.useContext(SelectedVideoContext);
  const onNewCommentChange = (e) => {
    const textAreaSize = e.target;
    setNewComment(e.target.value);
    adjustTextareaHeight(textAreaSize);
  };

  const onCommentSubmit = () => {
    addAnnotation();
    setNewComment("");
  };

  const onModeChange = (mode) => {
    setSelectedMode(mode);
  };

  const draggableRef = useRef(null);

  const { position, handleMouseDown, isDragging } = useDrag({
    ref: draggableRef,
  });

  const [draggable, setDraggable] = useState(false);

  useEffect(() => {
    if (isDragging) {
      setDraggable(true);
    }
  }, [isDragging, position]);

  const adjustTextareaHeight = (element) => {
    element.style.height = "auto";
    element.style.height = element.scrollHeight + "px";
  };

  return (
    <Box
      position={draggable ? "fixed" : "absolute"}
      top="78%"
      w="354px"
      left="50%"
      ref={draggableRef}
      zIndex={2}
      transform={draggable ? "translateX(0%)" : "translateX(-50%)"}
      style={{
        top: position.y,
        left: position.x,
      }}
    >
      <Flex alignItems="center" gap="4px" justify="space-around">
        <Flex className="markupToolText" backgroundColor="commentBg.100">
          <Box
            as="p"
            color="pr.400"
            cursor={isDragging ? "grabbing" : "grab"}
            onMouseDown={handleMouseDown}
          >
            Markup
          </Box>
          <Flex alignItems="center" gap="8px">
            {MODESArray.map((mode) => {
              return (
                <ModeIcons
                  key={mode}
                  mode={mode}
                  selectedMode={selectedMode}
                  setSelectedMode={setSelectedMode}
                  onModeChange={onModeChange}
                />
              );
            })}
          </Flex>
        </Flex>
        <MarkupColors
          selectedColor={selectedColor}
          setSelectedColor={setSelectedColor}
        />
      </Flex>
      <Flex className="markupInput" backgroundColor="pr.100">
        <Textarea
          rows={1}
          type="text"
          placeholder="Add a comment"
          onChange={onNewCommentChange}
          value={newComment}
        />
        <Tick onClick={onCommentSubmit} />
      </Flex>
    </Box>
  );
};

export default VideoMarkup;
