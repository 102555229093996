import React from "react";

import "./popup.scss";
import { Box, Text } from "@chakra-ui/react";

const CommentFilter = ({ setCommentsFilter }) => {
  const closeCommentsFilter = () => {
    setTimeout(() => {
      setCommentsFilter(false);
    }, 50);
  };
  return (
    <Box className="commentFilter">
      <Box className="commentFilterCard">
        <Box className="commentFilterBox">
          <Text as="h4">Sort</Text>
          <ul>
            <li onClick={closeCommentsFilter} className="activeCommentFilter">
              <span></span>New first
            </li>
            <li onClick={closeCommentsFilter}>
              <span></span>Old first
            </li>
          </ul>
        </Box>
        <Box className="commentFilterBox">
          <Text as="h4">Filter by</Text>
          <ul>
            <li onClick={closeCommentsFilter} className="activeCommentFilter">
              <span></span>All
            </li>
            <li onClick={closeCommentsFilter}>
              <span></span>Completed
            </li>
            <li onClick={closeCommentsFilter}>
              <span></span>Incomplete
            </li>
            <li onClick={closeCommentsFilter}>
              <span></span>Deleted
            </li>
          </ul>
        </Box>
      </Box>
    </Box>
  );
};

export default CommentFilter;
