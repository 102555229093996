import { extendTheme } from '@chakra-ui/react'
import { createBreakpoints } from '@chakra-ui/theme-tools'

const breakpoints = createBreakpoints({
  sm: '32em',
  md: '48em',
  lg: '62em',
  xl: '80em',
  '2xl': '96em',
})

const colors = {
  pr : {
    100 : "#0d0d0d",
    200 : "#1c1c1c",
    300 : "#939598",
    400 : "#ffffff",
    500 : "#939598",
    600 : "#33333333",
    700 : "#363435",
    800 : "#F2F4F1",
    900 : "#161616",
    1000 : "#AFAFAF",
  },
  sec : {
    100 : "#718096",
    200 : "#E2E8F0",
    300 : "#222222",
    400 : "#555555",
    500 : "#33333333",
  },
  icon : {
    100 : "#2b2b2b",
    200 : "#000000"
  },
  prhover : {
    100 : "#dfe2e2",
    200 : "#2b2b2b",
  },
  newPr:{
    100 : "#ffffff33",
    // 200 : "#090909",
    200 : "#000000",
    300 : "#9F9F9F",
    400 : "#000000",
    500 : "#808080",
    600 : "#999999",
    700 : "#333333",
    800 : "#666666",
    // 900 : "#999999",
    900 : "#e9e9e9",
    // 1000 : "#666666",
    1000 : "#9b9b9b",
    // 1100 : "#1F1F1F",
    1100 : "#222222",
    1200 : "#000000",
    1300 : "#e5e5e5",
    1400 : "#141414",
    1500 : "#e6e6e6",
    1600 : "#4D4D4D",
    1700 : "#E5E5E5",
  }
}


const borders = {
  prborder : {
    100 : "1px solid #222222",
    200 : "0.6px solid #363435",
    300 : "1px solid #162313"
  },
  icborder : {
    100 : "none",
    200 : "none"
  }  
}

const sizes = {}

export const darkTheme = extendTheme({
  colors,
  sizes,
  borders,
  components: {
    Button: { baseStyle: { _focus: { boxShadow: 'none' } } },
  },
  shadows: { outline: '0 !important' },
  config: {
    useSystemColorMode: false,
    initialColorMode: 'dark',
  },
  breakpoints,
})
