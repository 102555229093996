import React from "react";
import { useParams } from "react-router";
import axios from "axios";
import { Grid, GridItem, Box, Heading } from "@chakra-ui/react";
import { useNavigate } from "react-router";
import AnnotationTool from "../ImageReview/AnnotationTool";
import { Selected3dModelContext } from "../../context/Selected3dModelContext";

import "./Model3dContainer.scss";

function Model3dAnnotationMarker(props) {
  const dataPosition = `${props.annotationToShow?.position.x} ${props.annotationToShow?.position.y} ${props.annotationToShow?.position.z}`;

  const dataNormal = `${props.annotationToShow?.normal.x} ${props.annotationToShow?.normal.y} ${props.annotationToShow?.normal.z}`;
  if (!props.annotationToShow) return null;
  return (
    <button
      style={{
        backgroundColor: props.selectedColor,
        color: "white",
      }}
      className="view-button"
      key={`hotspot-new-anno}-${Math.random()}`}
      slot={`hotspot-newAnnotation-${Math.random()}}`}
      data-position={dataPosition}
      data-normal={dataNormal}
    >
      {props.selectedCommentIndex}
    </button>
  );
}

function NewAnnotationMarker(props) {
  const dataPosition = `${props.annotationToShow?.position.x} ${props.annotationToShow?.position.y} ${props.annotationToShow?.position.z}`;

  const dataNormal = `${props.annotationToShow?.normal.x} ${props.annotationToShow?.normal.y} ${props.annotationToShow?.normal.z}`;
  if (!props.annotationToShow) return null;
  return (
    <button
      style={{
        backgroundColor: props.selectedColor,
        color: "white",
      }}
      className="view-button"
      key={`hotspot-new-anno}-${Math.random()}`}
      slot={`hotspot-newAnnotation-${Math.random()}}`}
      data-position={dataPosition}
      data-normal={dataNormal}
    >
      {props.selectedCommentIndex}
    </button>
  );
}

const Model3DContainer = ({ setOpenMarkup }) => {
  const { assetId } = useParams();
  const {
    selectedColor,
    newAnnotation,
    setNewAnnotation,
    annotationToShow,
    allComments,
    selectedMarker,
    selectedCommentIndex,
    setSelectedCommentIndex,
    setSelectedComment,
    assetDetails,
  } = React.useContext(Selected3dModelContext);
  const [isDragging, setIsDragging] = React.useState(false);
  const [mousePressed, setMousePressed] = React.useState(false);
  const modelRef = React.useRef();
  //Adding escape key to close markup
  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      setOpenMarkup(false);
      setNewAnnotation([]);
    }
  };

  React.useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleMouseDown = (event) => {
    setMousePressed(true);
  };

  const handleMouseMove = (event) => {
    if (mousePressed) {
      setIsDragging(true);
    }
  };

  const handleMouseUp = (event) => {
    if (!isDragging) {
      const { clientX, clientY } = event;
      if (modelRef.current) {
        let hit = modelRef.current.positionAndNormalFromPoint(clientX, clientY);
        if (hit) {
          setNewAnnotation([...newAnnotation, hit]);
          setOpenMarkup(true);
        }
      }
    }
    setMousePressed(false);
    setIsDragging(false);
  };

  const addReply = async (comment) => {
    return;
  };

  if (!assetDetails) {
    return null;
  }

  return (
    <Box
      position="relative"
      className="assetContainerBg"
      backgroundColor="pr.900"
    >
      <model-viewer
        height="inherit"
        width="inherit"
        loading="eager"
        disable-pan
        class="assetContainer"
        src={assetDetails.url}
        alt="A rock"
        camera-controls
        shadow-intensity="1"
        ar
        ar-modes="webxr"
        // onClick={handleClick}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        ref={(ref) => {
          modelRef.current = ref;
        }}
      >
        <NewAnnotationMarker
          allComments={allComments}
          selectedCommentIndex={allComments.length + 1}
          selectedColor={selectedColor.code}
          annotationToShow={newAnnotation[newAnnotation.length - 1]}
          key={`hotspot-new-anno}`}
          className="view-button"
          slot={`hotspot-new-anno`}
        ></NewAnnotationMarker>

        <Model3dAnnotationMarker
          selectedCommentIndex={selectedCommentIndex}
          allComments={allComments}
          selectedColor={selectedMarker?.color}
          annotationToShow={selectedMarker}
          key={`hotspot-selected-anno}`}
          className="view-button"
          slot={`hotspot-selected-anno`}
        ></Model3dAnnotationMarker>
      </model-viewer>
    </Box>
  );
};

export default Model3DContainer;
