import React from "react";
import { Box } from "@chakra-ui/react";

const ProjectItem = ({ onProjectClick, name, projectId, selectedProject }) => {
  const onProjectItemClick = () => {
    onProjectClick && onProjectClick(projectId);
  };
  return (
    <Box
      flex="1"
      textAlign="left"
      cursor={"pointer"}
      onClick={onProjectItemClick}
      padding="4px 8px"
      borderLeft={
        selectedProject._id === projectId ? "4px solid #a30fff" : "none"
      }
      margin="8px 0px"
      height="28px"
      fontSize="14px"
      color="rgba(245, 245, 245, 0.5)"
      fontWeight={500}
    >
      {name}
    </Box>
  );
};

export default ProjectItem;
