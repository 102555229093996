import { Box, Flex, Text } from "@chakra-ui/react";
import React, { useContext } from "react";
import {
  Bell,
  DownArrow,
  Ellipsis,
  Folder,
  LeftArrow,
  Life,
  LinkIcon,
  Plus,
  Search,
  ThemeIcon,
} from "../../components/Icons";
import { useNavigate } from "react-router-dom";
import { MyContext } from "../../context";
import { SelectedVideoContext } from "../../context/SelectedVideoContext";

const VideoReviewHeader = () => {
  const { assetDetails } = React.useContext(SelectedVideoContext);
  const title = assetDetails?.name;
  const { theme, setTheme } = useContext(MyContext);
  const navigate = useNavigate();

  return (
    <Flex justify="space-between" p="4">
      <Flex gap="16px">
        <Flex alignItems="center" gap="4px">
          <Box
            p="6px 8px"
            backgroundColor="grey.400"
            borderRadius="8px"
            cursor="pointer"
            onClick={() => navigate("/")}
          >
            <Folder />
          </Box>
          <Box cursor="pointer" onClick={() => navigate("/")}>
            <LeftArrow />
          </Box>
          <Text
            fontSize="16px"
            lineHeight="18px"
            letterSpacing="0.00em"
            color="pr.400"
          >
            {title}
          </Text>
        </Flex>
        <Flex alignItems="center" gap="3">
          <Flex
            gap="10px"
            alignItems="center"
            p="6px 8px"
            backgroundColor="whitepr.100"
            borderRadius="8px"
            cursor="pointer"
          >
            <Text
              as="h3"
              fontSize="13px"
              lineHeight="13px"
              letterSpacing="0.00em"
              color="pr.400"
            >
              V2
            </Text>
            <DownArrow />
          </Flex>
          {/* <Menu>
              <MenuButton
                p="6px 8px"
                backgroundColor="whitepr.100"
                borderRadius="8px"
                cursor="pointer"
                as={Button}
                rightIcon={<DownArrow />}>
                V2
              </MenuButton>
              <MenuList>
                <MenuItem>V1</MenuItem>
                <MenuItem>V2</MenuItem>
                <MenuItem>V3</MenuItem>
                <MenuItem>V4</MenuItem>
                <MenuItem>V5</MenuItem>
              </MenuList>
            </Menu> */}
          <Box
            p="2"
            backgroundColor="whitepr.100"
            borderRadius="8px"
            cursor="pointer"
          >
            <LinkIcon />
          </Box>
          <Box
            p="2"
            backgroundColor="whitepr.100"
            borderRadius="8px"
            cursor="pointer"
          >
            <Ellipsis />
          </Box>
        </Flex>
        <Box w="2px" alignSelf="stretch" backgroundColor="#CACACA"></Box>
        <Flex gap="12px" alignItems="center">
          <Flex alignItems="center">
            <Text
              as="span"
              w="22px"
              h="22px"
              backgroundColor="#7B7B7B"
              borderRadius="50%"
              display="inline-block"
            ></Text>
            <Text
              as="span"
              w="22px"
              h="22px"
              backgroundColor="#B8B7B7"
              borderRadius="50%"
              display="inline-block"
              marginLeft="-6px"
            ></Text>
            <Text
              as="span"
              w="22px"
              h="22px"
              backgroundColor="#CD6969"
              borderRadius="50%"
              display="inline-block"
              marginLeft="-6px"
            ></Text>
            <Text
              as="span"
              w="22px"
              h="22px"
              backgroundColor="#CF95E3"
              borderRadius="50%"
              display="inline-block"
              marginLeft="-6px"
            ></Text>
          </Flex>
          <Flex
            alignItems="center"
            gap="10px"
            p="6px 8px"
            backgroundColor="whitepr.100"
            borderRadius="8px"
            cursor="pointer"
          >
            <Text
              fontSize="13px"
              lineHeight="13px"
              letterSpacing="0.00em"
              color="pr.400"
            >
              Share
            </Text>
            <Plus />
          </Flex>
        </Flex>
      </Flex>
      <Flex alignItems="center" gap="32px">
        <Flex
          alignItems="center"
          gap="10px"
          p="6px 8px"
          backgroundColor="whitepr.100"
          borderRadius="8px"
        >
          <Text
            fontSize="13px"
            lineHeight="13px"
            letterSpacing="0.00em"
            color="pr.400"
          >
            Awaiting my review
          </Text>
          <Text
            fontSize="13px"
            lineHeight="13px"
            letterSpacing="0.00em"
            color="pr.400"
          >
            03
          </Text>
        </Flex>
        <Box w="2px" alignSelf="stretch" backgroundColor="#CACACA"></Box>
        <Flex alignItems="center" gap="12px">
          <Box
            cursor="pointer"
            p="6px 8px"
            backgroundColor="whitepr.100"
            borderRadius="8px"
          >
            <Search />
          </Box>
          <Box
            cursor="pointer"
            p="6px 8px"
            backgroundColor="whitepr.100"
            borderRadius="8px"
          >
            <Bell />
          </Box>
          <Box
            cursor="pointer"
            p="6px 8px"
            backgroundColor="whitepr.100"
            borderRadius="8px"
          >
            <Life />
          </Box>
          <Box
            cursor="pointer"
            p="6px 8px"
            backgroundColor="whitepr.100"
            borderRadius="8px"
            onClick={() => {
              if (theme === "dark") {
                setTheme("light");
              } else {
                setTheme("dark");
              }
            }}
          >
            <ThemeIcon />
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default VideoReviewHeader;
