import { Box, Button, Textarea, Avatar, Image } from "@chakra-ui/react";
import React from "react";
import ImageComponent from "./ImageComponent";

const KonvaImageContainer = (props) => {
  const {
    addComment,
    addNewComment,
    selectAnnotation,
    selectedId,
    assetDetails,
    addAnnotation,
    openComment,
    selectedMode,
    setSelectedMode,
    ...rest
    // containerWidth,
    // containerHeight
  } = props;
  const containerRef = React.useRef(null);
  const [containerWidth, setContainerWidth] = React.useState(null);
  const [containerHeight, setContainerHeight] = React.useState(null);
  const [defaultStageScale, setDefaultStageScale] = React.useState(null);
  const [defaultScaleRange, setDefaultScaleRange] = React.useState(null);
  const [stageScale, setStageScale] = React.useState(1);
  const [scaleX, setScaleX] = React.useState(0);
  const [scaleY, setScaleY] = React.useState(0);

  React.useEffect(() => {
    if (containerRef.current) {
      const { width, height } = containerRef.current.getBoundingClientRect();
      if (openComment) {
        setContainerWidth(containerWidth - 360);
      } else {
        setContainerWidth(containerWidth + 360);
      }
      setContainerWidth(width);
      setContainerHeight(height);
    }
  }, [containerRef?.current, openComment]);

  const resetScale = () => {
    setStageScale(defaultStageScale);
    setScaleX(defaultStageScale);
    setScaleY(defaultStageScale);
  };

  const updateSize = () => {
    if (containerRef.current) {
      const { width, height } = containerRef.current.getBoundingClientRect();
      setContainerWidth(width);
      setContainerHeight(height);
    }
  };

  React.useEffect(() => {
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return (
    <Box
      id="konvaImageWrapper"
      style={{ cursor: selectedMode !== "default" ? "crosshair" : "none" }}
      width="100%"
      display="flex"
      // height="calc(100vh - 183px)"
      height="100%"
      // margin="0 auto"
      ref={containerRef}
    >
      <ImageComponent
        {...rest}
        setSelectedMode={setSelectedMode}
        selectedMode={selectedMode}
        selectAnnotation={selectAnnotation}
        openComment={openComment}
        annotations={props.annotations}
        newAnnotation={props.newAnnotation}
        defaultScaleRange={defaultScaleRange}
        setDefaultScaleRange={setDefaultScaleRange}
        containerWidth={containerWidth}
        containerHeight={containerHeight}
        setDefaultStageScale={setDefaultStageScale}
        defaultStageScale={defaultStageScale}
        stageScale={stageScale}
        setScaleX={setScaleX}
        setScaleY={setScaleY}
        scaleX={scaleX}
        scaleY={scaleY}
        setStageScale={setStageScale}
        assetDetails={assetDetails}
      />
    </Box>
  );
};

export default KonvaImageContainer;

/* <AnnotationTool
        selectMode={setMode}
        mode={mode}
        resetScale={resetScale}
        selectAnnotation={selectAnnotation}
      /> */
/* <Box marginTop={"10px"} display="flex" alignItems="center">
        <Textarea
          marginRight="12px"
          value={textAreaValue}
          placeholder="Enter a new comment"
          onChange={onTextAreaChange}
        />
        <Button
          bg="#BE4915"
          color="white"
          borderRadius="4px"
          isDisabled={!Boolean(textAreaValue) && !Boolean(selectedId)} 
          onClick={onCommentSubmit}
        >
          Submit
        </Button>
      </Box> */
