import { Box, Flex, Image, Text } from "@chakra-ui/react";
import React from "react";

import Car from "../../assets/images/projects/car.png";

import "./projectCard.scss";

const ProjectCard = ({ status, check }) => {
  return (
    <Box className="projectCard">
      <Image src={Car} alt="car" />
      <Flex className="projectCardTitle">
        <Text as="h3">D8_ATL_Exterior_Performance_add22_version</Text>
        {status ? <Text as="p" className="status"></Text> : ""}
        {check ? <input type="checkbox" /> : "" }
      </Flex>
      <Text as="span" className="mediaType">
        jpg
      </Text>
      <Text as="span" className="reviewVersion">
        V2
      </Text>
    </Box>
  );
};

export default ProjectCard;
