import React, { useContext, useEffect, useState } from "react";
import { Box, Image, Button, Text, Flex } from "@chakra-ui/react";
import ProjectBody from "./ProjectBody";
import ProjectHeader from "./ProjectHeader";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Dropzone from "react-dropzone";
import { ProjectContext } from "../../context/ProjectContext";

import useFinalAxiosWrapper from "../../utils/FinalApi";
import {
  GET_ASSETS_BY_PROJECT_API,
  GET_PROJECTS_BY_OWNER_API,
} from "../../constants/url";
import "./project.scss";
import '../../components/DropAsset/dropasset.scss';

const Projects = () => {
  const { setSelectedAssetId, selectedProject, setSelectedProject } =
    useContext(ProjectContext);
  const navigate = useNavigate();
  const { get, post } = useFinalAxiosWrapper();
  const [foldersLoading, setFoldersLoading] = useState(false);
  const [projectsLoading, setProjectsLoading] = useState(false);
  const [playLottie, setPlayLottie] = useState(false);
  const [projects, setProjects] = useState([]);
  const [projectAssets, setProjectAssets] = useState([]);
  const [newAssets, setNewAssets] = useState([]);
  const [isDropzoneVisible, setIsDropzoneVisible] = useState(false);
  const [showImageUpload, setShowImageUpload] = useState(false);
  const [deleteStatus, setdeleteStatus] = useState("");
  const [projectsView, setProjectsView] = useState("grid");
  const [deleteIds, setDeleteIds] = useState([]);

  const fetchProjects = async () => {
    await post(GET_PROJECTS_BY_OWNER_API, {
      ownerId: "cc314db7-1d2b-4199-83f4-12622b044d20",
    })
      .then((res) => {
        setProjects(res.data.projects);
        if (res.data.projects.length > 0) {
          setSelectedProject(selectedProject || res.data.projects[0]);
        }
        setProjectsLoading(false);
      })
      .catch((err) => {
        console.log("Error: ", err);
      });
  };

  const fetchAssets = async () => {
    setFoldersLoading(true);
    if (selectedProject) {
      await post(GET_ASSETS_BY_PROJECT_API, {
        projectId: selectedProject._id,
      }).then((res) => {
        setProjectAssets(res.data.assets);
        setFoldersLoading(false);
      });
    }
  };

  const onProjectClick = (id) => {
    const selectedProject = projects.find((project) => project._id === id);
    setSelectedProject(selectedProject);
  };

  useEffect(() => {
    fetchAssets();
  }, [selectedProject, showImageUpload, deleteStatus]);

  const handleDragLeave = () => setIsDropzoneVisible(false);

  const handleDrop = (files) => {
    const initialArrayLength = projectAssets.length;
    const newFiles = files.map((file) => {
      return {
        loading: true,
        file: file,
      };
    });
    const updatedAssets = [...projectAssets, ...newFiles];
    newFiles.forEach((file, index) => {
      const bodyFormData = new FormData();
      bodyFormData.append("file", file.file);
      bodyFormData.append("projectId", selectedProject._id);
      bodyFormData.append("name", file.name);
      bodyFormData.append("createdBy", "cc314db7-1d2b-4199-83f4-12622b044d20");
      axios
        .post("https://app-api.finalfinal.one/api/file/upload", bodyFormData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          setProjectAssets((prevProjectAssets) => {
            return prevProjectAssets.map((asset, i) => {
              if (i === initialArrayLength + index) {
                return { ...res.data, _id: res.data.assetId };
              }
              return asset;
            });
          });
        });
    });

    setIsDropzoneVisible(false);
    setProjectAssets(updatedAssets);
  };

  const onAssetClick = (asset) => {
    const { mimeType } = asset;
    setSelectedAssetId(asset._id);
    if (mimeType === "image/jpeg" || mimeType === "image/png") {
      navigate(`/imageReview/${asset._id}`);
    } else if (mimeType === "model/gltf-binary") {
      navigate(`/3dreview/${asset._id}`);
    } else if (mimeType === "video/mp4" || mimeType === "video/quicktime") {
      navigate(`/videoReview/${asset._id}`);
    }
  };

  useEffect(() => {
    setProjectsLoading(true);
    fetchProjects();
  }, []);

  const handleDragEnter = () => {
    setIsDropzoneVisible(true);
    console.log("onDragEnter");
  };

  useEffect(() => {
    window.addEventListener("dragenter", handleDragEnter);
    return () => {
      window.removeEventListener("dragenter", handleDragEnter);
    };
  }, []);

 

  return (
    <Box background="newPr.200" h="100%">
      {/* Commenting the drag an asset to the projects UI, uncomment the below line when integrating the drag functionality */}
      {/* <DropAsset /> */}
      <ProjectHeader
        playLottie={playLottie}
        projectsView={projectsView}
        setProjectsView={setProjectsView}
        deleteIds={deleteIds}
        setDeleteIds={setDeleteIds}
        setdeleteStatus={setdeleteStatus}
      />
      <ProjectBody
        setPlayLottie={setPlayLottie}
        playLottie={playLottie}
        projects={projects}
        assets={projectAssets}
        setSelectedProject={setSelectedProject}
        selectedProject={selectedProject}
        onAssetClick={onAssetClick}
        showImageUpload={showImageUpload}
        setShowImageUpload={setShowImageUpload}
        deleteStatus={deleteStatus}
        setdeleteStatus={setdeleteStatus}
        projectsView={projectsView}
        setProjectsView={setProjectsView}
        deleteIds={deleteIds}
        setDeleteIds={setDeleteIds}
      />
      <Dropzone
        multiple
        onDrop={handleDrop}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onError={() => console.log("error")}
      >
        {({
          getRootProps,
          getInputProps,
          isDragActive,
          isDragAccept,
          isDragReject,
        }) => {
          const additionalClass = isDragAccept
            ? "accept"
            : isDragReject
            ? "reject"
            : "";

          return (
            <>
              {isDropzoneVisible ? (
                <Flex
                  {...getRootProps({
                    className: `dropAsset ${additionalClass}`,
                  })}
                >
                  <Box className="dropAssetOverlay"></Box>
                  <Flex className="dropAssetContent">
                    <input {...getInputProps()} />
                    <Box>
                      <svg
                        width="60"
                        height="60"
                        viewBox="0 0 60 60"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M31.2104 16C31.2104 15.4477 30.7627 15 30.2104 15C29.6582 15 29.2104 15.4477 29.2104 16V29.2105H16C15.4477 29.2105 15 29.6582 15 30.2105C15 30.7628 15.4477 31.2105 16 31.2105H29.2105V44.4211C29.2105 44.9733 29.6582 45.4211 30.2105 45.4211C30.7627 45.4211 31.2105 44.9733 31.2105 44.4211V31.2105H44.4211C44.9733 31.2105 45.4211 30.7628 45.4211 30.2105C45.4211 29.6582 44.9733 29.2105 44.4211 29.2105H31.2104V16Z"
                          fill="#DFE2E2"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M30 59C46.0163 59 59 46.0163 59 30C59 13.9837 46.0163 1 30 1C13.9837 1 1 13.9837 1 30C1 46.0163 13.9837 59 30 59ZM30 60C46.5685 60 60 46.5685 60 30C60 13.4315 46.5685 0 30 0C13.4315 0 0 13.4315 0 30C0 46.5685 13.4315 60 30 60Z"
                          fill="#DFE2E2"
                        />
                      </svg>
                    </Box>
                    <Text as="p">Drop files to upload</Text>
                  </Flex>
                </Flex>
              ) : (
                ""
              )}
            </>
          );
        }}
      </Dropzone>
    </Box>
  );
};

export default Projects;
