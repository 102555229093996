import React, { useState, useContext } from "react";
import { Box, Spinner } from "@chakra-ui/react";
import ReactCursorPosition from "react-cursor-position";
import { NewAnnotationContext } from "../../context/NewAnnotationContext";
import { SelectedImageContext } from "../../context/SelectedImageContext";
import { KonvaStage } from "./KonvaStage";
import "./ImageComponent.scss";
import {
  TransformWrapper,
  TransformComponent,
  useControls,
} from "react-zoom-pan-pinch";

const Controls = React.memo(({ resetScale, setResetScale }) => {
  const { resetTransform } = useControls();
  if (resetScale) {
    resetTransform();
    setResetScale(false);
  }
  return null;
});

const ImageComponent = ({
  containerWidth,
  containerHeight,
  mode,
  imageLoading,
  selectedMode,
  setOpenMarkup,
  setSelectedMode,
  openMarkup,
  openComment,
  windowResized,
  setWindowResized,
}) => {
  const {
    selectedAsset,
    setZoom,
    defaultScale,
    setDefaultScale,
    defaultTranslation,
    setDefaultTranslation,
    posValue,
    isDragging,
    setIsDragging,
    resetScale,
    setResetScale,
    imageScale,
    setImageScale,
  } = useContext(SelectedImageContext);
  const AnnottationContext = useContext(NewAnnotationContext);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [image, setImage] = useState(null);
  const WrapperRef = React.useRef(null);

  const [isDrawing, toggleDrawing] = React.useState(false);
  const [cursorStyle, setCursorStyle] = React.useState("crosshair");

  const { setLines, setArrowAnnotation, setMarkers } = AnnottationContext;
  const [latestArrow, setLatestArrow] = useState(null);
  const [latestLine, setLatestLine] = React.useState(null);

  const onKeyPress = React.useCallback(
    (e) => {
      if (e.keyCode === 27) {
        setLatestArrow(null);
        setLatestLine(null);
        setArrowAnnotation([]);
        setMarkers([]);
        setLines([]);
        setOpenMarkup(false);
        setSelectedMode("default");
      }
      if (e.keyCode === 32) {
        setIsDragging(true);
        setCursorStyle("grab");
      }
    },
    [
      setArrowAnnotation,
      setIsDragging,
      setLines,
      setMarkers,
      setOpenMarkup,
      setSelectedMode,
    ]
  );

  const onKeyReleased = React.useCallback(
    (e) => {
      if (e.keyCode === 32) {
        setIsDragging(false);
        if (openMarkup) {
          setCursorStyle("crosshair");
        } else {
          setCursorStyle("none");
        }
      }
    },
    [openMarkup, setIsDragging]
  );

  React.useEffect(() => {
    if (WrapperRef && WrapperRef.current) {
      WrapperRef.current.resetTransform();
      WrapperRef.current.centerView();
    }
  }, [WrapperRef, containerHeight, containerWidth]);

  React.useEffect(() => {
    document.addEventListener("keydown", onKeyPress);
    document.addEventListener("keyup", onKeyReleased);

    return function cleanup() {
      document.removeEventListener("keydown", onKeyPress);
      document.removeEventListener("keyup", onKeyReleased);
    };
  }, [onKeyPress, onKeyReleased]);

  React.useEffect(() => {
    if (selectedAsset) {
      const { dimensions } = selectedAsset;
      const { width, height } = dimensions;
      let scale_x = containerWidth / width;
      let scale_y = containerHeight / height;
      let scale = Math.min(scale_x, scale_y);
      setDefaultScale(scale);
      setImageScale(scale);
      const wrh = width / height;
      let newWidth = containerWidth;
      let newHeight = newWidth / wrh;
      if (newHeight > containerHeight) {
        newHeight = containerHeight;
        newWidth = newHeight * wrh;
      }
      const xOffset =
        newWidth < containerWidth ? (containerWidth - newWidth) / 2 : 0;
      const yOffset =
        newHeight < containerHeight ? (containerHeight - newHeight) / 2 : 0;
      setDefaultTranslation({ x: xOffset, y: yOffset });
    }
  }, [
    selectedAsset,
    containerHeight,
    containerWidth,
    setDefaultScale,
    setDefaultTranslation,
    setImageScale,
    openComment,
  ]);

  const loadImage = React.useCallback(async () => {
    if (selectedAsset) {
      setIsImageLoading(true);
      const KI = new window.Image();
      KI.src = selectedAsset.url;
      KI.onload = () => {
        setIsImageLoading(false);
      };
      return KI;
    }
  }, [selectedAsset]);

  const onWrapperTransformed = React.useCallback((value) => {
    console.log("Transformed");
    const { state } = value;
    const { scale } = state;
    setImageScale(scale);
  }, []);

  React.useEffect(() => {
    loadImage().then((img) => {
      setImage(img);
    });
  }, [loadImage, selectedAsset]);

  if (
    containerHeight === 0 ||
    containerWidth === 0 ||
    isImageLoading ||
    imageLoading ||
    !imageScale
  ) {
    return (
      <Box
        width="100%"
        height="100%"
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        transition="all 0.2s ease-in-out"
      >
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="#A2A2A2"
          size="xl"
        />
      </Box>
    );
  }

  const haandleZoomStop = (value) => {
    setImageScale(value.state.scale);
  };
  console.log("WindowResized: ", windowResized);
  return (
    <Box
      id="stage-ref"
      // overflow={"scroll"}
      position={"relative"}
      height={"inherit"}
      width="inherit"
    >
      {defaultScale && (
        <TransformWrapper
          centerOnInit
          centerZoomedOut
          ref={WrapperRef}
          // defaultTranslationX={defaultTranslation?.x}
          // defaultTranslationY={defaultTranslation?.y}
          // initialPositionX={defaultTranslation?.x}
          // initialPositionY={defaultTranslation?.y}
          // defaultPositionX={defaultTranslation.x}
          // defaultPositionY={defaultTranslation.y}
          minScale={defaultScale < 1 ? defaultScale - defaultScale / 10 : 0.9}
          doubleClick={{ disabled: true }}
          maxScale={3}
          initialScale={defaultScale}
          disablePadding={true}
          panning={{ disabled: isDrawing || !isDragging }}
          wheel={{ step: 0.4 }}
          onZoomStop={haandleZoomStop}
          // onTransformed={onWrapperTransformed}
        >
          {({ zoomIn, zoomOut, resetTransform, instance, ...rest }) => (
            <TransformComponent>
              <Controls resetScale={resetScale} setResetScale={setResetScale} />
              <ReactCursorPosition>
                <KonvaStage
                  selectedMode={selectedMode}
                  isDrawing={isDrawing}
                  latestLine={latestLine}
                  latestArrow={latestArrow}
                  setZoom={setZoom}
                  mode={mode}
                  imageScale={imageScale}
                  isDragging={isDragging}
                  setLatestArrow={setLatestArrow}
                  setLatestLine={setLatestLine}
                  toggleDrawing={toggleDrawing}
                  selectedAsset={selectedAsset}
                  image={image}
                  containerWidth={containerWidth}
                  containerHeight={containerHeight}
                />
              </ReactCursorPosition>
            </TransformComponent>
          )}
        </TransformWrapper>
      )}
    </Box>
  );
};

export default ImageComponent;
