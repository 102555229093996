import { Box, Image, Flex, Text } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import Asset from "../../assets/images/review/carAsset.jpg";
import Lines from "../../assets/images/review/Lightpattern.png";
import Bg from "../../assets/images/review/Darkpattern.png";
import Cur from "../../assets/images/review/commentHover.svg";
import { useRef } from "react";
import { MyContext } from "../../context";
import Model3DContainer from "./Model3DContainer";
import Model3dComments from "../../components/Comments/Model3dComments";
import { GET_ASSETS_BY_PROJECT_API } from "../../constants/url";
import BatchReview from "../../components/BatchReview";
import { GridviewIcon, ListviewIcon, ShareIcon } from "../../components/Icons";
import { DownloadIcon } from "@chakra-ui/icons";
import ProjectGridView from "../../components/ProjectGridView";
import { ToastContainer, toast } from "react-toastify";

import "./review.scss";
import useFinalAxiosWrapper from "../../utils/FinalApi";
import { ProjectContext } from "../../context/ProjectContext";
import { useNavigate } from "react-router";
import { AuthContext } from "../../context/AuthContext";

const Model3dReviewAsset = ({
  setOpenMarkup,
  openComment,
  addReply,
  setOpenComment,
  selectAnnotation,
  selectedId,
  projectsView,
  setProjectsView,
  setSelectedMode,
  ...restProps
}) => {
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const [isHovering, setHovering] = useState(true);
  const { theme } = useContext(MyContext);
  const [batchView, setBatchView] = useState(false);
  const [projectAssets, setProjectAssets] = useState([]);
  const { post } = useFinalAxiosWrapper();
  const [deleteStatus, setdeleteStatus] = useState("");
  const [deleteIds, setDeleteIds] = useState([]);
  const { selectedProject, setSelectedAssetId, selectedAsset } =
    useContext(ProjectContext);

  const navigate = useNavigate();

  const AssetRef = useRef();

  const fetchAssets = async () => {
    if (selectedAsset) {
      await post(GET_ASSETS_BY_PROJECT_API, {
        projectId: selectedAsset?.project,
      }).then((res) => {
        setProjectAssets(res.data.assets);
      });
    }
  };

  useEffect(() => {
    fetchAssets();
  }, [selectedProject, selectedAsset, projectsView, deleteStatus]);

  const onAssetClick = (asset) => {
    const { mimeType } = asset;
    setProjectsView("list");
    setOpenComment(true);
    setSelectedAssetId(asset._id);
    if (mimeType === "image/jpeg" || mimeType === "image/png") {
      navigate(`/imageReview/${asset._id}`);
    } else if (mimeType === "model/gltf-binary") {
      navigate(`/3dreview/${asset._id}`);
    } else if (mimeType === "video/mp4" || mimeType === "video/quicktime") {
      navigate(`/videoReview/${asset._id}`);
    }
  };

  // const deleteProjectAssets = async () => {
  //   const response = await deleteAsset(deleteIds);
  //   setdeleteStatus(response?.data?.message);
  //   toast(response?.data?.message);
  //   setDeleteIds([]);
  // };

  const handleKeyPress = (event) => {
    if (
      event.keyCode === 71 &&
      event.target.tagName !== "TEXTAREA" &&
      event.target.tagName !== "INPUT"
    ) {
      if (projectsView === "list") {
        setProjectsView("grid");
        setOpenComment(false);
      } else if (projectsView === "grid") {
        setProjectsView("list");
        setOpenComment(true);
      }
    }

    if (
      (event.key === "f" ||
        event.key === "F" ||
        event.key === "c" ||
        event.key === "C") &&
      event.target.tagName !== "TEXTAREA" &&
      event.target.tagName !== "INPUT"
    ) {
      if (openComment) {
        setOpenComment(false);
      } else {
        setOpenComment(true);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [openComment]);

  return (
    <Box
      // backgroundImage={theme === "dark" ? Lines : Bg}
      h="calc(100dvh - 115px)"
    >
      <Flex
        ref={AssetRef}
        w="inherit"
        p="16px 20px"
        // overflow="hidden"
        h="calc(100dvh - 48px)"
      >
        <Box width={openComment ? "calc(100vw - 380px)" : "100%"}>
          {projectsView === "list" ? (
            <Box width={openComment ? "calc(100vw - 380px)" : "100%"}>
              <Model3DContainer setOpenMarkup={setOpenMarkup} />
            </Box>
          ) : (
            ""
          )}

          {projectsView === "grid" ? (
            <ProjectGridView
              assets={projectAssets}
              reviewGrid={true}
              onAssetClick={onAssetClick}
              deleteIds={deleteIds}
              setDeleteIds={setDeleteIds}
              setdeleteStatus={setdeleteStatus}
            />
          ) : (
            ""
          )}

          <Flex className="projectsFooter">
            <Flex className="projectsFooterLeft">
              <Flex className="projectsFooterFiles">
                <Flex
                  align="center"
                  gap="4px"
                  className="projectsFooterFilesContent"
                >
                  <Text as="span" color="pr.300">
                    {"←"}
                  </Text>
                  <Text as="p" color="pr.300">
                    Files 1 / 5
                  </Text>
                  <Text as="span" color="pr.300">
                    {"→"}
                  </Text>
                </Flex>
                <Flex className="projectsFooterIcon">
                  <ShareIcon color="pr.300" />
                </Flex>
                <Flex className="projectsFooterIcon">
                  <DownloadIcon color="pr.300" />
                </Flex>
              </Flex>
              <Text as="span" className="footerSeperator"></Text>
              <Flex className="projectsFooterFiles">
                <Flex
                  className={
                    projectsView === "list"
                      ? "projectsFooterViews activeView"
                      : "projectsFooterViews"
                  }
                  onClick={() => setProjectsView("list")}
                >
                  <ListviewIcon color="pr.300" />
                </Flex>
                <Flex
                  className={
                    projectsView === "grid"
                      ? "projectsFooterViews activeView"
                      : "projectsFooterViews"
                  }
                  onClick={() => setProjectsView("grid")}
                >
                  <GridviewIcon color="pr.300" />
                </Flex>
              </Flex>
            </Flex>
            {projectsView === "grid" ? (
              ""
            ) : (
              <Box className="assetName">
                <Text as="h3" color="pr.300">
                  D8_ATL_Exterior_Performance
                </Text>
              </Box>
            )}
            <Flex gap="12px">
              <Flex
                className="projectsFooterFullscreen"
                onClick={() => setOpenComment(!openComment)}
              >
                <Text as="p" backgroundColor="pr.200" color="pr.300">
                  C
                </Text>
                <Text as="h3" color="pr.300">
                  Comments
                </Text>
              </Flex>
              <Flex
                className="projectsFooterFullscreen"
                onClick={() => setOpenComment(!openComment)}
              >
                <Text as="p" backgroundColor="pr.200" color="pr.300">
                  F
                </Text>
                <Text as="h3" color="pr.300">
                  Full screen
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Box>
        <Model3dComments
          openComment={openComment}
          addReply={addReply}
          selectAnnotation={selectAnnotation}
          selectedAnnotation={selectedId}
          setOpenMarkup={setOpenMarkup}
          setSelectedMode={setSelectedMode}
        />
      </Flex>
      {batchView ? <BatchReview setBatchView={setBatchView} /> : ""}

      <ToastContainer />
    </Box>
  );
};

export default Model3dReviewAsset;
