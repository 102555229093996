import { Box, Spinner, Text, Button, Image, Input } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { AppleIcon, GoogleIcon, MicroSoftIcon } from "../../components/Icons";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router";
import Logo from "../../assets/images/FF_Symbol1.svg";
import LoginFooter from "./LoginFooter";

const LoginForm = () => {
  const navigate = useNavigate();
  const { setAuthToken, isAuthenticated } = React.useContext(AuthContext);
  const EMAIL_REGEX = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i);
  const [mailval, setMailval] = useState("");
  const [mailvalid, setMailvalid] = useState(true);
  const [loader, setLoader] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleOnMailChange = (e) => {
    setMailval(e.target.value);
  };

  const login = async () => {
    const w = window.open("https://api.finalfinal.one/login/google", "_self");
    console.log("Location: ", w.location.href);
  };

  useEffect(() => {
    if (mailval.length && EMAIL_REGEX.test(mailval)) {
      setMailvalid(false);
    } else {
      setMailvalid(true);
    }
  }, [mailval]);

  useEffect(() => {
    if (loader) {
      setTimeout(() => {
        setSuccess(true);
      }, 1000);
    } else {
      setSuccess(false);
    }
  }, [success, loader]);

  return (
    <Box className="login">
      <Box backgroundColor="newPr.400" p="42px 12px 19px" borderRadius="8px">
        <Box className="loginHeader">
          <Image src={Logo} alt="logo" />
        </Box>
        {success ? (
          <Box className="loginSuccess">
            <Text as="h2">Check your mail</Text>
            <Text as="p">
              We’ve sent you a verification link to{" "}
              <Text as="span">nischal.masand@gmail.com. </Text>
              Follow the instructions on the mail to login
            </Text>
          </Box>
        ) : (
          <Box className="loginContainer">
            <Box className="loginHead">
              <Text as="h2" color="newPr.500">
                Welcome to Final_Final
              </Text>
              <Text as="p" color="newPr.500">
                The last word in creative feedback
              </Text>
            </Box>
            <form>
              <Box className="loginFormField">
                <Input
                  onChange={handleOnMailChange}
                  value={mailval}
                  type="email"
                  placeholder="Email"
                  name="email"
                  backgroundColor="newPr.600"
                  color="newPr.700"
                  _placeholder={{ color: "newPr.700" }}
                />
              </Box>
              <Box className="loginFormSubmit">
                {loader ? (
                  <Box className="loginFormLoader">
                    <Spinner
                      color="#000000"
                      emptyColor="#AFAFAF"
                      thickness="2px"
                      w="22px"
                      h="22px"
                    />
                  </Box>
                ) : (
                  <button
                    type="submit"
                    disabled={mailvalid}
                    onClick={() => setLoader(true)}
                  >
                    Continue
                  </button>
                )}
              </Box>
              <Box className="loginFormSignUp">
                <Text as="p" color="newPr.800">
                  Don’t have an account? <span>Sign up</span>
                </Text>
              </Box>
            </form>
            <Box className="loginSeperator">
              <Text as="p">or</Text>
            </Box>
            <Box className="loginOptions">
              <Box onClick={login}>
                <Button>Continue with Google</Button>
                <GoogleIcon color="#9a9a9a" />
              </Box>
              <Box>
                <Button>Continue with Microsoft</Button>
                <MicroSoftIcon color="#9a9a9a" />
              </Box>
              <Box>
                <Button>Continue with Apple</Button>
                <AppleIcon color="#9a9a9a" />
              </Box>
            </Box>
          </Box>
        )}
        <LoginFooter />
      </Box>
    </Box>
  );
};

export default LoginForm;
