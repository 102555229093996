import {
  Box,
  Flex,
  Image,
  Skeleton,
  SkeletonCircle,
  SkeletonText,
  Text,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import Asset from "../../assets/images/review/carAsset.jpg";
import Lines from "../../assets/images/review/lines.png";
import FinalBg from "../../assets/images/review/Final_Final_128_1X1_Tile.png";
import Bg from "../../assets/images/review/Darkpattern.png";
import Cur from "../../assets/images/review/commentHover.svg";
import { useRef } from "react";
import { MyContext } from "../../context";
import Comments from "../../components/Comments/Comments";
import ReviewMarkup from "../../components/ReviewMarkup/ReviewMarkup";
import KonvaImageContainer from "../ImageReview/KonvaImageContainer";
import {
  BatchReviewIcon,
  CursorPlus,
  DownloadIcon,
  GridviewIcon,
  ListviewIcon,
  Plus,
  ShareIcon,
} from "../../components/Icons";
import { SelectedImageContext } from "../../context/SelectedImageContext";
import ProjectGridView from "../../components/ProjectGridView";
import BatchReview from "../../components/BatchReview";
import { GET_ASSETS_BY_PROJECT_API } from "../../constants/url";
import { ProjectContext } from "../../context/ProjectContext";
import useFinalAxiosWrapper from "../../utils/FinalApi";
import { useNavigate } from "react-router";
import { AuthContext } from "../../context/AuthContext";

import "react-toastify/dist/ReactToastify.css";

const MODES = Object.freeze({
  RECTANGLE: "rectangle",
  DRAG: "drag",
  ARROW: "arrow",
  LINE: "line",
  PEN: "pen",
  DEFAULT: "default",
});

const ReviewAsset = ({
  openComment,
  addReply,
  selectAnnotation,
  comments,
  selectedAnnotation,
  setOpenComment,
  setProjectsView,
  projectsView,
  assetDetails,
  ...restProps
}) => {
  const { setSelectedComment, isMousePositionedOutside, isDragging } =
    useContext(SelectedImageContext);
  const { selectedProject, setSelectedAssetId, selectedAsset } =
    useContext(ProjectContext);
  const { deleteAsset } = useContext(AuthContext);
  const navigate = useNavigate();

  const { post } = useFinalAxiosWrapper();
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const [isHovering, setHovering] = useState(true);
  const [selectedMode, setSelectedMode] = useState(MODES.DEFAULT);
  const { theme } = useContext(MyContext);
  const [openMarkup, setOpenMarkup] = useState(false);
  const AssetRef = useRef();
  const [projectAssets, setProjectAssets] = React.useState([]);
  const [markupInitialPoint, setMarkupInitialPoint] = useState({
    left: "",
    top: "",
  });
  const [deleteIds, setDeleteIds] = useState([]);
  const [deleteStatus, setdeleteStatus] = useState("");

  const [batchView, setBatchView] = useState(false);

  const handleMouseEnter = () => {
    setHovering(true);
  };

  const handleMouseLeave = () => {
    setHovering(false);
  };

  const handleMouseMove = (e) => {
    setCursorPosition({ x: e.pageX, y: e.pageY });
  };

  const onNewAnnotationClick = () => {
    if (!isMousePositionedOutside && !isDragging) {
      setOpenMarkup(true);
      setSelectedComment(null);
      if (selectedMode === "default") {
        setSelectedMode(MODES.PEN);
      }
    }
  };
  const handleKeyPress = (event) => {
    if (
      event.keyCode === 71 &&
      event.target.tagName !== "TEXTAREA" &&
      event.target.tagName !== "INPUT"
    ) {
      if (projectsView === "list") {
        setProjectsView("grid");
        setOpenComment(false);
      } else if (projectsView === "grid") {
        setProjectsView("list");
        setOpenComment(true);
      }
    }

    if (
      (event.key === "f" ||
        event.key === "F" ||
        event.key === "c" ||
        event.key === "C") &&
      event.target.tagName !== "TEXTAREA" &&
      event.target.tagName !== "INPUT"
    ) {
      if (openComment) {
        setOpenComment(false);
      } else {
        setOpenComment(true);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [projectsView, openComment]);

  const fetchAssets = async () => {
    if (selectedAsset) {
      await post(GET_ASSETS_BY_PROJECT_API, {
        projectId: selectedAsset?.project,
      }).then((res) => {
        setProjectAssets(res.data.assets);
      });
    }
  };

  useEffect(() => {
    fetchAssets();
  }, [selectedProject, selectedAsset, projectsView, deleteStatus]);

  const onAssetClick = (asset) => {
    const { mimeType } = asset;
    setProjectsView("list");
    setOpenComment(true);
    setSelectedAssetId(asset._id);
    if (mimeType === "image/jpeg" || mimeType === "image/png") {
      navigate(`/imageReview/${asset._id}`);
    } else if (mimeType === "model/gltf-binary") {
      navigate(`/3dreview/${asset._id}`);
    } else if (mimeType === "video/mp4" || mimeType === "video/quicktime") {
      navigate(`/videoReview/${asset._id}`);
    }
  };

  const deleteProjectAssets = async () => {
    const response = await deleteAsset(deleteIds);
    setdeleteStatus(response?.data?.message);
    toast(response?.data?.message);
    setDeleteIds([]);
  };

  return (
    <Box h="calc(100dvh - 63px)" align="center" id="flexCon">
      <Flex h="calc(100dvh - 48px)" p="16px 20px">
        <Box width={openComment ? "calc(100vw - 380px)" : "100%"}>
          {projectsView === "list" ? (
            <Box
              width={openComment ? "calc(100vw - 380px)" : "100%"}
              // transition="all 0.3s ease-in-out"
              flexBasis={openComment ? "calc(100dvw - 360px)" : "100%"}
            >
              <Box
                position="relative"
                // backgroundImage={FinalBg}
                className="assetContainerBg"
                onClick={(e) => {
                  setMarkupInitialPoint({
                    ...markupInitialPoint,
                    left: e?.pageX,
                    top: e?.pageY,
                  });
                }}
                backgroundColor="pr.900"
              >
                {isHovering && selectedMode === "default" && (
                  <Box
                    className="custom-cursor"
                    zIndex="9999999"
                    style={{ left: cursorPosition.x, top: cursorPosition.y }}
                  >
                    <Flex className="custom-cursor-field">
                      <Flex className="custom-cursor-icon" as="span">
                        <Plus color="#0D0D0D" w="10px" h="10px" />
                        {/* <CursorPlus /> */}
                      </Flex>
                      <Box as="p">Click to leave a comment</Box>
                    </Flex>
                  </Box>
                )}

                <Box
                  ref={AssetRef}
                  className="assetContainer"
                  flex="1 1 auto"
                  width="inherit"
                  onClick={onNewAnnotationClick}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  onMouseMove={handleMouseMove}
                >
                  <KonvaImageContainer
                    {...restProps}
                    setSelectedMode={setSelectedMode}
                    selectAnnotation={selectAnnotation}
                    selectedMode={selectedMode}
                    openComment={openComment}
                    mode={selectedMode}
                    openMarkup={openMarkup}
                    setOpenMarkup={setOpenMarkup}
                    setMode={setSelectedMode}
                  />
                </Box>
                {openMarkup ? (
                  <ReviewMarkup
                    setSelectedMode={setSelectedMode}
                    selectedMode={selectedMode}
                    markupInitialPoint={markupInitialPoint}
                  />
                ) : (
                  ""
                )}
              </Box>
            </Box>
          ) : (
            ""
          )}
          {projectsView === "grid" ? (
            <ProjectGridView
              assets={projectAssets}
              reviewGrid={true}
              onAssetClick={onAssetClick}
              deleteIds={deleteIds}
              setDeleteIds={setDeleteIds}
              setdeleteStatus={setdeleteStatus}
            />
          ) : (
            ""
          )}

          <Flex className="projectsFooter">
            <Flex className="projectsFooterLeft">
              <Flex className="projectsFooterFiles">
                <Flex
                  align="center"
                  gap="4px"
                  className="projectsFooterFilesContent"
                >
                  <Text as="span" color="pr.300">
                    {"←"}
                  </Text>
                  <Text as="p" color="pr.300">
                    Files 1 / 5
                  </Text>
                  <Text as="span" color="pr.300">
                    {"→"}
                  </Text>
                </Flex>
                <Flex className="projectsFooterIcon">
                  <ShareIcon color="pr.300" />
                </Flex>
                <Flex className="projectsFooterIcon">
                  <DownloadIcon color="pr.300" />
                </Flex>
              </Flex>
              <Text as="span" className="footerSeperator"></Text>
              <Flex className="projectsFooterFiles">
                <Flex
                  className={
                    projectsView === "list"
                      ? "projectsFooterViews activeView"
                      : "projectsFooterViews"
                  }
                  onClick={() => setProjectsView("list")}
                >
                  <ListviewIcon color="pr.300" />
                </Flex>
                <Flex
                  className={
                    projectsView === "grid"
                      ? "projectsFooterViews activeView"
                      : "projectsFooterViews"
                  }
                  onClick={() => setProjectsView("grid")}
                >
                  <GridviewIcon color="pr.300" />
                </Flex>
                {/* <Flex
                  className={
                    projectsView === "grid"
                      ? "projectsFooterViews activeView"
                      : "projectsFooterViews"
                  }
                  onClick={() => setBatchView(true)}>
                  <BatchReviewIcon />
                </Flex> */}
              </Flex>
              {projectsView === "grid" && deleteIds.length ? (
                <button className="delete" onClick={deleteProjectAssets}>
                  Delete
                </button>
              ) : (
                ""
              )}
              {/* <Flex as="h4" className="projectFilesTitle">
                  Name{" "}
                  <Text as="span">
                    <SortIcon />
                  </Text>
                </Flex> */}
            </Flex>
            {projectsView === "grid" ? (
              ""
            ) : (
              <Box className="assetName">
                <Text as="h3" color="pr.300">
                  D8_ATL_Exterior_Performance
                </Text>
              </Box>
            )}
            <Flex gap="12px">
              <Flex
                className="projectsFooterFullscreen"
                onClick={() => setOpenComment(!openComment)}
              >
                <Text as="p" backgroundColor="pr.200" color="pr.300">
                  C
                </Text>
                <Text as="h3" color="pr.300">
                  Comments
                </Text>
              </Flex>
              <Flex
                className="projectsFooterFullscreen"
                onClick={() => setOpenComment(!openComment)}
              >
                <Text as="p" backgroundColor="pr.200" color="pr.300">
                  F
                </Text>
                <Text as="h3" color="pr.300">
                  Full screen
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Box>

        <Comments
          setSelectedMode={setSelectedMode}
          setOpenMarkup={setOpenMarkup}
          openComment={openComment}
          setOpenComment={setOpenComment}
          addReply={addReply}
          selectAnnotation={selectAnnotation}
          comments={comments}
          selectedAnnotation={selectedAnnotation}
        />
      </Flex>

      {batchView ? <BatchReview setBatchView={setBatchView} /> : ""}

      <ToastContainer />
    </Box>
  );
};

export default ReviewAsset;
