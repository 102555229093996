import React from "react";
import {
  Box,
  Grid,
  GridItem,
  Heading,
  InputGroup,
  Input,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Button,
  InputLeftElement,
  Tabs,
  TabIndicator,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Spinner,
} from "@chakra-ui/react";
import ReactCursorPosition from "react-cursor-position";
import { ArrowDown, ArrowUp, Plus, Search } from "tabler-icons-react";
import { v1 as uuid } from "uuid";
import { Link } from "react-router-dom";
import AddProjectContainer from "../../components/AddProject/AddProjectContainer";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ImageUploadContainer from "../../components/ImageUpload/ImageUploadContainer";
import ProjectItem from "./ProjectItem";
import AssetItem from "./AssetItem";
import { ProjectContext } from "../../context/ProjectContext";
import { AuthContext } from "../../context/AuthContext";
import useFinalAxiosWrapper from "../../utils/FinalApi";
import { GET_ASSETS_BY_PROJECT_API, GET_PROJECTS_BY_OWNER_API } from "../../constants/url";

const ProjectRootContiner = (props) => {
  const { get, post } = useFinalAxiosWrapper();
  const {
    setSelectedAssetId,
    selectedProject,
    setSelectedProject,
  } = React.useContext(ProjectContext);
  const [showImageUpload, setShowImageUpload] = React.useState(false);
  const [showAddProjectModal, setShowAddProjectModal] = React.useState(false);
  const [projectsLoading, setProjectsLoading] = React.useState(false);
  const [foldersLoading, setFoldersLoading] = React.useState(false);
  const [projects, setProjects] = React.useState([]);
  const [projectAssets, setProjectAssets] = React.useState([]);
  const navigate = useNavigate();

  const fetchProjects = async () => {
    await post(GET_PROJECTS_BY_OWNER_API, {
      ownerId: "cc314db7-1d2b-4199-83f4-12622b044d20",
    })
      .then((res) => {
        setProjects(res.data.projects);
        if (res.data.projects.length > 0) {
          setSelectedProject(selectedProject || res.data.projects[0]);
        }
        setProjectsLoading(false);
      })
      .catch((err) => {
        console.log("Error: ", err);
      });
  };

  const fetchAssets = async () => {
    setFoldersLoading(true);
    if (selectedProject) {
      await post(GET_ASSETS_BY_PROJECT_API, {
        projectId: selectedProject._id,
      }).then((res) => {
        setProjectAssets(res.data.assets);
        setFoldersLoading(false);
      });
    }
  };

  React.useEffect(() => {
    setProjectsLoading(true);
    fetchProjects();
  }, []);

  React.useEffect(() => {
    fetchAssets();
  }, [selectedProject]);

  const onProjectClick = (id) => {
    const selectedProject = projects.find((project) => project._id === id);
    setSelectedProject(selectedProject);
  };

  const onImageUploadClose = () => {
    setShowImageUpload(false);
  };

  const onAddProjectClick = (e) => {
    e.stopPropagation();
    setShowAddProjectModal(true);
  };

  const onAddProjectClose = () => {
    setShowAddProjectModal(false);
  };

  const onAddNewFileClick = () => {
    setShowImageUpload(true);
  };

  const onAssetClick = (asset) => {
    const { mimeType } = asset;
    setSelectedAssetId(asset._id);
    if (mimeType === "image/jpeg" || mimeType === "image/png") {
      navigate(`/imageReview/${asset._id}`);
    } else if (mimeType === "model/gltf-binary") {
      navigate(`/3dreview/${asset._id}`);
    } else if (mimeType === "video/mp4" || mimeType === "video/quicktime") {
      navigate(`/videoReview/${asset._id}`);
    }
  };

  const NewFileComponent = (
    <Box
      key={"add_new_file"}
      textAlign="left"
      style={{ fontSize: "24px", height: "240px", width: "320px" }}
      cursor={"pointer"}
      onClick={onAddNewFileClick}
    >
      <Plus size={24} strokeWidth={1} color="white" />
    </Box>
  );

  const folderContents = React.useMemo(() => {
    return projectAssets.map((folder, index) => {
      const { thumbnailCount } = folder;
      if (thumbnailCount > 1) {
        return (
          <ReactCursorPosition key={folder._id}>
            <AssetItem
              index={index}
              folder={folder}
              onAssetClick={onAssetClick}
            />
          </ReactCursorPosition>
        );
      } else {
        return (
          <AssetItem
            key={folder._id}
            index={index}
            folder={folder}
            onAssetClick={onAssetClick}
          />
        );
      }
    });
  }, [projectAssets, selectedProject]);

  const FolderViewContent = [NewFileComponent, ...folderContents];

  return (
    <Grid
      id="main_grid"
      templateAreas={`"projects folders"
    "projects folders"
 `}
      gridTemplateRows={"100px 1fr"}
      gridTemplateColumns={"300px 1fr"}
      gap="2"
      color="#888888"
      fontWeight="bold"
      height="inherit"
    >
      <GridItem
        pl="2"
        bg="#0F0F14"
        area={"projects"}
        paddingLeft={"23px"}
        paddingRight={"23px"}
        paddingTop={"20px"}
        width="272px"
      >
        <Box>
          <Heading
            as="h6"
            color="rgba(245, 245, 245, 0.5)"
            style={{
              fontFamily: "GreedMedium",
              fontSize: "20px",
              lineHeight: "22px",
              fontWeight: 500,
              letterSpacing: "0.00em",
            }}
            onClick={() => navigate("/")}
            cursor="pointer"
          >
            Finll
          </Heading>
        </Box>
        <Box
          color="#D9D9D9"
          style={{
            fontFamily: "GreedMedium",
            fontSize: "28px",
            marginTop: "28px",
            height: "100%",
          }}
        >
          <InputGroup
            border="0px transparent"
            display={"flex"}
            alignItems={"center"}
          >
            <InputLeftElement pointerEvents="none">
              <Search color="#D9D9D9" size={9} />
            </InputLeftElement>
            <Input type="text" placeholder="Search" fontSize={"20px"} />
          </InputGroup>
          {projectsLoading ? (
            <Box
              height="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="#A2A2A2"
                size="xl"
              />
            </Box>
          ) : (
            <Accordion allowToggle marginTop="28px" width="100%">
              <AccordionItem border="none">
                {({ isExpanded }) => (
                  <>
                    <AccordionButton
                      height="60px"
                      fontSize="24px"
                      paddingLeft={"4px"}
                    >
                      <Box
                        flex="1"
                        textAlign="left"
                        display="flex"
                        alignItems="center"
                        height="36px"
                        justifyContent={"space-between"}
                      >
                        {isExpanded ? <ArrowUp /> : <ArrowDown />}
                        <Box
                          width="20px"
                          height="20px"
                          fontSize="16px"
                          display={"flex"}
                          justifyContent={"center"}
                          bg={"#ff6465"}
                          color="#1D1D27"
                          alignItems="center"
                          margin="0px 4px"
                          borderRadius={"4px"}
                        >
                          T
                        </Box>
                        <Box
                          marginLeft={"4px"}
                          fontWeight={500}
                          fontSize="16px"
                        >
                          Tonics Team
                        </Box>
                        {/* <Button
                          component={Link}
                          to="/addImage"
                          onClick={onAddProjectClick}
                          p={0}
                          marginLeft={"10px"}
                          height="20px"
                          width="20px"
                        >
                          <Plus size={16} />
                        </Button> */}
                      </Box>
                    </AccordionButton>

                    <AccordionPanel pb={4}>
                      <Box>
                        {projects?.length
                          ? projects.map((project) => {
                              return (
                                <ProjectItem
                                  selectedProject={selectedProject}
                                  projectId={project._id}
                                  key={project._id}
                                  onProjectClick={onProjectClick}
                                  name={project.name}
                                />
                              );
                            })
                          : null}
                      </Box>
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
            </Accordion>
          )}
        </Box>
      </GridItem>
      <GridItem
        pl="2"
        bg="grey.900"
        area={"folders"}
        backgroundColor={"#0f0f14 "}
      >
        <Box
          color="#F5F5F5"
          style={{
            fontFamily: "GreedMedium",
            fontSize: "24px",
            fontWeight: 400,
          }}
          height="40px"
          marginTop={"72px"}
          backgroundColor={"#0f0f14"}
          display="flex"
          justifyContent={"space-between"}
          marginRight="40px"
          alignItems="center"
        >
          <Box>{selectedProject?.name}</Box>
          <Box display="flex" color="#666464" fontWeight={500} fontSize="14px">
            <Box p="0px 26px">Search</Box>
            <Box p="0px 26px">Select</Box>
            <Box p="0px 26px">View</Box>
            <Box p="0px 26px">Sort</Box>
          </Box>
        </Box>
        <Tabs marginTop="36px" height="100%">
          <TabList>
            <Tab>
              <Box color="#A2A2A2" fontWeight={500} fontSize="14px">
                All
              </Box>
            </Tab>
            <Tab>
              <Box color="#A2A2A2" fontWeight={500} fontSize="14px">
                Exteriors
              </Box>
            </Tab>
            <Tab>
              <Box color="#A2A2A2" fontWeight={500} fontSize="14px">
                Interiors
              </Box>
            </Tab>
            <Tab>
              <Box color="#A2A2A2" fontWeight={500} fontSize="14px">
                Profile
              </Box>
            </Tab>
            <Tab>
              <Plus
                size={9}
                strokeWidth={1}
                color="white"
                style={{ marginRight: "10px" }}
              />
              Collections
            </Tab>
          </TabList>
          <TabIndicator mt="-3.5px" height="4px" bg="#a30fff" />
          <TabPanels height="100%">
            <TabPanel maxHeight="830px" overflow="scroll" height="100%">
              {foldersLoading ? (
                <Box
                  height="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Spinner
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="#A2A2A2"
                    size="xl"
                  />
                </Box>
              ) : (
                <Box
                  id="content"
                  display="flex"
                  flexWrap="wrap"
                  justifyContent="flex-start"
                  marginTop="36px"
                  gap="40px"
                >
                  {selectedProject && FolderViewContent.length >= 1
                    ? FolderViewContent.map((folder, index) => {
                        return folder;
                      })
                    : null}
                </Box>
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </GridItem>
      {showAddProjectModal ? (
        <Box>
          <AddProjectContainer
            onClose={onAddProjectClose}
            fetchProjects={fetchProjects}
          />
        </Box>
      ) : null}
      {showImageUpload ? (
        <Box>
          <ImageUploadContainer
            onClose={onImageUploadClose}
            fetchAssets={fetchAssets}
            fetchProjects={fetchProjects}
          />
        </Box>
      ) : null}
    </Grid>
  );
};

export default ProjectRootContiner;
