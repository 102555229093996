import { Box, Spinner, Flex } from "@chakra-ui/react";
import { Text as RText } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { Button } from "@chakra-ui/react";
import { Stage, Layer, Line, Circle, Text, Arrow } from "react-konva";
import VideoReviewHeader from "./VideoReviewHeader";
import { SelectedVideoContext } from "../../context/SelectedVideoContext";
import VideoActionPanel from "./VideoActionPanel";
import VideoPlayer from "./VideoPlayer";
import "./ReactPlayer.scss";
import VideoHotkeys from "./VideoHotkeys";
import VideoMarkup from "./VideoMarkup";
import VideoComments from "./VideoComments";
import { Plus } from "../../components/Icons";
import ReviewHeader from "../Review/ReviewHeader";
import { GridviewIcon, ListviewIcon, ShareIcon } from "../../components/Icons";
import { DownloadIcon } from "@chakra-ui/icons";
import ProjectGridView from "../../components/ProjectGridView";
import { ProjectContext } from "../../context/ProjectContext";
import { useNavigate } from "react-router";
import useFinalAxiosWrapper from "../../utils/FinalApi";
import { GET_ASSETS_BY_PROJECT_API } from "../../constants/url";

const VideoReviewContainer = () => {
  const containerRef = React.useRef(null);
  const stageRef = React.useRef(null);
  const {
    assetDetails,
    selectedMode,
    timeStamp,
    setTimeStamp,
    latestArrow,
    setLatestArrow,
    latestLine,
    setLatestLine,
    latestMarker,
    setLatestMarker,
    markers,
    setMarkers,
    lines,
    setLines,
    arrowAnnotation,
    setArrowAnnotation,
    allComments,
    allAnnotations,
    selectedAnnotation,
    setSelectedAnnotation,
    selectedComment,
    selectedCommentIndex,
    selectedColor,
    isPlaying,
    setIsPlaying,
    mainPlayer,
  } = useContext(SelectedVideoContext);
  const { selectedProject, setSelectedAssetId, selectedAsset } =
  useContext(ProjectContext);
  console.log("🚀 ~ VideoReviewContainer ~ selectedAsset:", selectedAsset)
  const { post } = useFinalAxiosWrapper();

  const navigate = useNavigate();

  const [isLoaded, setLoaded] = useState(false);
  const [transcodingState, setTranscoding] = useState(5);
  const [isLoadingFailed, setLoadingFailed] = useState(false);
  const [loadMarkers, setLoadmarkers] = useState(false);
  const [isDrawing, toggleDrawing] = useState(false);
  const [openComment, setOpenComment] = useState(true);
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const [isHovering, setHovering] = useState(false);
  const [visibleAnnotations, setVisibleAnnotations] = useState([]);
  const [projectsView, setProjectsView] = useState("list");
  const [projectAssets, setProjectAssets] = useState([]);
  const [deleteIds, setDeleteIds] = useState([]);
  const [deleteStatus, setdeleteStatus] = useState("");

  const handleKeyPress = (event) => {
    if (
      event.keyCode === 71 &&
      event.target.tagName !== "TEXTAREA" &&
      event.target.tagName !== "INPUT"
    ) {
      if (projectsView === "list") {
        setProjectsView("grid");
        setOpenComment(false);
      } else if (projectsView === "grid") {
        setProjectsView("list");
        setOpenComment(true);
      }
    }

    if (
      (event.key === "f" ||
        event.key === "F" ||
        event.key === "c" ||
        event.key === "C") &&
      event.target.tagName !== "TEXTAREA" &&
      event.target.tagName !== "INPUT"
    ) {
      if (openComment) {
        setOpenComment(false);
      } else {
        setOpenComment(true);
      }
    }
  };

  const fetchAssets = async () => {
    if (selectedAsset) {
      await post(GET_ASSETS_BY_PROJECT_API, {
        projectId: selectedAsset?.project,
      }).then((res) => {
        console.log("🚀 ~ fetchAssets ~ res:", res);
        setProjectAssets(res.data.assets);
      });
    }
  };

  useEffect(() => {
    fetchAssets();
  }, [selectedProject, selectedAsset, projectsView, deleteStatus]);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [openComment]);

  const onAssetClick = (asset) => {
    const { mimeType } = asset;
    setProjectsView("list");
    setOpenComment(true);
    setSelectedAssetId(asset._id);
    if (mimeType === "image/jpeg" || mimeType === "image/png") {
      navigate(`/imageReview/${asset._id}`);
    } else if (mimeType === "model/gltf-binary") {
      navigate(`/3dreview/${asset._id}`);
    } else if (mimeType === "video/mp4" || mimeType === "video/quicktime") {
      navigate(`/videoReview/${asset._id}`);
    }
  };

  const videoJsOptions = {
    autoplay: false,
    controls: true,
    responsive: true,
    fluid: true,
    language: "en",
    preload: "metadata",
    playbackRates: [0.25, 0.5, 1, 1.5, 2],
    width: 100,
    inactivityTimeout: 0,
    html5: {
      vhs: {
        enableLowInitialPlaylist: false,
        smoothQualityChange: false,
        overrideNative: false,
      },
    },
    plugins: {
      qualityLevel: {},
      hlsQualitySelector: {
        displayCurrentQuality: true,
      },
    },
    controlBar: {
      children: [
        "playToggle",
        "volumePanel",
        "currentTimeDisplay",
        "timeDivider",
        "durationDisplay",
        "progressControl",
        "liveDisplay",
        "customControlSpacer",
        "chaptersButton",
        "descriptionsButton",
        "subsCapsButton",
        "audioTrackButton",
        "playbackRateMenuButton",
        "fullscreenToggle",
      ],
      progressControl: {
        seekBar: true,
      },
      pictureInPictureToggle: false,
      remainingTimeDisplay: {
        displayNegative: false,
      },
    },
    sources: [],
    assetDetails: assetDetails,
  };

  useEffect(() => {
    if (mainPlayer?.current && selectedAnnotation?.timeStart) {
      // Hide custom cursor when showing annotation
      setHovering(false);
      // Show cursor: default in konvajs-content
      let konvaContentCursor =
        document.getElementsByClassName("konvajs-content");
      if (konvaContentCursor[0]) konvaContentCursor[0].style.cursor = "default";
      // Show annotations
      let annoData = {
        annotation: selectedAnnotation,
        marker: selectedAnnotation.marker,
        timeStart: selectedAnnotation.timeStart,
      };
      setVisibleAnnotations([annoData]);
      // Seek to particular time
      mainPlayer?.current?.currentTime(selectedAnnotation?.timeStart);
      //
    }
  }, [selectedAnnotation]);

  useEffect(() => {
    if (!loadMarkers) return;
    if (allAnnotations.length == 0) return;
    if (allComments.length == 0) return;
    if (allComments.length != allAnnotations.length) return;
    if (!mainPlayer?.current) return;
    //
    // Add dots to the player
    let player = mainPlayer?.current;
    // Note: First clear all markers and remove onclick events from comments
    // player.markerPlugin({
    //   // dot information
    //   markers: [{
    //     offset: 18.5,
    //     type: 'text',
    //     data: {
    //       content: 'mark point info 1 (8.5)'
    //     }
    //   },
    //   {
    //     offset: 13.5,
    //     type: 'text',
    //     data: {
    //       content: 'mark point info 2 (13.5)'
    //     }
    //   },
    //   {
    //     offset: 1.5,
    //     type: 'text',
    //     data: {
    //       content: 'mark point info 3 (19.5)'
    //     }
    //   },
    //   {
    //     offset: 2,
    //     type: 'text',
    //     data: {
    //       content: 'mark point info 4 (26.5)'
    //     }
    //   },
    //   {
    //     offset: 8.5,
    //     type: 'text',
    //     data: {
    //       content: 'mark point info 1 (8.5)'
    //     }
    //   },
    //   {
    //     offset: 3.5,
    //     type: 'text',
    //     data: {
    //       content: 'mark point info 2 (13.5)'
    //     }
    //   },
    //   {
    //     offset: 9.5,
    //     type: 'text',
    //     data: {
    //       content: 'mark point info 3 (19.5)'
    //     }
    //   },
    //   {
    //     offset: 6,
    //     type: 'text',
    //     data: {
    //       content: 'mark point info 4 (26.5)'
    //     }
    //   }],
    //   // Whether to show the right panel
    //   panel: false
    // });
    // Empty .vjs-marker-bar
    // let markerBar = document.getElementsByClassName('vjs-marker-bar');
    // for(let i = 0; i < markerBar.length; i++){
    //   markerBar[i].innerHTML = '';
    // }
    //
    // Add updated markers
    // Loop over all comments and find the corresponding annotation
    for (let i = 0; i < allComments.length; i++) {
      let commentData = allComments[i];
      let annotationId = commentData.annotation;
      let annotationData = allAnnotations.find((x) => x.id === annotationId);
      if (annotationData) {
        annotationData.commentData = commentData;
      }
    }
    //
    let dotsData = [];
    for (let i = 0; i < allAnnotations.length; i++) {
      let annoData = allAnnotations[i];
      let timeStart = annoData.timeStart;
      let comment = annoData.commentData?.comment;
      if (comment != undefined) {
        let dotsInfo = {
          offset: timeStart,
          type: "text",
          data: {
            content: comment,
          },
        };
        dotsData.push(dotsInfo);
      } else {
      }
    }

    // Add markers
    player.markerPlugin({
      // dot information
      markers: dotsData,
      // Whether to show the right panel
      panel: false,
    });

    // Loop over vjs-marker-point-tip-content class
    let markerPointTipContent = document.getElementsByClassName(
      "vjs-marker-point-tip-content"
    );
    for (let i = 0; i < markerPointTipContent.length; i++) {
      // Get parent.parent.parent of the comment
      let parent = markerPointTipContent[i].parentElement;
      let parentParent = parent.parentElement;
      let parentParentParent = parentParent.parentElement;
      // Remove background of parentParentParent
      parentParentParent.style.background = "none";
      // Read the comment
      let comment = markerPointTipContent[i].innerHTML;
      // Find the corresponding comment
      let commentData = allComments.find((x) => x.comment === comment);
      if (commentData) {
        // Get comment count
        let counter = commentData.counter;
        // Span to add
        let span = document.createElement("span");
        span.innerHTML = String(counter).padStart(2, "0"); // '09'
        span.style.fontSize = "10px";
        // Add to end of parentParentParent
        parentParentParent.appendChild(span);
      }
      // on click of the comment
      parentParentParent.onclick = function () {
        //
        if (mainPlayer.current) {
          // Start video if required
          if (mainPlayer.current.hasStarted() === false) {
            mainPlayer.current.hasStarted(true);
            // Show konvajs-content parent
            let konvaContent =
              document.getElementsByClassName("konvajs-content");
            let konvaContentParent = konvaContent[0].parentElement;
            konvaContentParent.style.display = "block";
          }
          // Pause video
          mainPlayer.current.pause();
          // Filer and show selected annotation
          const annotationsToShow = allAnnotations.filter(
            (annotation) => annotation.id === commentData.annotation
          );
          const updatedSelectedAnnotation = {
            ...annotationsToShow[0].annotation,
            marker: annotationsToShow[0].position,
            timeStart: annotationsToShow[0].timeStart,
          };
          setSelectedAnnotation(updatedSelectedAnnotation);
          //
        }
        //
      };
      //
    }
    //
    setLoadmarkers(true);
  }, [loadMarkers, allAnnotations, allComments, mainPlayer?.current]);

  const handlePlayerReady = (player) => {
    mainPlayer.current = player;
    window.player = player;
    // Load markers
    if (!loadMarkers) setLoadmarkers(true);
    //
    let videoJs = document.getElementsByClassName("video-js")[0];
    let videoJsParent = videoJs.parentElement;
    videoJsParent.style.opacity = "0";
    //
    // Show vjs-control-bar by default
    let controlBar = document.getElementsByClassName("vjs-control-bar")[0];
    controlBar.style.display = "flex";
    //
    player.on("loadedmetadata", function () {
      setLoaded(true);
      //
      //
      let assetData = player.options_.assetDetails;
      // Check if the video is transcoding
      if (assetData.transcoding != undefined) {
        setTranscoding(assetData.transcoding);
      }
      //
      let level_sizes = [];
      let levels = player.qualityLevels();
      for (let i = 0; i < levels.length; i++) {
        let level = levels[i];
        if (level.height < level.width) level_sizes.push(level.height);
        else level_sizes.push(level.width);
      }
      let max_size = Math.max(...level_sizes);
      // Set the quality to the highest
      player.hlsQualitySelector.setQuality(max_size);
      // Set the thumbnail sprite
      let thumbnailCount = assetData.thumbnailCount;
      let duration = player.duration();
      let interval = duration / thumbnailCount;
      let imageURL = assetData.thumbnailBaseUrl + assetData.thumbnailFilename;
      //
      let singleImageWidth = assetData.singleThumbnailSize.width;
      let singleImageHeight = assetData.singleThumbnailSize.height;
      let fullImageWidth = singleImageWidth * thumbnailCount;
      let fullImageHeight = singleImageHeight;
      //
      let standardWidth = 160;
      if (singleImageWidth < singleImageHeight) {
        let standardHeight = 160;
        let imageRatio = singleImageWidth / singleImageHeight;
        standardWidth = standardHeight * imageRatio;
      }
      //
      let newRatio = singleImageWidth / standardWidth;
      let newWidth = fullImageWidth / newRatio;
      let newHeight = fullImageHeight / newRatio;
      // Load downscaled thumbnail for video
      // Replace thumb-320-000.jpg to thumb-160-000.jpg
      imageURL = imageURL.replace("thumb-320", "thumb-160");
      // Add the thumbnail sprite to the player
      player.thumbnailSprite({
        sprites: [
          {
            url: imageURL,
            start: 0,
            duration: duration,
            interval: interval,
            width: standardWidth,
            height: newHeight,
          },
        ],
      });
      //
      // SHOW VIDEO-JS
      let videoJs = document.getElementsByClassName("video-js")[0];
      let videoJsParent = videoJs.parentElement;
      videoJsParent.style.opacity = "1";
      //
    });
    // You can handle player events here, for example:
    player.on("ready", () => {
      // Required for keyboard scrubbing
      // Remove scroll bars from body and child of finll-root
      let body = document.getElementsByTagName("body")[0];
      body.style.overflow = "hidden";
      body.style.width = "100%";
      body.style.height = "100%";
      let finllRoot = document.getElementsByClassName("finll-root")[0];
      let finllRootFirstChild = finllRoot.firstElementChild;
      finllRootFirstChild.style.overflow = "hidden";
      finllRootFirstChild.style.width = "100%";
      finllRootFirstChild.style.height = "100%";
      //
    });
    player.on("dispose", () => {
      console.log("handlePlayerReady dispose");
    });
    player.on("enterFullWindow", () => {
      console.log("handlePlayerReady enterFullWindow");
    });
    player.on("exitFullWindow", () => {
      console.log("handlePlayerReady exitFullWindow");
    });
    let default_bottom_value_control_bar = 0;
    let default_top_value_progress_bar = 0;
    player.on("fullscreenchange", () => {
      console.log("handlePlayerReady fullscreenchange");
      // Check if the player is in fullscreen
      if (player.isFullscreen()) {
        console.log("Player is fullscreen");
        // Hide marker bar: class .vjs-marker-bar
        let markerBar = document.getElementsByClassName("vjs-marker-bar");
        for (let i = 0; i < markerBar.length; i++) {
          markerBar[i].style.display = "none";
        }
        // .vjs-control-bar
        // Get the control bar bottom value & set it to 0
        let controlBar = document.getElementsByClassName("vjs-control-bar");
        if (controlBar.length > 0) {
          console.log("Got control bar");
          let controlBarBottom = controlBar[0].style.bottom;
          default_bottom_value_control_bar = controlBarBottom;
          controlBar[0].style.bottom = "0px";
        }
        // .vjs-progress-control
        // Get the progress bar bottom value a& set it to 0
        let progressBar = document.getElementsByClassName(
          "vjs-progress-control"
        );
        if (progressBar.length > 0) {
          console.log("Got progress bar");
          let progressBarTop = progressBar[0].style.top;
          default_top_value_progress_bar = progressBarTop;
          progressBar[0].style.top = "-3px";
        }
      } else {
        // Show marker bar: class .vjs-marker-point
        let markerBar = document.getElementsByClassName("vjs-marker-bar");
        for (let i = 0; i < markerBar.length; i++) {
          markerBar[i].style.display = "block";
        }
        // Bring back the control bar bottom value
        let controlBar = document.getElementsByClassName("vjs-control-bar");
        if (controlBar.length > 0) {
          controlBar[0].style.bottom = default_bottom_value_control_bar;
        }
        // Bring back the progress bar bottom value
        let progressBar = document.getElementsByClassName(
          "vjs-progress-control"
        );
        if (progressBar.length > 0) {
          progressBar[0].style.top = default_top_value_progress_bar;
        }
        //
      }
      //
    });
    //
    player.on("play", () => {
      console.log("handlePlayerReady play");
      // Hide konvajs-content parent
      let konvaContent = document.getElementsByClassName("konvajs-content");
      let konvaContentParent = konvaContent[0].parentElement;
      konvaContentParent.style.display = "none";
      // Clear annotations
      setSelectedAnnotation(null);
      setVisibleAnnotations([]);
      // Clear the konva stage
      let stage = stageRef.current;
      stage.clear();
      // Hide custom cursor when playing
      setHovering(false);
      // Show os cursor: default in konvajs-content
      let konvaContentCursor =
        document.getElementsByClassName("konvajs-content");
      konvaContentCursor[0].style.cursor = "default";
      //
      setIsPlaying(true);
    });
    player.on("pause", () => {
      console.log("handlePlayerReady pause");
      // Clear the konva stage
      let stage = stageRef.current;
      stage.clear();
      // Show konvajs-content parent
      let konvaContent = document.getElementsByClassName("konvajs-content");
      let konvaContentParent = konvaContent[0].parentElement;
      konvaContentParent.style.display = "block";
      // Show custom cursor when paused
      setHovering(true);
      // Set cursor position for cursor label
      let e = { x: videoJsOptions.width / 2, y: videoJsOptions.height / 2 };
      setCursorPosition(e);
      // Hide os cursor: none in konvajs-content
      let konvaContentCursor =
        document.getElementsByClassName("konvajs-content");
      konvaContentCursor[0].style.cursor = "none";
      //
      setIsPlaying(false);
    });
    player.on("error", () => {
      console.log("handlePlayerReady error");
      // Check if the video is transcoding
      let assetData = player.options_.assetDetails;
      if (assetData.transcoding != undefined) {
        setTranscoding(assetData.transcoding);
      }
      let err = player.error();
      console.log(err);
      setLoadingFailed(true);
    });
    player.on("aderror", () => {
      console.log("handlePlayerReady aderror");
      // Check if the video is transcoding
      let assetData = player.options_.assetDetails;
      if (assetData.transcoding != undefined) {
        setTranscoding(assetData.transcoding);
      }
      let err = player.error();
      console.log(err);
      setLoadingFailed(true);
    });
  };

  const getRelativePointerPosition = (node) => {
    const transform = node.getAbsoluteTransform().copy();
    transform.invert();
    const pos = node.getStage().getPointerPosition();
    return transform.point(pos);
  };

  const convertImageToBase64 = (imgUrl, callback) => {
    const image = new Image();
    image.crossOrigin = "anonymous";
    image.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      canvas.height = image.naturalHeight;
      canvas.width = image.naturalWidth;
      ctx.drawImage(image, 0, 0);
      const dataUrl = canvas.toDataURL();
      callback && callback(dataUrl);
    };
    image.src = imgUrl;
  };

  const handleMouseDown = (event) => {
    // return if there is selected annotation
    if (selectedAnnotation) return;
    //
    if (!latestMarker) {
      const point = getRelativePointerPosition(event.target.getStage());
      const { x, y } = point;
      const newMarker = {
        position: { x, y },
      };
      setLatestMarker(newMarker);
      setMarkers([newMarker]);
      setHovering(false);
      // Show cursor: inherit in konvajs-content
      let konvaContentCursor =
        document.getElementsByClassName("konvajs-content");
      konvaContentCursor[0].style.cursor = "inherit";
      return;
    }
    // if (selectedMode === "default") {
    //   toggleDrawing(true);
    //   const point = getRelativePointerPosition(event.target.getStage());
    //   const { x, y } = point;
    //   const newMarker = {
    //     position: { x, y },
    //   };
    //   setMarkers([newMarker]);
    // }
    if (selectedMode === "arrow") {
      toggleDrawing(true);
      const point = getRelativePointerPosition(event.target.getStage());
      const { x, y } = point;
      // const { offsetX, offsetY } = event.evt;
      const updatedArrow = {
        points: { x1: x, y1: y, x2: x, y2: y, color: selectedColor },
      };
      setLatestArrow(updatedArrow);
    }
    if (selectedMode === "pen") {
      toggleDrawing(true);
      const point = getRelativePointerPosition(event.target.getStage());
      const updatedLine = {
        points: [{ x: point.x, y: point.y }],
        color: selectedColor,
      };
      setLatestLine(updatedLine);
      // setLines([...lines, updatedLine]);
    }
  };

  const handleMouseMove = (event) => {
    // Set cursor position for cursor label
    let e = getRelativePointerPosition(event.target.getStage());
    setCursorPosition(e);
    //
    if (markers.length > 0) {
      event.target.getStage().container().style.cursor = "crosshair";
    }
    if (!isDrawing) {
      return;
    }
    if (selectedMode === "pen") {
      const point = getRelativePointerPosition(event.target.getStage());
      // add point
      const updatedPoints = latestLine?.points
        ? [...latestLine.points, { x: point.x, y: point.y }]
        : [{ x: point.x, y: point.y }];

      // replace last
      const updatedLine = {
        points: updatedPoints,
        color: latestLine?.color,
      };
      setLatestLine(updatedLine);
    }
    if (selectedMode === "arrow") {
      const { x, y } = getRelativePointerPosition(event.target.getStage());
      const updatedArrow = {
        points: {
          x1: latestArrow.points.x1,
          y1: latestArrow.points.y1,
          x2: x,
          y2: y,
          color: latestArrow.points.color,
        },
      };
      setLatestArrow(updatedArrow);
    }
  };

  useEffect(() => {
    if (mainPlayer?.current?.hasStarted()) {
      const currentTime = mainPlayer?.current?.currentTime();
      setTimeStamp(currentTime);
    }
  }, [isPlaying, setTimeStamp]);

  const handleMouseUp = (event) => {
    // if (markers.length === 0) {
    //   event.target.getStage().container().style.cursor = "none";
    //   return;
    // }
    if (markers.length > 0) {
      toggleDrawing(false);
      event.target.getStage().container().style.cursor = "crosshair";
    }
    if (selectedMode === "arrow") {
      toggleDrawing(false);
      if (
        latestArrow?.points.x1 === latestArrow?.points.x2 &&
        latestArrow?.points.y1 === latestArrow?.points.y2
      ) {
        setArrowAnnotation([...arrowAnnotation]);
        setLatestArrow(null);
        return;
      } else {
        setArrowAnnotation([...arrowAnnotation, latestArrow]);
        setLatestArrow(null);
      }
      event.target.getStage().container().style.cursor = "crosshair";
    } else if (selectedMode === "default") {
      event.target.getStage().container().style.cursor = "none";
    } else if (selectedMode === "pen") {
      toggleDrawing(false);
      setLines([...lines, latestLine]);
      setLatestLine(null);
      event.target.getStage().container().style.cursor = "crosshair";
    }
  };

  const handleMouseEnter = (event) => {};

  const handleKeyDown = (event) => {
    if (document.activeElement.tagName === "INPUT")
      console.log("An input is focused");
    else VideoHotkeys(event, mainPlayer);
  };

  let visibleArrows = [];
  let visibleMarkers = [];
  let visibleLines = [];

  visibleAnnotations?.forEach((annotation) => {
    annotation?.annotation?.lines.forEach((line) => {
      visibleLines.push({ points: line.points, color: line.color });
    });
  });
  visibleAnnotations?.forEach((annotation) => {
    visibleMarkers.push({ position: annotation?.marker });
  });
  visibleAnnotations?.forEach((annotation) => {
    const arrowPoints = annotation?.annotation?.arrows.points.map((arrow) => {
      return { points: arrow, color: arrow.color };
    });
    visibleArrows.push(...arrowPoints);
  });

  const LinesToDraw = latestLine
    ? [...lines, latestLine, ...visibleLines]
    : [...lines, ...visibleLines];
  const Markers = latestMarker
    ? [...markers, { position: latestMarker.marker }, ...visibleMarkers]
    : [...markers, ...visibleMarkers];
  const ArrowsToDraw = latestArrow
    ? [...arrowAnnotation, latestArrow, ...visibleArrows]
    : [...arrowAnnotation, ...visibleArrows];

  if (assetDetails?.url) {
    //
    let mainAssetUrl = assetDetails.url;
    // Remove the extension
    let currentDate = Date.now();
    let mainAssetFolder = mainAssetUrl.split(".").slice(0, -1).join(".");
    // Remove the last part of the url
    mainAssetFolder = mainAssetFolder.split("/").slice(0, -1).join("/");
    let assetUrl = mainAssetFolder + "/index.m3u8?t=" + currentDate;
    videoJsOptions.sources.push({
      src: assetUrl,
      type: "application/x-mpegURL",
    });
    //
    let assetData = videoJsOptions.assetDetails;
    let mediaWidth = assetData.dimensions.width;
    let mediaHeight = assetData.dimensions.height;
    let mediaRatio = mediaWidth / mediaHeight;
    let playerTop = 0;
    let playerLeft = 0;
    let playerHeight = 60;
    let playerWidth = playerHeight * mediaRatio;
    // This ensures that the Video.js player is responsive.
    playerHeight = (document.documentElement.clientHeight * playerHeight) / 100;
    playerWidth = (document.documentElement.clientHeight * playerWidth) / 100;
    let screenWidth = document.documentElement.clientWidth;
    // Check if the player width is greater than the screen width
    if (playerWidth > screenWidth) {
      let remoffset =
        parseInt(getComputedStyle(document.documentElement).fontSize) * 2;
      playerWidth = screenWidth - remoffset;
      playerHeight = playerWidth / mediaRatio;
    }
    // Decide on resizing based on width
    let resizeWidth = false;
    // let fadeInComments = document.getElementsByClassName('fadeInComments')[0];
    // let fadeInCommentsWidth = fadeInComments.offsetWidth;
    let fadeInCommentsWidth = 400; // Avoid hardcoding like this
    // check if we have to resize based on width
    if (openComment) {
      if (screenWidth - fadeInCommentsWidth < playerWidth) {
        resizeWidth = true;
      }
    }
    // Reduce playerWidth by fadeInComments class size
    if (resizeWidth) {
      let wrapperWidth = screenWidth - fadeInCommentsWidth;
      if (playerWidth > wrapperWidth) playerWidth = wrapperWidth;
      videoJsOptions.width = playerWidth;
      videoJsOptions.height = playerHeight;
      // Recalculate the player height for stage
      playerHeight = playerWidth / mediaRatio;
      playerTop = (videoJsOptions.height - playerHeight) / 2;
      videoJsOptions.kwidth = playerWidth;
      videoJsOptions.kheight = playerHeight;
      videoJsOptions.kTop = playerTop;
    } else {
      // Add the player width and height to the options
      videoJsOptions.width = playerWidth;
      videoJsOptions.height = playerHeight;
      videoJsOptions.kwidth = playerWidth;
      videoJsOptions.kheight = playerHeight;
      videoJsOptions.kTop = 0;
    }
    //
    // set kLeft
    let wrapper = document.getElementsByClassName("player-wrapper")[0];
    if (wrapper) {
      let wrapperWidth = wrapper.offsetWidth;
      playerLeft = (wrapperWidth - playerWidth) / 2;
      videoJsOptions.kLeft = playerLeft;
    }
    //
    // Return the complete video player component
    return (
      // <Box
      //   background="pr.300"
      //   borderRadius="6px"
      //   width="inherit"
      //   height="inherit"
      //   display="flex"
      //   flexDirection="column"
      //   overflow={"scroll"}
      // >
      <Box background="pr.100">
        <ReviewHeader
          projectsView={projectsView}
          title={assetDetails?.name || assetDetails?._id}
        />

        {/* <VideoActionPanel
          setOpenComment={setOpenComment}
          openComment={openComment}
          setProjectsView={setProjectsView}
          projectsView={projectsView}
        /> */}
        {/* <Box
          margin={4}
          width={"100%"}
          height={"inherit"}
          ref={containerRef}
          display="flex"
          alignSelf={"center"}
        > */}
        <Flex
          w="inherit"
          p="16px 20px"
          // overflow="hidden"
          h="calc(100dvh - 48px)"
        >
          <Box width={openComment ? "calc(100vw - 380px)" : "100%"}>
            {projectsView === "list" ? (
              <Box width={openComment ? "calc(100vw - 380px)" : "100%"}>
                <Box
                  position="relative"
                  className="assetContainerBg"
                  backgroundColor="pr.900"
                >
                  <Box
                    display="flex"
                    position="relative"
                    width="inherit"
                    flexDirection={"column"}
                    alignItems={"center"}
                    className="player-wrapper assetContainer"
                    onKeyDown={handleKeyDown}
                  >
                    <Stage
                      style={{
                        position: "absolute",
                        top: playerTop,
                        zIndex: 1000,
                        display: "none",
                      }}
                      ref={stageRef}
                      width={playerWidth}
                      height={playerHeight}
                      onMouseEnter={handleMouseEnter}
                      onMouseDown={handleMouseDown}
                      onMouseMove={handleMouseMove}
                      onMouseUp={handleMouseUp}
                    >
                      <Layer imageSmoothingEnabled>
                        {LinesToDraw.map((line, i) => {
                          let flattenedPoints = [];
                          if (!line) {
                            return null;
                          }
                          line.points.forEach((point) => {
                            flattenedPoints.push(point.x);
                            flattenedPoints.push(point.y);
                          });
                          return (
                            <Line
                              key={i}
                              points={flattenedPoints}
                              stroke={line.color || "#df4b26"}
                              strokeWidth={4}
                              tension={0.5}
                              lineCap="round"
                              globalCompositeOperation={
                                line.tool === "eraser"
                                  ? "destination-out"
                                  : "source-over"
                              }
                            />
                          );
                        })}
                        {ArrowsToDraw?.map((arrow, i) => {
                          return (
                            <Arrow
                              pointerLength={10}
                              pointerWidth={15}
                              key={i}
                              points={[
                                arrow.points.x1,
                                arrow.points.y1,
                                arrow.points.x2,
                                arrow.points.y2,
                              ]}
                              stroke={arrow.points.color || "#df4b26"}
                              fill={arrow.points.color || "#df4b26"}
                              strokeWidth={4}
                              lineCap="round"
                              globalCompositeOperation={
                                arrow.tool === "eraser"
                                  ? "destination-out"
                                  : "source-over"
                              }
                            />
                          );
                        })}
                        {Markers.map((marker, i) => {
                          if (!marker?.position) return null;
                          return (
                            <Layer>
                              <Circle
                                key={i}
                                x={marker?.position?.x}
                                y={marker?.position?.y}
                                radius={16}
                                fill={"#df4b26"}
                                stroke={"white"}
                                strokeWidth={2}
                              />
                              <Text
                                x={
                                  i < 9
                                    ? marker?.position?.x - 5
                                    : marker?.position?.x - 9
                                }
                                y={marker?.position?.y - 7}
                                text={
                                  latestMarker
                                    ? allComments.length + 1
                                    : selectedCommentIndex
                                }
                                fontSize={16}
                                stroke="white"
                                strokeWidth={2}
                                align="center"
                              />
                            </Layer>
                          );
                        })}
                      </Layer>
                    </Stage>
                    {!isLoadingFailed && !isLoaded && (
                      <Spinner
                        thickness="4px"
                        speed="0.65s"
                        emptyColor="gray.200"
                        color="#A2A2A2"
                        size="xl"
                      />
                    )}
                    {!isLoadingFailed && (
                      <VideoPlayer
                        options={videoJsOptions}
                        onReady={handlePlayerReady}
                      />
                    )}
                    {isLoadingFailed && (
                      <Box
                        background="pr.300"
                        borderRadius="6px"
                        width="100vw"
                        height="60vh"
                        display="flex"
                        flexDirection="column"
                        overflow={"scroll"}
                      >
                        <RText style={{ textAlign: "center" }}>
                          Media failed to load.
                        </RText>
                        {transcodingState == 0 && (
                          <RText style={{ textAlign: "center" }}>
                            Transcoding will start soon. Please try again in a
                            few minutes.
                          </RText>
                        )}
                        {(transcodingState == 1 || transcodingState == 2) && (
                          <RText style={{ textAlign: "center" }}>
                            Transcoding is in progress. Please wait till it is
                            complete.
                          </RText>
                        )}
                        {transcodingState == 3 && (
                          <RText style={{ textAlign: "center" }}>
                            Transcoding complete. Please refresh the page.
                          </RText>
                        )}
                        {transcodingState == 4 && (
                          <RText style={{ textAlign: "center" }}>
                            Transcoding failed. Please try again later.
                          </RText>
                        )}
                        {transcodingState == 5 && (
                          <RText style={{ textAlign: "center" }}>
                            Transcoding status unknown. Please contact support.
                          </RText>
                        )}
                      </Box>
                    )}
                    {isHovering && (
                      <Box
                        className="custom-cursor"
                        zIndex="9999999"
                        width="100%"
                        style={{
                          left: cursorPosition.x + videoJsOptions.kLeft + 20,
                          top:
                            cursorPosition.y +
                            20 -
                            videoJsOptions.kheight -
                            videoJsOptions.kTop,
                        }}
                      >
                        <Flex className="custom-cursor-field">
                          <Flex className="custom-cursor-icon" as="span">
                            <Plus color="#0D0D0D" w="10px" h="10px" />
                            {/* <CursorPlus /> */}
                          </Flex>
                          <Box as="p">Click to leave a comment</Box>
                        </Flex>
                      </Box>
                    )}
                    <VideoMarkup />
                  </Box>
                </Box>
              </Box>
            ) : (
              ""
            )}

            {projectsView === "grid" ? (
              <ProjectGridView
                assets={projectAssets}
                reviewGrid={true}
                onAssetClick={onAssetClick}
                deleteIds={deleteIds}
                setDeleteIds={setDeleteIds}
                setdeleteStatus={setdeleteStatus}
              />
            ) : (
              ""
            )}

            <Flex className="projectsFooter">
              <Flex className="projectsFooterLeft">
                <Flex className="projectsFooterFiles">
                  <Flex
                    align="center"
                    gap="4px"
                    className="projectsFooterFilesContent"
                  >
                    <Text as="span" color="pr.300">
                      {"←"}
                    </Text>
                    <Text as="p" color="pr.300">
                      Files 1 / 5
                    </Text>
                    <Text as="span" color="pr.300">
                      {"→"}
                    </Text>
                  </Flex>
                  <Flex className="projectsFooterIcon">
                    <ShareIcon color="pr.300" />
                  </Flex>
                  <Flex className="projectsFooterIcon">
                    <DownloadIcon color="pr.300" />
                  </Flex>
                </Flex>
                <Text as="span" className="footerSeperator"></Text>
                <Flex className="projectsFooterFiles">
                  <Flex
                    className={
                      projectsView === "list"
                        ? "projectsFooterViews activeView"
                        : "projectsFooterViews"
                    }
                    onClick={() => setProjectsView("list")}
                  >
                    <ListviewIcon color="pr.300" />
                  </Flex>
                  <Flex
                    className={
                      projectsView === "grid"
                        ? "projectsFooterViews activeView"
                        : "projectsFooterViews"
                    }
                    onClick={() => setProjectsView("grid")}
                  >
                    <GridviewIcon color="pr.300" />
                  </Flex>
                </Flex>
              </Flex>
              {projectsView === "grid" ? (
                ""
              ) : (
                <Box className="assetName">
                  <Text as="h3" color="pr.300">
                    D8_ATL_Exterior_Performance
                  </Text>
                </Box>
              )}
              <Flex gap="12px">
                <Flex
                  className="projectsFooterFullscreen"
                  onClick={() => setOpenComment(!openComment)}
                >
                  <Text as="p" backgroundColor="pr.200" color="pr.300">
                    C
                  </Text>
                  <Text as="h3" color="pr.300">
                    Comments
                  </Text>
                </Flex>
                <Flex
                  className="projectsFooterFullscreen"
                  onClick={() => setOpenComment(!openComment)}
                >
                  <Text as="p" backgroundColor="pr.200" color="pr.300">
                    F
                  </Text>
                  <Text as="h3" color="pr.300">
                    Full screen
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </Box>
          {openComment ? <VideoComments openComment={openComment} /> : null}
        </Flex>
        {/* </Box> */}
      </Box>
    );
  }
  return (
    <Box
      background="pr.100"
      display="flex"
      alignItems="center"
      justifyContent="center"
      h="100vh"
    >
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="#A2A2A2"
        size="xl"
      />
    </Box>
  );
};

export default VideoReviewContainer;
