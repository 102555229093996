//Auth context with jwt
import React, { createContext, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [token, setToken] = useState(null);
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(undefined);
  const [window, setWindow] = useState(undefined);

  const getUserDetails = async () => {
    const response = await axios.get(
      `https://app-api.finalfinal.one/api/user/get-user`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  };

  const deleteAsset = async (id) => {
    try {
      const data = {
        assetId: id,
      };

      const response = await axios.post(
        "https://app-api.finalfinal.one/api/public/asset/delete",
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Cookie:
              "connect.sid=s%3AD64iVqaR2kgLc9dBJ1ExuSVg8maV3koq.CDBk23rXmPAyRsGAycQz4Oo7Qvcgu3B823b4ao3baAs",
          },
        }
      );

      console.log(JSON.stringify(response.data));
      return response;
    } catch (error) {
      console.log(error);
      return error;
    }
  };

  const getAuthToken = () => {
    const token = localStorage.getItem("finll_token");
    if (token) {
      setIsAuthenticated(true);
      setToken(token);
    } else {
      setIsAuthenticated(false);
    }
  };

  React.useEffect(() => {
    getAuthToken();
  }, []);

  const setAuthToken = (token) => {
    if (token) {
      localStorage.setItem("finll_token", token);
      setToken(token);
      setIsAuthenticated(true);
      console.log("Token: ", token);
    } else {
      localStorage.removeItem("finll_token");
      setToken(null);
      setIsAuthenticated(false);
    }
  };

  const logout = () => {
    localStorage.removeItem("finll_token");
    setAuthToken(null);
    setUser(null);
    setIsAuthenticated(false);
    navigate("/login");
  };

  return (
    <AuthContext.Provider
      value={{
        token,
        setToken,
        user,
        setUser,
        isAuthenticated,
        setIsAuthenticated,
        setAuthToken,
        getAuthToken,
        logout,
        window,
        setWindow,
        deleteAsset,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
