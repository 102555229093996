export const URL = 'http://localhost:5021/'
export const API_URL = URL + 'api/'
export const TOKEN_NAME = 'amg_showrunner_token'

export const CREATE_PROJECT_API = '/project/create';
export const FILE_UPLOAD_API = '/file/upload';
export const CREATE_COMMENT_API = '/comment/create';
export const CREATE_ANNOTATION_API = '/annotation/create';
export const ASSET_DETAILS_API = '/asset/details';
export const GET_COMMENTS_BY_ASSET_API = '/comment/get-by-asset';
export const GET_ANNOTATIONS_BY_ASSET_API = '/annotation/get-by-asset';
export const GET_PROJECTS_BY_OWNER_API = '/project/get-by-owner';
export const GET_ASSETS_BY_PROJECT_API = '/asset/get-by-project';