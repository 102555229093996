import { Box } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

const LoginFooter = () => {
  return (
    <Box className="loginFooter">
      <Link to="">Terms of use </Link>
      <span>|</span>
      <Link to="">Privacy policy</Link>
    </Box>
  );
};

export default LoginFooter;
