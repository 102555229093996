import React, { useEffect, useState } from "react";
import { Box, Flex, Image, Input, Spinner, Text } from "@chakra-ui/react";
import { Point } from "tabler-icons-react";

const AssetItem = (props) => {
  const {
    folder,
    onAssetClick,
    position,
    isPositionOutside,
    status,
    setDeleteIds,
    setdeleteStatus,
    deleteIds,
    deleteSelection,
  } = props;
  const [cursorPosition, setCursorPosition] = useState(null);
  const {
    thumbnailBaseUrl,
    thumbnailFilename,
    thumbnailCount,
    singleThumbnailSize,
  } = folder;
  const [sectionWidth, setSectionWidth] = useState(320 / thumbnailCount);
  const [imageIndex, setImageIndex] = useState(0);
  const [thumbnailImage, setThumbnailImage] = useState(null);
  const [imageWidth, setImageWidth] = useState(null);
  // const [deleteSelection, setDeleteSelection] = useState(false);

  const loadImage = async () => {
    const KI = new window.Image();
    KI.src = `${thumbnailBaseUrl}${thumbnailFilename}`;
    KI.onload = () => {
      setImageWidth(KI?.naturalWidth);
    };
    return KI;
  };

  useEffect(() => {
    loadImage().then((img) => {
      setThumbnailImage(img);
    });
  }, [folder]);

  useEffect(() => {
    let x = 0;
    if (position) {
      x = position.x;
    }
    let url = "";
    if (!isPositionOutside && position) {
      setImageIndex(position ? Math.trunc(x / sectionWidth) : 0);
      setCursorPosition(position?.x);
    } else {
      setImageIndex(0);
      setCursorPosition(0);
    }
  }, [isPositionOutside, position]);

  const onAssetItemClick = () => {
    onAssetClick && onAssetClick(folder);
  };
  const isFilmstripThumbnail = React.useMemo(() => {
    return folder.thumbnailCount > 1;
  }, [folder.thumbnailCount]);

  const isVideoThumbnail = React.useMemo(() => {
    return (
      folder.mimeType === "video/mp4" || folder.mimeType === "video/quicktime"
    );
  }, [folder.mimeType]);

  const filterProjectAssetType = (type) => {
    if (type === "gltf-binary") {
      return "3D";
    } else if (type === "quicktime" || type === "mp4") {
      return "video";
    } else if (type === "png" || type === "jpeg" || type === "jpg") {
      return "image";
    } else {
      return type;
    }
  };

  // useEffect(() => {
  //   const handleCommandKeyDown = (e) => {
  //     if (e.keyCode === 91) {
  //       setDeleteSelection(true);
  //     }
  //   };

  //   const handleCommandKeyUp = (e) => {
  //     if (e.keyCode === 91) {
  //       setDeleteSelection(false);
  //     }
  //   };

  //   // Attach the event listener
  //   window.addEventListener("keydown", handleCommandKeyDown);
  //   window.addEventListener("keyup", handleCommandKeyUp);

  //   // Clean up the event listener on component unmount
  //   return () => {
  //     window.removeEventListener("keydown", handleCommandKeyDown);
  //     window.removeEventListener("keyup", handleCommandKeyUp);
  //   };
  // }, []);

  // const handleDeleteIds = (id) => {
  //   setdeleteStatus("");
  //   setDeleteIds((prev) =>
  //     deleteIds.includes(id) ? prev.filter((cur) => cur !== id) : [...prev, id]
  //   );
  // };

  return (
    <Box
      position="relative"
      key={folder._id}
      id={folder._id}
      // width={singleThumbnailSize?.width}
    >
      <Box
        // height={singleThumbnailSize?.height }
        // width={singleThumbnailSize?.width}
        minHeight="94px"
        w="164px"
        background="transparent"
        cursor="pointer"
        overflow="hidden"
        position={"relative"}
        borderRadius="4px"
        onClick={deleteSelection ? () => console.log("test") : onAssetItemClick}
        // borderRadius="8px 8px 0 0"
        // className={deleteIds.includes(folder._id) ? 'projectCardSelected' : ""}
        // border="0.4px solid #2D2D2D"
      >
        {cursorPosition ? (
          <Box
            height="100%"
            width="2px"
            backgroundColor="red"
            position="absolute"
            top={0}
            zIndex={100}
            left={`${cursorPosition}px`}
            onClick={
              deleteSelection ? () => console.log("test") : onAssetItemClick
            }
          ></Box>
        ) : null}
        {thumbnailImage && imageWidth ? (
          <Image
            // height={singleThumbnailSize?.height === 320 ? singleThumbnailSize?.height - 1 : singleThumbnailSize?.height}
            width={singleThumbnailSize?.width}
            objectFit={isFilmstripThumbnail ? "none" : "contain"}
            src={`${thumbnailBaseUrl}${thumbnailFilename}`}
            draggable={false}
            style={{
              overflow: "visible",
              position: isFilmstripThumbnail ? "absolute" : "initial",
              left: isPositionOutside
                ? `${imageWidth / 2 - 160}px`
                : `${imageWidth / 2 - 160 - imageIndex * 320}px`,
            }}
          />
        ) : (
          <Box
            // height={singleThumbnailSize?.height || "192px"}
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="#A2A2A2"
              size="xl"
            />
          </Box>
        )}
      </Box>
      <Flex className="projectCardContent">
        {/* <Flex
          justify="space-between"
          align="center"
          p="8px 8px 0px"
          // maxWidth="240px"
        > */}
        {/* <Text
            as="h4"
            className="mediaType"
            border="icborder.200"
            backgroundColor="icon.200"
            color="pr.300"
          >
            {filterProjectAssetType(folder?.mimeType?.split("/")[1])}
          </Text> */}
        <Text as="h4" color="newPr.900">
          V{folder.__v}
        </Text>
        <Text as="p" color="newPr.1000">
          Due: 23 Apr, 2023
        </Text>
        {/* </Flex> */}
        {/* <Flex className="projectCardTitle" onClick={onAssetItemClick}> */}
        <Text as="h2" color="newPr.1000">
          {folder.name || folder._id}
        </Text>
        {/* {status ? <Text as="p" className="status"></Text> : ""} */}
        {/* </Flex> */}
      </Flex>
      {/* <Flex
        justify="space-between"
        align="center"
        p="8px 8px 0px"
        // maxWidth="240px"
      >
        <Text
          as="span"
          className="mediaType"
          border="icborder.200"
          backgroundColor="icon.200"
          color="pr.300"
        >
          {filterProjectAssetType(folder?.mimeType?.split("/")[1])}
        </Text>
        <Text
          as="span"
          className="reviewVersion"
          border="icborder.200"
          backgroundColor="icon.200"
          color="pr.300"
        >
          v{folder.__v}
        </Text>
      </Flex>
      <Flex className="projectCardTitle" onClick={onAssetItemClick}>
        <Text as="h3" color="pr.300">
          {folder.name || folder._id}
        </Text>
        {status ? <Text as="p" className="status"></Text> : ""}
      </Flex> */}
    </Box>
    // </Box>
  );
};

export default AssetItem;
