import React, { useContext } from "react";
import { ChakraProvider, Container, Box } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { useStores } from "./store/rootStore";
import { darkTheme } from "./theme/darkTheme";
import { lightTheme } from "./theme/lightTheme";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ProjectRootContiner from "./pages/projectView/ProjectRootContainer.jsx";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Model3DContainer from "./pages/3dReview/index.jsx";
import Review from "./pages/Review";
import { MyContext } from "./context";
import VideoReviewContainer from "./pages/VideoReview/VideoReviewContainer";
import { ProjectContextProvider } from "./context/ProjectContext";
import { SelectedImageContextProvider } from "./context/SelectedImageContext";
import Projects from "./pages/Projects";
import { Selected3dModelContextProvider } from "./context/Selected3dModelContext";
import { SelectedVideoContextProvider } from "./context/SelectedVideoContext";
import LandingPage from "./pages/LandingPage";
import LottieFile from "./pages/LottieFile";
import LandingPageTest from "./pages/LandingPageTest";
import LandingPageTestTwo from "./pages/LandingPageTestTwo";
import LandingPageTestThree from "./pages/LandingPageTestThree";
import Login from "./pages/Login";
import { AuthProvider } from "./context/AuthContext";
import Protected from "./context/ProtectedRoutes";
import LoginSuccess from "./common/LoginSuccess";

import "./styles/common.scss";

const queryClient = new QueryClient();

//create routes using createRoutesFromElements
// const routes = createBrowserRouter(
//   createRoutesFromElements(
//     <Routes>
//       <Route
//         path="/"
//         element={
//           <Box backgroundColor="#3a3a3a" className="finll-root">
//             <LandingPage />
//           </Box>
//         }
//       />
//       <Route path="/login" element={<Login />} />
//       <Route path="/test" element={<LandingPageTest />} />
//       <Route path="/test-two" element={<LandingPageTestTwo />} />
//       <Route path="/test-three" element={<LandingPageTestThree />} />
//       <Route
//         path="/project-rootcontainer"
//         element={
//           <Protected>
//             <ProjectRootContiner />
//           </Protected>
//         }
//       />
//       <Route
//         path="/3dreview/:assetId"
//         element={
//           <Protected>
//             <Model3DContainer />
//           </Protected>
//         }
//       />
//       <Route
//         path="/videoReview/:videoId"
//         element={
//           <Protected>
//             <VideoReviewContainer />
//           </Protected>
//         }
//       />
//       <Route
//         path="/imageReview/:imageId"
//         element={
//           <Protected>
//             <Review />
//           </Protected>
//         }
//       />
//       <Route
//         path="/projects"
//         element={
//           <Protected>
//             <Projects />
//           </Protected>
//         }
//       />
//       <Route
//         path="/lottie"
//         element={
//           <Protected>
//             <LottieFile />
//           </Protected>
//         }
//       />
//     </Routes>
//   )
// );

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Box backgroundColor="#3a3a3a" className="finll-root">
        <LandingPage />
      </Box>
    ),
  },
  {
    path: "/test",
    element: (
      <Box backgroundColor="#3a3a3a" className="finll-root">
        <LandingPageTest />
      </Box>
    ),
  },
  {
    path: "/test-two",
    element: (
      <Box backgroundColor="#3a3a3a" className="finll-root">
        <LandingPageTestTwo />
      </Box>
    ),
  },
  {
    path: "/test-three",
    element: (
      <Box backgroundColor="#3a3a3a" className="finll-root">
        <LandingPageTestThree />
      </Box>
    ),
  },
  {
    path: "/project-rootcontainer",
    element: (
      <AuthProvider>
        <Protected>
          <Box backgroundColor="#3a3a3a" className="finll-root">
            <ProjectRootContiner />
          </Box>
        </Protected>
      </AuthProvider>
    ),
    // children: [
    //   {
    //     path: "/add_project",
    //     element: (
    //       <Box p={2} backgroundColor="#141414" className="finll-root">
    //         <AddProjectContainer />
    //       </Box>
    //     ),
    //   },
    // ],
  },
  {
    path: "3dreview/:assetId",
    element: (
      <AuthProvider>
        <Protected>
          <Box backgroundColor="#141414" className="finll-root">
            <Selected3dModelContextProvider>
              <Model3DContainer />
            </Selected3dModelContextProvider>
          </Box>
        </Protected>
      </AuthProvider>
    ),
  },
  {
    path: "videoReview/:videoId",
    element: (
      <AuthProvider>
        <Protected>
          <Box p={2} backgroundColor="pr.100" className="finll-root" >
            <SelectedVideoContextProvider>
              <VideoReviewContainer />
            </SelectedVideoContextProvider>
          </Box>
        </Protected>
      </AuthProvider>
    ),
  },
  {
    path: "imageReview/:imageId",
    element: (
      <AuthProvider>
        <Protected>
          <Box backgroundColor="#141414" className="finll-root">
            <SelectedImageContextProvider>
              <Review />
            </SelectedImageContextProvider>
          </Box>
        </Protected>
      </AuthProvider>
    ),
  },
  {
    path: "projects",
    element: (
      // <Box backgroundColor="#141414" className="finll-root">
      // <Protected>
      <AuthProvider>
        <Protected>
          <Projects />
        </Protected>
      </AuthProvider>

      // </Protected>
      // </Box>
    ),
  },
  {
    path: "lottie",
    element: (
      // <Box backgroundColor="#141414" className="finll-root">
      <AuthProvider>
        <LottieFile />
      </AuthProvider>
      // </Box>
    ),
  },
  {
    path: "login",
    element: (
      <AuthProvider>
        <Login />
      </AuthProvider>
    ),
  },
  {
    path: "success/:token",
    element: (
      <Box backgroundColor="#3a3a3a" className="finll-root">
        <AuthProvider>
          <LoginSuccess />
        </AuthProvider>
      </Box>
    ),
  },
]);

const App = observer(() => {
  const { theme } = useContext(MyContext);

  const root = useStores();

  return (
    <ChakraProvider theme={theme === "dark" ? darkTheme : lightTheme}>
      <QueryClientProvider client={queryClient}>
        <ProjectContextProvider>
          <RouterProvider router={router} />
        </ProjectContextProvider>
      </QueryClientProvider>
    </ChakraProvider>
  );
});

export default App;
