import React from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "./AuthContext";
function Protected({ children }) {
  const { isAuthenticated } = React.useContext(AuthContext);
  if (isAuthenticated === undefined) {
    return null;
  }
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }
  return children;
}
export default Protected;
