import React from "react";
import { Stage, Layer, Line, Circle, Text, Arrow } from "react-konva";
import URLimage from "./URLImage";
import { SelectedImageContext } from "../../context/SelectedImageContext";
import { NewAnnotationContext } from "../../context/NewAnnotationContext";

export function KonvaStage(props) {
  const stageRef = React.useRef(null);
  const {
    setIsMousePositionedOutside,
    allComments,
    selectedAnnotation,
    imageScale,
    selectedCommentIndex,
  } = React.useContext(SelectedImageContext);
  const AnnottationContext = React.useContext(NewAnnotationContext);

  const {
    lines,
    setLines,
    arrowAnnotation,
    setArrowAnnotation,
    markers,
    setMarkers,
    selectedColor,
  } = AnnottationContext;

  const {
    isPositionOutside,
    isDragging,
    selectedMode,
    toggleDrawing,
    setLatestArrow,
    setLatestLine,
    isDrawing,
    mode,
    latestLine,
    latestArrow,
  } = props;

  React.useEffect(() => {
    setIsMousePositionedOutside(isPositionOutside);
  }, [isPositionOutside, setIsMousePositionedOutside]);

  const getRelativePointerPosition = (node) => {
    const transform = node.getAbsoluteTransform().copy();
    transform.invert();
    const pos = node.getStage().getPointerPosition();
    return transform.point(pos);
  };

  const handleMouseDown = (event) => {
    if (isPositionOutside) {
      return;
    }
    if (isDragging) {
      event.target.getStage().container().style.cursor = "grab";
      return;
    }
    if (selectedMode === "default") {
      toggleDrawing(true);
      const point = getRelativePointerPosition(event.target.getStage());
      const { x, y } = point;
      const newMarker = {
        position: { x, y },
      };
      setMarkers([newMarker]);
    }
    if (selectedMode === "arrow") {
      toggleDrawing(true);
      const point = getRelativePointerPosition(event.target.getStage());
      const { x, y } = point;
      // const { offsetX, offsetY } = event.evt;
      const updatedArrow = {
        points: { x1: x, y1: y, x2: x, y2: y, color: selectedColor },
      };
      setLatestArrow(updatedArrow);
    }
    if (selectedMode === "pen") {
      toggleDrawing(true);
      const point = getRelativePointerPosition(event.target.getStage());
      const updatedLine = {
        points: [{ x: point.x, y: point.y }],
        color: selectedColor,
      };
      setLatestLine(updatedLine);
      // setLines([...lines, updatedLine]);
    }
  };

  const handleMouseMove = (event) => {
    if (isDragging) return;
    if (markers.length > 0) {
      event.target.getStage().container().style.cursor = "crosshair";
    }
    if (!isDrawing) {
      return;
    }
    if (mode === "pen") {
      const point = getRelativePointerPosition(event.target.getStage());
      // add point
      const updatedPoints = latestLine?.points
        ? [...latestLine.points, { x: point.x, y: point.y }]
        : [{ x: point.x, y: point.y }];

      // replace last
      const updatedLine = {
        points: updatedPoints,
        color: latestLine?.color,
      };
      setLatestLine(updatedLine);
    }
    if (mode === "arrow") {
      const { x, y } = getRelativePointerPosition(event.target.getStage());
      const updatedArrow = {
        points: {
          x1: latestArrow.points.x1,
          y1: latestArrow.points.y1,
          x2: x,
          y2: y,
          color: latestArrow.points.color,
        },
      };
      setLatestArrow(updatedArrow);
    }
  };

  const handleMouseUp = (event) => {
    if (isDragging) {
      event.target.getStage().container().style.cursor = "crosshair";
      return;
    }
    if (markers.length === 0) {
      event.target.getStage().container().style.cursor = "none";
      return;
    }
    if (markers.length > 0) {
      toggleDrawing(false);
      event.target.getStage().container().style.cursor = "crosshair";
    }
    if (mode === "arrow") {
      toggleDrawing(false);
      if (
        latestArrow.points.x1 === latestArrow.points.x2 &&
        latestArrow.points.y1 === latestArrow.points.y2
      ) {
        setArrowAnnotation([...arrowAnnotation]);
        setLatestArrow(null);
        return;
      } else {
        setArrowAnnotation([...arrowAnnotation, latestArrow]);
        setLatestArrow(null);
      }
      event.target.getStage().container().style.cursor = "crosshair";
    } else if (mode === "default") {
      event.target.getStage().container().style.cursor = "none";
    } else if (mode === "pen") {
      toggleDrawing(false);
      setLines([...lines, latestLine]);
      setLatestLine(null);
      event.target.getStage().container().style.cursor = "crosshair";
    }
  };

  const handleMouseEnter = (event) => {
    if (mode === "default") {
      event.target.getStage().container().style.cursor = "none";
    } else {
      event.target.getStage().container().style.cursor = "crosshair";
    }
  };

  const ArrowAnnotation = arrowAnnotation.map((arrow) => {
    return arrow.points;
  });
  const Markers = selectedAnnotation
    ? [...markers, { position: selectedAnnotation.marker }]
    : [...markers];
  const Arrows = selectedAnnotation
    ? [...ArrowAnnotation, ...selectedAnnotation.arrows.points]
    : [...ArrowAnnotation];
  const Lines = selectedAnnotation
    ? [...lines, ...selectedAnnotation.lines]
    : [...lines];
  const LinesToDraw = latestLine ? [...Lines, latestLine] : [...Lines];
  const ArrowsToDraw = latestArrow
    ? [...Arrows, latestArrow.points]
    : [...Arrows];
  if (!props.containerHeight || !props.containerWidth || !props.imageScale) {
    return null;
  }
  return (
    <Stage
      ref={stageRef}
      width={props.selectedAsset?.dimensions.width}
      height={props.selectedAsset?.dimensions.height}
      onMouseEnter={handleMouseEnter}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
    >
      <Layer imageSmoothingEnabled>
        <URLimage
          width={props.containerWidth}
          height={props.containerHeight}
          image={props.image}
          imageSrc={props.selectedAsset.url}
          selectedAsset={props.selectedAsset}
        />
      </Layer>
      <Layer>
        {LinesToDraw.map((line, i) => {
          let flattenedPoints = [];
          if (!line) {
            return null;
          }
          line.points.forEach((point) => {
            flattenedPoints.push(point.x);
            flattenedPoints.push(point.y);
          });
          return (
            <Line
              key={i}
              points={flattenedPoints}
              stroke={line?.color || "#df4b26"}
              strokeWidth={4 / imageScale > 8 ? 8 : 4 / imageScale}
              tension={0.5}
              lineCap="round"
              globalCompositeOperation={
                line.tool === "eraser" ? "destination-out" : "source-over"
              }
            />
          );
        })}
      </Layer>
      <Layer>
        {ArrowsToDraw?.map((arrow, i) => {
          return (
            <Arrow
              pointerLength={10 / imageScale}
              pointerWidth={15 / imageScale}
              key={i}
              points={[arrow.x1, arrow.y1, arrow.x2, arrow.y2]}
              stroke={arrow?.color || "#df4b26"}
              fill={arrow?.color || "#df4b26"}
              strokeWidth={4 / imageScale}
              lineCap="round"
              globalCompositeOperation={
                arrow.tool === "eraser" ? "destination-out" : "source-over"
              }
            />
          );
        })}
      </Layer>
      <Layer scaleX={1} scaleY={1}>
        {Markers.map((marker, i) => {
          if (!marker?.position) return null;
          const MarkerTextPostion = () => {
            if (selectedCommentIndex) {
              if (selectedCommentIndex < 9) {
                return marker?.position?.x - 5 / imageScale;
              } else {
                return marker?.position?.x - 9 / imageScale;
              }
            } else {
              if (allComments?.length + 1 < 9) {
                return marker?.position?.x - 5 / imageScale;
              } else {
                return marker?.position?.x - 9 / imageScale;
              }
            }
          };

          return (
            <>
              <Circle
                key={i}
                x={marker?.position?.x}
                y={marker?.position?.y}
                scaleX={1}
                scaleY={1}
                radius={20 / imageScale}
                fill={"#df4b26"}
                stroke={"white"}
                strokeWidth={2}
              />
              <Text
                shadowBlur={0}
                scaleX={1}
                scaleY={1}
                x={MarkerTextPostion()}
                y={marker?.position?.y - 7 / imageScale}
                text={
                  selectedAnnotation
                    ? selectedCommentIndex
                    : allComments?.length + 1 || 1
                }
                fontSize={16 / imageScale}
                fontFamily="soneregular, sans-serif"
                // stroke="white"
                // strokeWidth={2 / imageScale}
                fill="white"
                align="center"
              />
            </>
          );
        })}
      </Layer>
    </Stage>
  );
}
