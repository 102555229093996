import React, { useEffect, useState } from "react";
import { Box, Flex, Image, Input, Spinner, Text } from "@chakra-ui/react";
import { Point } from "tabler-icons-react";

import "./projectAssetCard.scss";

const ProjectAssetCard = (props) => {
  const {
    folder,
    onAssetClick,
    position,
    isPositionOutside,
    status,
    // slideCardContent,
  } = props;
  const [cursorPosition, setCursorPosition] = useState(null);
  const {
    thumbnailBaseUrl,
    thumbnailFilename,
    thumbnailCount,
    singleThumbnailSize,
  } = folder;
  const [sectionWidth, setSectionWidth] = useState(320 / thumbnailCount);
  const [imageIndex, setImageIndex] = useState(0);
  const [thumbnailImage, setThumbnailImage] = useState(null);
  const [imageWidth, setImageWidth] = useState(null);

  const loadImage = async () => {
    const KI = new window.Image();
    KI.src = `${thumbnailBaseUrl}${thumbnailFilename}`;
    KI.onload = () => {
      setImageWidth(KI?.naturalWidth);
    };
    return KI;
  };

  useEffect(() => {
    loadImage().then((img) => {
      setThumbnailImage(img);
    });
  }, []);

  useEffect(() => {
    let x = 0;
    if (position) {
      x = position.x;
    }
    let url = "";
    if (!isPositionOutside && position) {
      setImageIndex(position ? Math.trunc(x / sectionWidth) : 0);
      setCursorPosition(position?.x);
    } else {
      setImageIndex(0);
      setCursorPosition(0);
    }
  }, [isPositionOutside, position]);

  const onAssetItemClick = () => {
    onAssetClick && onAssetClick(folder);
  };
  const isFilmstripThumbnail = React.useMemo(() => {
    return folder.thumbnailCount > 1;
  }, [folder.thumbnailCount]);

  const isVideoThumbnail = React.useMemo(() => {
    return (
      folder.mimeType === "video/mp4" || folder.mimeType === "video/quicktime"
    );
  }, [folder.mimeType]);

  const filterProjectAssetType = (type) => {
    if (type === "gltf-binary") {
      return "3D";
    } else if (type === "quicktime" || type === "mp4") {
      return "video";
    } else if (type === "png" || type === "jpeg" || type === "jpg") {
      return "image";
    } else {
      return type;
    }
  };

  return (
    <Flex
      position="relative"
      key={folder._id}
      id={folder._id}
      gap="20px"
      h="100%"
      // width={"196px"}
      className="projectCardmask"
      backgroundColor="newPr.1100"
      // borderRadius="6px"
      p="8px"
    >
      <Box
        // height={singleThumbnailSize?.height}
        width="180px"
        h="180px"
        minHeight="100px"
        // background="transparent"
        cursor="pointer"
        overflow="hidden"
        position={"relative"}
        onClick={onAssetItemClick}
        // border="icborder.100"
        // borderRadius="4px"
        // backgroundColor="newPr.1100"
      >
        {cursorPosition ? (
          <Box
            height="100%"
            width="2px"
            backgroundColor="red"
            position="absolute"
            top={0}
            zIndex={100}
            left={`${cursorPosition}px`}
            onClick={onAssetItemClick}
          ></Box>
        ) : null}
        {thumbnailImage && imageWidth ? (
          <Image
            // height={singleThumbnailSize?.height}
            // width={singleThumbnailSize?.width}
            h="100%"
            objectFit={isFilmstripThumbnail ? "none" : "contain"}
            objectPosition="top"
            ml="auto"
            src={`${thumbnailBaseUrl}${thumbnailFilename}`}
            draggable={false}
            // borderRadius="4px"
            style={{
              overflow: "visible",
              position: isFilmstripThumbnail ? "absolute" : "initial",
              left: isPositionOutside
                ? `${imageWidth / 2 - 160}px`
                : `${imageWidth / 2 - 160 - imageIndex * 320}px`,
            }}
          />
        ) : (
          <Box
            height={singleThumbnailSize?.height}
            width={singleThumbnailSize?.width}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="#A2A2A2"
              size="xl"
            />
          </Box>
        )}
      </Box>
      <Flex
        className="projectAssetCardContent"
        // borderRadius="6px"
        // backgroundColor="newPr.1100"
        // right={slideCardContent ? "170px" : 0}
      >
        {/* <Flex
          justify="space-between"
          align="center"
          p="8px 8px 0px"
          // maxWidth="240px"
        > */}
        {/* <Text
            as="h4"
            className="mediaType"
            border="icborder.200"
            backgroundColor="icon.200"
            color="pr.300"
          >
            {filterProjectAssetType(folder?.mimeType?.split("/")[1])}
          </Text> */}
        <Text as="h4" color="newPr.300">
          V{folder.__v}
        </Text>
        <Text as="p" color="#585858">
          Due: 23 Apr, 2023
        </Text>
        {/* </Flex> */}
        {/* <Flex className="projectCardTitle" onClick={onAssetItemClick}> */}
        <Text as="h2" color="#9f9f9fcc">
          {folder.name || folder._id}
        </Text>
        {status ? <Text as="p" className="status"></Text> : ""}
        {/* </Flex> */}
      </Flex>
    </Flex>
    // </Box>
  );
};

export default ProjectAssetCard;
