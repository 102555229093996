import {
  Box,
  Flex,
  Text,
  Skeleton,
  SkeletonText,
  Button,
} from "@chakra-ui/react";
import React from "react";
import { Comment, Tick } from "../../components/Icons";
import { SelectedImageContext } from "../../context/SelectedImageContext";

const ActionPanel = ({ setOpenComment, openComment, zoom, setZoom }) => {
  const { resetImageScale } = React.useContext(SelectedImageContext);
  const handleOpenComments = () => {
    if (openComment === true) {
      setOpenComment(false);
    } else {
      setOpenComment(true);
    }
  };
  const resetScale = () => {
    resetImageScale();
  };

  return (
    <Flex
      backgroundColor="grey.500"
      p="12px 16px"
      justify="space-between"
      borderBottom="1px solid"
      align="center"
      borderColor="pr.600"
    >
      <Flex gap="12px">
        <Skeleton isLoaded>
          <Flex
            as="p"
            className="actionLabel"
            color="grey.800"
            _hover={{ backgroundColor: "#AFAFAF" }}
          >
            <Text as="span"></Text>
            In review
          </Flex>
        </Skeleton>
        <Skeleton isLoaded>
          <Flex
            className="actionLabel"
            as="p"
            color="grey.800"
            _hover={{ backgroundColor: "#AFAFAF" }}
          >
            No due date
          </Flex>
        </Skeleton>
      </Flex>
      <Flex gap="12px">
        <Button onClick={resetScale}>Reset scale</Button>
      </Flex>
      <Flex alignItems="center" gap="12px">
        <Skeleton isLoaded>
          <Box
            onClick={handleOpenComments}
            className="actionComments"
            _hover={{ backgroundColor: "#AFAFAF" }}
          >
            <Flex as="span" color="whitepr.100">
              2
            </Flex>
            <Comment w="15px" h="13px" color="grey.700" opacity="0.55" />
          </Box>
        </Skeleton>
        <Skeleton isLoaded>
          <Flex className="greenbtn" as="p" color="grey.800">
            Approve <Tick color="grey.800" />
          </Flex>
        </Skeleton>
      </Flex>
    </Flex>
  );
};

export default ActionPanel;
