import React from "react";
import { Image as KonvaImage } from "react-konva";

const URLImage = ({ image, selectedAsset }) => {
  return (
    <KonvaImage
      width={selectedAsset?.dimensions.width}
      height={selectedAsset?.dimensions.height}
      image={image}
    />
  );
};

export default URLImage;
