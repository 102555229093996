import { Box, Text } from "@chakra-ui/react";
import React from "react";

const Info = () => {
  return (
    <div className="commentsCardContainer">
      <div className="info">
        <Box className="infoContent" borderColor="sec.300">
          <Text as="h3" color="pr.800">File name</Text>
          <Text color="pr.800">60 seconds cut.mp4</Text>
        </Box>
        <Box className="infoContent" borderColor="sec.300">
          <Text as="h3" color="pr.800">Uploader</Text>
          <Text color="pr.800">Emery</Text>
        </Box>
        <Box className="infoContent" borderColor="sec.300">
          <Text as="h3" color="pr.800">Upload Date</Text>
          <Text color="pr.800">2 min ago</Text>
        </Box>
        <Box className="infoContent" borderColor="sec.300">
          <Text as="h3" color="pr.800">Codec</Text>
          <Text color="pr.800">AVC</Text>
        </Box>
        <Box className="infoContent" borderColor="sec.300">
          <Text as="h3" color="pr.800">RES</Text>
          <Text color="pr.800">1920 x 1080</Text>
        </Box>
        <Box className="infoContent" borderColor="sec.300">
          <Text as="h3" color="pr.800">FPS</Text>
          <Text color="pr.800">23.976</Text>
        </Box>
        <Box className="infoContent" borderColor="sec.300">
          <Text as="h3" color="pr.800">File name</Text>
          <Text color="pr.800">9bceb2db-4d73-468a-92f2- f18d7e0458fc</Text>
        </Box>
        <Box className="infoContent" borderColor="sec.300">
          <Text as="h3" color="pr.800">Uploader</Text>
          <Text color="pr.800">Emery</Text>
        </Box>
        <Box className="infoContent" borderColor="sec.300">
          <Text as="h3" color="pr.800">Upload Date</Text>
          <Text color="pr.800">2 min ago</Text>
        </Box>
        <Box className="infoContent" borderColor="sec.300">
          <Text as="h3" color="pr.800">Codec</Text>
          <Text color="pr.800">AVC</Text>
        </Box>
        <Box className="infoContent" borderColor="sec.300">
          <Text as="h3" color="pr.800">RES</Text>
          <Text color="pr.800">1920 x 1080</Text>
        </Box>
        <Box className="infoContent" borderColor="sec.300">
          <Text as="h3" color="pr.800">FPS</Text>
          <Text color="pr.800">23.976</Text>
        </Box>
        <Box className="infoContent" borderColor="sec.300">
          <Text as="h3" color="pr.800">File name</Text>
          <Text color="pr.800">60 seconds cut.mp4</Text>
        </Box>
        <Box className="infoContent" borderColor="sec.300">
          <Text as="h3" color="pr.800">Uploader</Text>
          <Text color="pr.800">Emery</Text>
        </Box>
        <Box className="infoContent" borderColor="sec.300">
          <Text as="h3" color="pr.800">Upload Date</Text>
          <Text color="pr.800">2 min ago</Text>
        </Box>
        <Box className="infoContent" borderColor="sec.300">
          <Text as="h3" color="pr.800">Codec</Text>
          <Text color="pr.800">AVC</Text>
        </Box>
        <Box className="infoContent" borderColor="sec.300">
          <Text as="h3" color="pr.800">RES</Text>
          <Text color="pr.800">1920 x 1080</Text>
        </Box>
        <Box className="infoContent" borderColor="sec.300">
          <Text as="h3" color="pr.800">FPS</Text>
          <Text color="pr.800">23.976</Text>
        </Box>
      </div>
    </div>
  );
};

export default Info;
