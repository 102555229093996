import React from "react";

import "./popup.scss";
import { CompareIcon } from "../Icons";
import { Box, Button, Text } from "@chakra-ui/react";

const VersionPopup = ({ setOpenVersionFilter }) => {
  return (
    <div className="versionPopup">
      <Box className="versionPopupCard" backgroundColor="sec.300">
        <div className="versionPopupContent">
          <div>
            <Text as="h4" color="pr.800">Version</Text>
          </div>
          <div>
            <Text as="h4" color="pr.800">Review step 1</Text>
          </div>
          <div>
            <Text as="h4" color="pr.800">Review step 2</Text>
          </div>
        </div>
        <div className="versionPopupContent">
          <div>
            <Text _hover={{backgroundColor:"prhover.200"}} color="pr.800">V1</Text>
          </div>
          <div className="versionPopupStatus view">
            <label>Viewing</label>
            <span>23 Apr, 2023</span>
          </div>
          <div className="versionPopupStatus open">
            <label>Open</label>
            <span>23 Apr, 2023</span>
          </div>
        </div>
        <div className="versionPopupContent">
          <div>
            <Text _hover={{backgroundColor:"prhover.200"}} color="pr.800">V2</Text>
          </div>
          <div className="versionPopupStatus view">
            <label>Viewing</label>
            <span>23 Apr, 2023</span>
          </div>
          <div className="versionPopupStatus open">
            <label>Open</label>
            <span>23 Apr, 2023</span>
          </div>
        </div>
        <div className="versionPopupContent">
          <div>
            <Text _hover={{backgroundColor:"prhover.200"}} color="pr.800">V3</Text>
          </div>
          <div className="versionPopupStatus view">
            <label>Viewing</label>
            <span>23 Apr, 2023</span>
          </div>
          <div className="versionPopupStatus open">
            <label>Open</label>
            <span>23 Apr, 2023</span>
          </div>
        </div>
        <div className="versionPopupCompare">
          <Button
            color="pr.800"
            onClick={() =>
              setTimeout(() => {
                setOpenVersionFilter(false);
              }, 50)
            }
          >
            Compare
            <CompareIcon />
          </Button>
        </div>
      </Box>
    </div>
  );
};

export default VersionPopup;
