import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { DownloadIcon, Plus, TickMarkGreen } from "../Icons";
import ProjectCard from "../ProjectCard";

import "./batchreview.scss";

const BatchReview = ({ setBatchView }) => {
  return (
    <Box className="batchReview">
      <Flex className="batchHead">
        <Text as="h3">Batch review</Text>
        <Text className="assetIcon" onClick={() => setBatchView(false)}>
          {" "}
          x
        </Text>
      </Flex>
      <Box h="calc(100vh - 116px)" p="16px 12px">
        <Flex className="projectGrid">
          <ProjectCard check={true} />
          <ProjectCard check={true} />
          <ProjectCard check={true} />
          <ProjectCard check={true} />
          <ProjectCard check={true} />
          <ProjectCard check={true} />
          <ProjectCard check={true} />
          <ProjectCard check={true} />
          <ProjectCard check={true} />
          <ProjectCard check={true} />
        </Flex>
      </Box>
      <Flex className="batchFooter">
        <Flex className="batchFooterOptions">
          <Text as="h3">2 files selected</Text>
          <Text as="p">
            Select all <Plus />
          </Text>
          <Text className="assetIcon">
            <DownloadIcon />
          </Text>
        </Flex>
        <Flex className="batchFooterApprove" as="h4">
          Approve assets <TickMarkGreen />
        </Flex>
      </Flex>
    </Box>
  );
};

export default BatchReview;
