import React, { useEffect, useRef, useState } from "react";
import { Box, Flex, Input, Textarea } from "@chakra-ui/react";
import { Selected3dModelContext } from "../../context/Selected3dModelContext";
import { Tick } from "../../components/Icons";
import { useDrag } from "../../customHook/useDrag";

const ColorsArray = [
  { color: "red", code: "#FF0000" },
  { color: "orange", code: "#FF570E" },
  { color: "yellow", code: "#FFFF00" },
  { color: "green", code: "#1ABCA1" },
  { color: "blue", code: "#00BFFF" },
];

const ColorItem = ({ color, selectedColor, setSelectedColor }) => {
  const onColorClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedColor(color);
  };
  return (
    <Box
      onClick={onColorClick}
      as="span"
      backgroundColor={color.code}
      height={selectedColor.color === color.color ? "20px" : "14px"}
      width={selectedColor.color === color.color ? "20px" : "14px"}
    ></Box>
  );
};

const MarkupColors = ({ selectedColor, setSelectedColor }) => (
  <Flex
    flexGrow={1}
    className="markupToolColor"
    as="p"
    color="pr.400"
    backgroundColor="commentBg.100"
  >
    Color
    {ColorsArray.map((color) => {
      return (
        <ColorItem
          key={color.color}
          color={color}
          selectedColor={selectedColor}
          setSelectedColor={setSelectedColor}
        />
      );
    })}
  </Flex>
);

const Model3dReviewMarkup = ({ selectedMode, setSelectedMode }) => {
  const {
    newComment,
    setNewComment,
    newAnnotation,
    setNewAnnotation,
    selectedColor,
    setSelectedColor,
    addAnnotation,
  } = React.useContext(Selected3dModelContext);
  const onNewCommentChange = (e) => {
    setNewComment(e.target.value);
  };

  const draggableRef = useRef(null);

  const { position, handleMouseDown, isDragging } = useDrag({
    ref: draggableRef,
  });

  const [draggable, setDraggable] = useState(false);

  const onModeChange = (mode) => {
    setSelectedMode(mode);
  };

  const onCommentSubmit = () => {
    addAnnotation();
    setNewComment("");
  };

  useEffect(() => {
    if (isDragging) {
      setDraggable(true);
    }
  }, [isDragging, position]);

  const adjustTextareaHeight = (element) => {
    element.style.height = "auto";
    element.style.height = element.scrollHeight + "px";
  };

  return (
    <Box
      position={draggable ? "fixed" : "absolute"}
      top="78%"
      w="354px"
      left="50%"
      ref={draggableRef}
      zIndex={2}
      transform={draggable ? "translateX(0%)" : "translateX(-50%)"}
      style={{
        top: position.y,
        left: position.x,
      }}
    >
      <Flex alignItems="center" gap="4px" justify="space-around">
        <Flex className="markupToolText" backgroundColor="commentBg.100">
          <Box
            as="p"
            color="pr.400"
            cursor={isDragging ? "grabbing" : "grab"}
            onMouseDown={handleMouseDown}
          >
            Markup
          </Box>
          {/* <Flex alignItems="center" gap="8px">
            {MODESArray.map((mode) => {
              return (
                <ModeIcons
                  key={mode}
                  mode={mode}
                  selectedMode={selectedMode}
                  setSelectedMode={setSelectedMode}
                  onModeChange={onModeChange}
                />
              );
            })}
          </Flex> */}
        </Flex>
        <MarkupColors
          selectedColor={selectedColor}
          setSelectedColor={setSelectedColor}
        />
      </Flex>
      <Flex className="markupInput" backgroundColor="pr.100">
        <Textarea
          rows={1}
          type="text"
          placeholder="Add a comment"
          onChange={onNewCommentChange}
          value={newComment}
        />
        <Tick onClick={onCommentSubmit} />
      </Flex>
    </Box>
  );
};

export default Model3dReviewMarkup;
