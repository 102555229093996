import React from "react";
import { Box, Button, Input, Spinner } from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import axios from "axios";
import { ProjectContext } from "../../context/ProjectContext";
import useFinalAxiosWrapper from "../../utils/FinalApi";
import { FILE_UPLOAD_API } from "../../constants/url";

const ImageUploadContainer = ({ onClose, fetchProjects, fetchAssets }) => {
  const { post } = useFinalAxiosWrapper();
  const { selectedProject } = React.useContext(ProjectContext);
  const [uploadedImage, setUploadedImage] = React.useState(null);
  const uploadedImageRef = React.useRef(null);
  const [imageName, setImageName] = React.useState("");
  const [imageApiLoading, setImageApiLoading] = React.useState(false);

  const onImageNameChange = (e) => {
    setImageName(e.target.value);
  };

  const onCloseClick = () => {
    onClose && onClose();
  };

  const onSubmitClick = async () => {
    const bodyFormData = new FormData();
    bodyFormData.append("file", uploadedImage);
    bodyFormData.append("projectId", selectedProject._id);
    bodyFormData.append("name", imageName);
    bodyFormData.append("createdBy", "cc314db7-1d2b-4199-83f4-12622b044d20");
    setImageApiLoading(true);

    await post(FILE_UPLOAD_API, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      // .then((res) => {
      //   axios.post("https://api.finalfinal.one/api/asset/create", {
      //     projectId: selectedProject._id,
      //     url: res.data.url,
      //     createdBy: "8da9da6b-5bcc-424c-b677-338c8e21942f",
      //     name: imageName,
      //     thumbnail: res.data.thumbnail,
      //   });
      // })
      .then(() => {
        fetchProjects().then(() => {
          fetchAssets();
          onCloseClick();
          setImageApiLoading(false);
        });
      });
  };

  const handleUpload = (e) => {
    e.preventDefault();
    setUploadedImage(e.target.files[0]);
  };

  return (
    <Modal isCentered isOpen={true} onClose={onCloseClick}>
      <ModalOverlay
        bg="blackAlpha.300"
        backdropFilter="blur(10px) hue-rotate(90deg)"
      />
      <ModalContent
        background={"#141414"}
        color="#949598"
        className="green-medium"
        fontFamily={"GreedMedium"}
        borderRadius={"8px"}
        width={"40rem"}
      >
        <ModalHeader
          backgroundColor={"#949598"}
          height="200px"
          fontSize={"36px"}
          color="#141414"
          borderTopLeftRadius={"8px"}
          borderTopRightRadius={"8px"}
          fontWeight={600}
        >
          Upload Asset
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {!imageApiLoading ? (
            <>
              <Box p={4}>
                <Input
                  height="60px"
                  ref={uploadedImageRef}
                  type="file"
                  onChange={handleUpload}
                  border={"1px solid #949598"}
                  padding={4}
                />
              </Box>
              <Box p={4}>
                <Box margin="12px 0px" fontSize={"24px"} color="#949598">
                  File name
                </Box>
                <Input
                  height="60px"
                  type="text"
                  value={imageName}
                  onChange={onImageNameChange}
                  border={"1px solid #949598"}
                  padding={4}
                />
              </Box>
              <Box p={4}>
                <Box margin="12px 0px" fontSize={"24px"} color="#949598">
                  Project
                </Box>
                <Input
                  height="60px"
                  type="text"
                  disabled
                  value={selectedProject.name}
                  onChange={handleUpload}
                  border={"1px solid #949598"}
                  padding={4}
                />
              </Box>
            </>
          ) : (
            <Box
              height="300px"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Spinner color="#be4916" size="xl" />
            </Box>
          )}
        </ModalBody>

        <ModalFooter>
          <Button
            background="#be4916"
            color="white"
            mr={3}
            onClick={onSubmitClick}
          >
            Submit
          </Button>
          <Button variant="ghost" onClick={onCloseClick}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ImageUploadContainer;
