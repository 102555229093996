import React from "react";

import { useLottie } from "lottie-react";
import FinalAnimation1 from "../../assets/gifs/FF_logo_big_B_v01.json";
import FinalAnimation2 from "../../assets/gifs/FF_logo_big_A_v02.json";
import { Flex } from "@chakra-ui/react";


const LottieFile = () => {

    const options1 = {
        animationData: FinalAnimation1,
        loop: true
      };

      const options2 = {
        animationData: FinalAnimation2,
        loop: true
      };
    
      const { View } = useLottie(options1);
      const { View2 } = useLottie(options2);


  return (
   <>
        <Flex align="center" justify="center" w="100%" h="100vh" backgroundColor="#000">
            <div style={{width:"200px"}}>
                {View}
            </div>
            <div style={{width:"200px"}}>
                {View2}
            </div>
        </Flex>
   </>
  );
};

export default LottieFile;
