import { Box, Flex, Image, Text } from "@chakra-ui/react";
import ReactCursorPosition from "react-cursor-position";
import { ProjectContext } from "../../context/ProjectContext";
import React, {
  useContext,
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
} from "react";
import ProjectCard from "../ProjectCard";
import AssetItem from "../../pages/projectView/AssetItem";
import useFinalAxiosWrapper from "../../utils/FinalApi";

import { AddIcon, AddPlusIcon } from "../Icons";
import ImageUploadContainer from "../ImageUpload/ImageUploadContainer";
import {
  GET_ASSETS_BY_PROJECT_API,
  GET_PROJECTS_BY_OWNER_API,
} from "../../constants/url";
import "./projectGridView.scss";
import { MyContext } from "../../context";
import { useGSAP } from "@gsap/react";

const ProjectGridView = ({
  assets,
  onAssetClick,
  reviewGrid,
  showImageUpload,
  setShowImageUpload,
  deleteIds,
  setDeleteIds,
  setdeleteStatus,
  deleteSelection,
  setDeleteSelection,
}) => {
  const gridAssetsContainer = useRef();
  const { selectedProject, setSelectedProject } = useContext(ProjectContext);

  const { post } = useFinalAxiosWrapper();

  const [projectAssets, setProjectAssets] = useState([]);
  const [projects, setProjects] = useState([]);

  // const [deleteSelection, setDeleteSelection] = useState(false);

  const { staggeredCards } = useContext(MyContext);

  const fetchProjects = async () => {
    await post(GET_PROJECTS_BY_OWNER_API, {
      ownerId: "cc314db7-1d2b-4199-83f4-12622b044d20",
    })
      .then((res) => {
        setProjects(res.data.projects);
        if (res.data.projects.length > 0) {
          setSelectedProject(selectedProject || res.data.projects[0]);
        }
      })
      .catch((err) => {
        console.log("Error: ", err);
      });
  };

  const fetchAssets = async () => {
    if (selectedProject) {
      await post(GET_ASSETS_BY_PROJECT_API, {
        projectId: selectedProject._id,
      }).then((res) => {
        setProjectAssets(res.data.assets);
      });
    }
  };

  useGSAP(
    () => {
      if (assets) {
        staggeredCards(".projectCard");
      }
    },
    [assets],
    gridAssetsContainer
  );

  const onAddNewFileClick = () => {
    setShowImageUpload(true);
  };

  const onImageUploadClose = () => {
    setShowImageUpload(false);
  };

  const onProjectClick = (id) => {
    const selectedProject = projects.find((project) => project._id === id);
    setSelectedProject(selectedProject);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const projectCards = document.querySelectorAll(".projectCard");
      const clickedInsideProjectCard = Array.from(projectCards).some((card) =>
        card.contains(event.target)
      );

      if (!clickedInsideProjectCard) {
        setDeleteIds([]);
      }
    };

    // Add the event listener when the component mounts
    document.addEventListener("click", handleClickOutside);

    return () => {
      // Remove the event listener when the component unmounts
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleCommandKeyDown = (e) => {
      if (e.keyCode === 91) {
        setDeleteSelection(true);
      }
    };

    const handleCommandKeyUp = (e) => {
      if (e.keyCode === 91) {
        setDeleteSelection(false);
      }
    };

    // Attach the event listener
    window.addEventListener("keydown", handleCommandKeyDown);
    window.addEventListener("keyup", handleCommandKeyUp);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("keydown", handleCommandKeyDown);
      window.removeEventListener("keyup", handleCommandKeyUp);
    };
  }, []);

  const handleDeleteIds = (id) => {
    setdeleteStatus("");
    setDeleteIds((prev) =>
      deleteIds.includes(id) ? prev.filter((cur) => cur !== id) : [...prev, id]
    );
  };

  return (
    <>
      <Flex
        className="projectGrid"
        h={reviewGrid ? "calc(100dvh - 145px)" : ""}
        ref={gridAssetsContainer}
      >
        {showImageUpload ? (
          <Box>
            <ImageUploadContainer
              onClose={onImageUploadClose}
              fetchAssets={fetchAssets}
              fetchProjects={fetchProjects}
            />
          </Box>
        ) : null}

        {assets.map((folder, index) => {
          const { thumbnailCount } = folder;
          if (thumbnailCount > 1) {
            return (
              <Box
                className={
                  deleteIds.includes(folder._id)
                    ? "projectCardSelected projectCard"
                    : "projectCard"
                }
                onClick={
                  deleteSelection
                    ? () => handleDeleteIds(folder._id)
                    : () => console.log("test")
                }
                backgroundColor="newPr.1100"
              >
                <ReactCursorPosition key={folder._id}>
                  {/* <Box className="projectCardCheckbox">
                    <input
                      type="checkbox"
                      name={folder._id}
                      id={folder._id}
                      value={folder._id}
                      checked={deleteIds.includes(folder._id)}
                      onChange={() => handleDeleteIds(folder?._id)}
                    />
                    <label htmlFor={folder?._id}></label>
                  </Box> */}
                  <AssetItem
                    deleteIds={deleteIds}
                    setDeleteIds={setDeleteIds}
                    setdeleteStatus={setdeleteStatus}
                    index={index}
                    folder={folder}
                    onAssetClick={onAssetClick}
                    status={true}
                    deleteSelection={deleteSelection}
                  />
                </ReactCursorPosition>
              </Box>
            );
          } else {
            return (
              <Box
                className={
                  deleteIds.includes(folder._id)
                    ? "projectCardSelected projectCard"
                    : "projectCard"
                }
                onClick={
                  deleteSelection
                    ? () => handleDeleteIds(folder._id)
                    : () => console.log("test")
                }
                backgroundColor="newPr.1100"
              >
                {/* <Box className="projectCardCheckbox">
                  <input
                    type="checkbox"
                    name={folder._id}
                    id={folder._id}
                    value={folder._id}
                    checked={deleteIds.includes(folder._id)}
                    onChange={() => handleDeleteIds(folder?._id)}
                  />
                  <label htmlFor={folder?._id}></label>
                </Box> */}
                <AssetItem
                  deleteIds={deleteIds}
                  setDeleteIds={setDeleteIds}
                  setdeleteStatus={setdeleteStatus}
                  key={folder._id}
                  index={index}
                  folder={folder}
                  onAssetClick={onAssetClick}
                  status={true}
                  deleteSelection={deleteSelection}
                />
              </Box>
            );
          }
        })}
        <Box className="projectCard"></Box>
        <Box className="projectCard"></Box>
        <Box className="projectCard"></Box>
        <Box className="projectCard"></Box>
        {/* <ProjectCard status={true} />
        <ProjectCard status={true} />
        <ProjectCard status={true} />
        <ProjectCard status={true} />
        <ProjectCard status={true} />
        <ProjectCard status={true} />
        <ProjectCard status={true} />
        <ProjectCard status={true} />
        <ProjectCard status={true} /> */}
        {/* <ProjectCard status={true} /> */}
      </Flex>
    </>
  );
};

export default ProjectGridView;
