import React from "react";
import { Box } from "@chakra-ui/react";
import { useState } from "react";
import Comments from "../../components/Comments/Comments";
import axios from "axios";
import { v1 as uuid } from "uuid";
import Model3dReviewMarkup from "./Model3dReviewMarkup";
import Model3dReviewAsset from "./Model3dReviewAsset";
import Model3dActionPanel from "./Model3dActionPanel";
import { Selected3dModelContext } from "../../context/Selected3dModelContext";

import "./review.scss";
import ReviewHeader from "../Review/ReviewHeader";


const MODES = Object.freeze({
  RECTANGLE: "rectangle",
  DRAG: "drag",
  ARROW: "arrow",
  LINE: "line",
  PEN: "pen",
  DEFAULT: "default",
});



const Image3dReview = () => {
  const [openMarkup, setOpenMarkup] = useState(false);
  const [openComment, setOpenComment] = useState(true);
  const [selectedId, selectAnnotation] = useState(null);
  const [projectsView, setProjectsView] = useState("list");
  const [assetDetails, setAssetDetails] = useState(null);
  const [selectedMode, setSelectedMode] = useState(MODES.DEFAULT);

  return (
    <>
      <Box background="pr.100">
        <ReviewHeader
          projectsView={projectsView}
          title={assetDetails?.name || assetDetails?._id}
        />
        {/* <Model3dActionPanel
          setOpenComment={setOpenComment}
          openComment={openComment}
        /> */}
        <Model3dReviewAsset
          setOpenMarkup={setOpenMarkup}
          openMarkup={openMarkup}
          openComment={openComment}
          setOpenComment={setOpenComment}
          selectAnnotation={selectAnnotation}
          selectedAnnotation={selectedId}
          projectsView={projectsView}
          setProjectsView={setProjectsView}
          setSelectedMode={setSelectedMode}
        />

        {openMarkup ? <Model3dReviewMarkup /> : ""}
      </Box>
    </>
  );
};

export default Image3dReview;
