import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Text,
  Flex,
  Input,
  FormLabel,
  UnorderedList,
  ListItem,
  Image,
} from "@chakra-ui/react";
import { DownArrow, OptionsIcon, ReplyIcon, Send } from "../Icons";
import moment from "moment";
import { Selected3dModelContext } from "../../context/Selected3dModelContext";
import { NewAnnotationContext } from "../../context/NewAnnotationContext";
import CommentFilter from "../Popups/CommentFilter";
import { MyContext } from "../../context";
import DeleteComment from "../Popups/DeleteComment";
import NoComments from "../../assets/images/review/comments.png";
import Info from "./Info";
const Model3dComments = ({
  openComment,
  comments,
  setOpenMarkup,
  setSelectedMode,
  selectAnnotation,
  selectedAnnotation,
}) => {
  const {
    allComments,
    setSelectedComment,
    setSelectedCommentIndex,
    replyComment,
    setReplyComment,
    selectedComment,
    addReply,
  } = React.useContext(Selected3dModelContext);

  // const { setNewComment } = useContext(NewAnnotationContext);
  const { theme } = useContext(MyContext);

  const [switchCommentTabs, setSwitchCommentTabs] = useState(true);
  const [commentsFilter, setCommentsFilter] = useState(false);
  const [commentsOptions, setCommentsOptions] = useState(null);
  const [deleteComment, setDeleteComment] = useState(null);

  useEffect(() => {
    window.addEventListener("keydown", (eve) => {
      if (eve.keyCode === 27) {
        if (commentsFilter === true) {
          setCommentsFilter(false);
        }

        if (commentsOptions !== null) {
          setCommentsOptions(null);
        }

        if (deleteComment !== null) {
          setDeleteComment(null);
        }
      }
    });
  }, [commentsFilter, commentsOptions, deleteComment]);

  const resetNewAnnotation = () => {
    // setLines([]);
    // setMarkers([]);
    // setArrowAnnotation([]);
    // setNewComment("");
    setOpenMarkup(false);
    setSelectedMode("default");
  };

  const closeCommentOption = () => {
    setTimeout(() => {
      setCommentsOptions(null);
    }, 20);
  };

  const onReplyInputChange = (e) => {
    setReplyComment(e.target.value);
  };

  return (
    <Box
      flex="1 0 auto"
      className={openComment ? "comments commentsActive" : "comments"}
      backgroundColor="pr.100"
      backdropFilter="blur(25px)"
      position="relative"
    >
      {openComment ? (
        <>
          {switchCommentTabs ? (
            <Box className="genComment">
              <Text color="pr.300">
                This is a general comment for this image. It will always remain
                here as this image is very important
              </Text>
            </Box>
          ) : (
            ""
          )}
          <Flex gap="4" className="commentsTab" backgroundColor="pr.100">
            <Text
              className={
                switchCommentTabs ? "commentsTitle" : "commentsTitle inActive"
              }
              as="h2"
              color="sec.100"
              onClick={() => setSwitchCommentTabs(true)}
            >
              Comments
            </Text>
            <Text
              className={
                switchCommentTabs ? "commentsTitle inActive" : "commentsTitle"
              }
              as="h2"
              color="sec.100"
              onClick={() => setSwitchCommentTabs(false)}
            >
              File info
            </Text>
          </Flex>
          <Box h="100%">
            {switchCommentTabs ? (
              allComments?.length ? (
                <>
                  <Flex
                    className="commentsFilter"
                    backgroundColor="pr.100"
                    color="pr.800"
                  >
                    <Flex position="relative">
                      <Flex
                        as="p"
                        onClick={() => setCommentsFilter(!commentsFilter)}
                      >
                        Filter & sort
                        <DownArrow w="9px" h="5px" color="#f2f4f1" />
                      </Flex>
                      {commentsFilter ? (
                        <CommentFilter setCommentsFilter={setCommentsFilter} />
                      ) : (
                        ""
                      )}
                    </Flex>

                    <Flex
                      as="p"
                      // backgroundColor="pr.600"
                    >
                      <label for="switch">Hide markers</label>
                      <input type="checkbox" id="switch" />
                      <FormLabel
                        borderColor="pr.800"
                        className={
                          theme === "dark"
                            ? "switchLable"
                            : "switchLable lightSwitch"
                        }
                        for="switch"
                      ></FormLabel>
                    </Flex>
                  </Flex>
                  <Flex className="commentsCardContainer">
                    {allComments.map((comment, index) => {
                      const onCommentSelect = () => {
                        setSelectedComment(comment);
                        setSelectedCommentIndex(allComments?.length - index);
                        resetNewAnnotation();
                      };
                      return (
                        <Flex
                          key={comment._id}
                          className="commentsCard"
                          // backgroundColor={
                          //   selectedComment?._id === comment._id
                          //     ? "pr.600"
                          //     : "pr.300"
                          // }
                          borderColor={
                            selectedComment?._id === comment._id
                              ? "#ff570e"
                              : "pr.600"
                          }
                          _hover={{ backgroundColor: "pr.600" }}
                          onClick={onCommentSelect}
                        >
                          <Flex alignItems="center" justify="space-between">
                            <Flex alignItems="center">
                              <Text className="commentsNumber">
                                {comment?.counter < 10
                                  ? `0${comment?.counter}`
                                  : comment?.counter}
                              </Text>
                              <Text color="pr.300">&nbsp;—&nbsp;</Text>
                              <Box pl="4px">
                                <Text
                                  className="commentsCardUser"
                                  color="sec.100"
                                >
                                  {comment?.createdBy?.firstName}{" "}
                                  {comment?.createdBy?.lastName}
                                </Text>
                                <Text
                                  className="commentsTimeStamp"
                                  color="sec.100"
                                >
                                  {moment(
                                    comment?.createdAt,
                                    "YYYYMMDD"
                                  ).fromNow()}
                                </Text>
                              </Box>
                            </Flex>
                            <Flex className="commentsOptions">
                              <Box>
                                <input type="checkbox" id={"tick" + index} />
                                <FormLabel
                                  borderColor="pr.800"
                                  className={
                                    theme === "dark" ? "" : "lightTick"
                                  }
                                  for={"tick" + index}
                                ></FormLabel>
                              </Box>
                              <Box position="relative">
                                <Box
                                  onClick={() => {
                                    if (commentsOptions === index) {
                                      setCommentsOptions(null);
                                    } else {
                                      setCommentsOptions(index);
                                    }
                                  }}
                                >
                                  <OptionsIcon color="pr.800" />
                                </Box>
                                {index === commentsOptions ? (
                                  <Box
                                    className="commentsOptionsPopup"
                                    backgroundColor="pr.100"
                                  >
                                    <UnorderedList>
                                      <ListItem
                                        color="pr.800"
                                        _hover={{
                                          backgroundColor: "prhover.200",
                                        }}
                                        onClick={closeCommentOption}
                                      >
                                        Edit
                                      </ListItem>
                                      <ListItem
                                        color="pr.800"
                                        _hover={{
                                          backgroundColor: "prhover.200",
                                        }}
                                        onClick={() =>
                                          setTimeout(() => {
                                            setDeleteComment(index);
                                            setCommentsOptions(null);
                                          }, 20)
                                        }
                                      >
                                        Delete
                                      </ListItem>
                                    </UnorderedList>
                                  </Box>
                                ) : (
                                  ""
                                )}
                              </Box>
                            </Flex>
                          </Flex>
                          <Text className="commentsDescription" color="sec.200">
                            {comment?.comment}
                          </Text>

                          {comment?.replies?.map((thread) => {
                            return (
                              <Flex flexDirection="column" p={4}>
                                <Flex flexDirection="column">
                                  <Flex align="center" pb="8px">
                                    <ReplyIcon />
                                    <Box className="commentsCardReplyUser">
                                      <Text color="sec.100">
                                        {thread?.createdBy?.firstName}{" "}
                                        {thread?.createdBy?.lastName}
                                      </Text>
                                      <Text as="span" color="sec.100">
                                        2 hours ago
                                      </Text>
                                    </Box>
                                  </Flex>
                                  <Text
                                    className="commentsReplyDescription"
                                    color="sec.200"
                                  >
                                    {thread?.comment}
                                  </Text>
                                </Flex>
                              </Flex>
                            );
                          })}

                          {deleteComment === index ? (
                            <DeleteComment
                              setDeleteComment={setDeleteComment}
                            />
                          ) : (
                            ""
                          )}

                          {selectedComment?._id === comment?._id ? (
                            <Flex
                              className="commentsInputContainer"
                              backgroundColor="sec.500"
                            >
                              <Input
                                type="text"
                                value={replyComment}
                                placeholder="Enter new reply"
                                color="pr.500"
                                _placeholder={{ color: "sec.400" }}
                                onChange={(e) =>
                                  setReplyComment(e.target.value)
                                }
                              />
                              <Send
                                w="9px"
                                h="8px"
                                color="pr.400"
                                cursor="pointer"
                                onClick={() => {
                                  if (replyComment) {
                                    addReply();
                                  }
                                }}
                              />
                            </Flex>
                          ) : (
                            <Flex
                              className="commentsReply"
                              as="p"
                              // backgroundColor="pr.600"
                              color="sec.100"
                              _hover={{ backgroundColor: "pr.100" }}
                              onClick={() => {
                                setSelectedComment(comment);
                              }}
                            >
                              Reply &nbsp;&nbsp; ↖
                              {/* <Send w="9px" h="8px" color="sec.100" /> */}
                            </Flex>
                          )}
                        </Flex>
                      );
                    })}
                  </Flex>
                </>
              ) : (
                <Flex className="noComments">
                  <Image src={NoComments} alt="no comments" />
                  <Text as="p" color="pr.300">
                    No comments yet. Click on the image to add your comment
                  </Text>
                </Flex>
              )
            ) : (
              <Info />
            )}
          </Box>
        </>
      ) : (
        ""
      )}
    </Box>
  );
};

export default Model3dComments;
