import React, { useContext, useEffect, useRef } from "react";
import { SearchIcon } from "@chakra-ui/icons";
import { Box, Flex, Image, Input, Text, Button } from "@chakra-ui/react";
import {
  Bell,
  FinlIcon,
  GridIconNew,
  ListIconNew,
  ThemeIcon,
} from "../../components/Icons";
import lottie from "lottie-web";
import { ToastContainer, toast } from "react-toastify";

import Logo from "../../assets/images/finlLogo1.svg";
import Profile from "../../assets/images/projects/profile.svg";
import FinalAnimation from "../../assets/gifs/FF_logo_big_B_v02.json";
import { useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { MyContext } from "../../context";
import "react-toastify/dist/ReactToastify.css";

const ProjectHeader = ({
  playLottie,
  projectsView,
  setProjectsView,
  deleteIds,
  setdeleteStatus,
  setDeleteIds,
}) => {
  const { logout, deleteAsset } = React.useContext(AuthContext);
  const { theme, setTheme } = useContext(MyContext);
  const elementsRef = useRef(null);
  const [lottieInstance, setLottieInstance] = useState(null);

  useEffect(() => {
    const animation = lottie.loadAnimation({
      container: elementsRef.current,
      renderer: "svg",
      animationData: FinalAnimation,
      autoplay: false,
      loop: false,
    });

    setLottieInstance(animation);

    return () => {
      if (lottieInstance) {
        lottieInstance.destroy();
      }
    };
  }, []);

  useEffect(() => {
    if (lottieInstance) {
      if (playLottie) {
        lottieInstance.setDirection(1);
        lottieInstance.play();
      } else {
        lottieInstance.setDirection(-1);
        lottieInstance.play();
      }
    }
  }, [playLottie, lottieInstance]);

  const deleteProjectAssets = async () => {
    const response = await deleteAsset(deleteIds);
    setdeleteStatus(response?.data?.message);
    toast(response?.data?.message);
    setDeleteIds([]);
  };

  return (
    <Box p="12px">
      <Flex justify="space-between" align="center" className="projectHeader">
        <Box cursor="pointer">
          {/*
          Use this when you want to use lottie file as logo all the function is written just uncomment the below code
           <Box className="logoImg" ref={elementsRef} /> */}
          <img src={Logo} alt="brand logo" />
        </Box>
        {/* <Flex className="projectSearchBar" backgroundColor="pr.200">
          <SearchIcon w="14px" h="14px" color="pr.300" />
          <Input type="text" placeholder="Search" />
          <Flex
            className="projectSearchBarLine"
            as="span"
            backgroundColor="icon.100"
            border="icborder.100"
            color="pr.300"
          >
            /
          </Flex>
        </Flex> */}
        <Flex className="projectHeaderContent">
          {/* <Text as="p" color="pr.300">
            Awaiting approval <Text as="span">(3)</Text>
            <Text as="span" className="dot"></Text>
          </Text>
          <Bell stroke="pr.300" color="transparent" /> */}
          {deleteIds.length ? (
            <button className="delete" onClick={deleteProjectAssets}>
              Delete
            </button>
          ) : (
            ""
          )}
          <Box
            cursor="pointer"
            onClick={() => {
              if (projectsView === "list") {
                setProjectsView("grid");
              }
            }}
          >
            <GridIconNew color="newPr.1600" />
          </Box>
          <Box
            cursor="pointer"
            onClick={() => {
              if (projectsView === "grid") {
                setProjectsView("list");
              }
            }}
          >
            <ListIconNew color="newPr.1600" />
          </Box>
          <Box
            onClick={() => {
              if (theme === "dark") {
                setTheme("light");
              } else {
                setTheme("dark");
              }
            }}
            cursor="pointer"
            className="projectHeaderThemeSwitch"
          >
            <Text backgroundColor="newPr.1100">
              <Text
                as="span"
                backgroundColor="newPr.1200"
                left={theme === "dark" ? "auto" : "2px"}
                right={theme === "dark" ? "2px" : "auto"}
              ></Text>
            </Text>
            {/* <ThemeIcon color="pr.300" /> */}
          </Box>
          {/* <Box disaply="flex">
            <Image src={Profile} alt="profile" />
          </Box> */}
          <Button
            onClick={logout}
            color="pr.300"
            _hover={{ backgroundColor: "pr.200" }}
          >
            Logout
          </Button>
        </Flex>
      </Flex>
      <ToastContainer />
    </Box>
  );
};

export default ProjectHeader;
