import { Box, Flex, Image, Text } from "@chakra-ui/react";
import React from "react";
import { useLottie } from "lottie-react";

import FinalAnimation1 from "../../assets/gifs/FF_logo_big_B_v02.json";
// import FinalAnimation1 from "../../assets/gifs/FF_logo_big_A_v02.json";
import Logo from "../../assets/images/final1.svg";

import "./landing.scss";

const LandingPageTestThree = () => {

  const options1 = {
    animationData: FinalAnimation1,
    loop: true
  };

  const { View } = useLottie(options1);

  return (
    <Flex className="landingPage">
      <Box w="200px">
        {/* <Image src={Logo} alt="Logo" /> */}
        {View}
      </Box>
      <Text as="h2">coming soon...</Text>
    </Flex>
  );
};

export default LandingPageTestThree;
