import React, { useEffect, useState } from "react";
import axios from "axios";
import { AuthContext } from "../context/AuthContext";

const BASE_URL = "https://app-api.finalfinal.one/api";

const useFinalAxiosWrapper = () => {
  const { token, logout } = React.useContext(AuthContext);

  const BASE_PARAMS = {
    headers: {
      Authorization: `bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  };

  const get = async (url, params) => {
    try {
      const response = await axios.get(BASE_URL + url, { params });
      return response;
    } catch (error) {
      if (error.response.data === "Not authorized") {
        logout();
      }
      throw error;
    }
  };

  const post = async (url, data, headers = BASE_PARAMS) => {
    try {
      const response = await axios.post(BASE_URL + url, data, headers);
      return response;
    } catch (error) {
      if (error.response.data === "Not authorized") {
        logout();
      }
      throw error;
    }
  };

  return { get, post };
};

export default useFinalAxiosWrapper;
