import React from "react";
import {
  Box,
  Button,
  Input,
  Spinner,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import useFinalAxiosWrapper from "../../utils/FinalApi";
import { CREATE_PROJECT_API } from "../../constants/url";

const AddProjectContainer = ({ onClose, fetchProjects }) => {
  const { post } = useFinalAxiosWrapper();
  const [projectName, setProjectName] = React.useState("");
  const [projectDescription, setProjectDescription] = React.useState("");
  const [ownerId, setOwnerId] = React.useState(
    "cc314db7-1d2b-4199-83f4-12622b044d20"
  );
  const [projectApiLoading, setProjectApiLoading] = React.useState(false);

  const onCloseClick = () => {
    onClose && onClose();
  };

  const onSubmitClick = async () => {
    setProjectApiLoading(true);
    await post(CREATE_PROJECT_API, {
      ownerId,
      name: projectName,
      description: projectDescription,
    }).then((res) => {
      setProjectApiLoading(false);
      fetchProjects();
      onCloseClick();
    });
    onClose && onClose();
  };

  const onProjectNameChange = (e) => {
    setProjectName(e.target.value);
  };

  const onProjectDescriptionChange = (e) => {
    setProjectDescription(e.target.value);
  };

  const onOwnderIdChange = (e) => {
    setOwnerId(e.target.value);
  };

  return (
    <Modal isCentered isOpen={true} onClose={onCloseClick}>
      <ModalOverlay
        bg="blackAlpha.300"
        backdropFilter="blur(10px) hue-rotate(90deg)"
      />
      <ModalContent
        background={"#141414"}
        color="#949598"
        className="green-medium"
        fontFamily={"GreedMedium"}
        borderRadius={"8px"}
        width={"40rem"}
      >
        <ModalHeader
          backgroundColor={"#949598"}
          height="200px"
          fontSize={"36px"}
          color="#141414"
          borderTopLeftRadius={"8px"}
          borderTopRightRadius={"8px"}
          fontWeight={600}
        >
          Add Project
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {projectApiLoading ? (
            <Box
              height="300px"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl"
              />
            </Box>
          ) : (
            <Box width="20rem">
              <Box p={2}>
                <Box margin="12px 0px" fontSize={"24px"} color="#949598">
                  Project Name
                </Box>
                <Input
                  border={"2px solid #949598"}
                  focusBorderColor="white"
                  type="text"
                  title="Project Name"
                  value={projectName}
                  onChange={onProjectNameChange}
                />
              </Box>
              <Box p={2}>
                <Box margin="12px 0px" fontSize={"24px"} color="#949598">
                  Project Description
                </Box>
                <Input
                  border={"2px solid #949598"}
                  focusBorderColor="white"
                  type="text"
                  title="Project Description"
                  value={projectDescription}
                  onChange={onProjectDescriptionChange}
                />
              </Box>
              <Box p={2}>
                <Box margin="12px 0px" fontSize={"24px"} color="#949598">
                  Project Owner Id
                </Box>
                <Input
                  border={"2px solid #949598"}
                  type="text"
                  title="Ownder Id"
                  disabled
                  value={ownerId}
                  onChange={onOwnderIdChange}
                />
              </Box>
            </Box>
          )}
        </ModalBody>

        <ModalFooter>
          <Button
            background="#be4916"
            color="white"
            mr={3}
            onClick={onSubmitClick}
          >
            Submit
          </Button>
          <Button variant="ghost" onClick={onCloseClick}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddProjectContainer;
