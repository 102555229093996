import React from 'react';
import { Box, Image } from '@chakra-ui/react';
import Logo from '../../assets/images/FF_Symbol1.svg';


const LoginHead = () => {
  return (
    <Box className='loginHeader'>
        <Image src={Logo} alt="logo" />
    </Box>
  )
}

export default LoginHead