import React, { useContext, useState } from "react";
import {
  Box,
  Text,
  Flex,
  Input,
  FormLabel,
  UnorderedList,
  ListItem,
  Image,
} from "@chakra-ui/react";
import {
  DownArrow,
  OptionsIcon,
  ReplyIcon,
  Send,
} from "../../components/Icons";
import moment from "moment";
import { SelectedVideoContext } from "../../context/SelectedVideoContext";
import CommentFilter from "../../components/Popups/CommentFilter";
import { MyContext } from "../../context";
import DeleteComment from "../../components/Popups/DeleteComment";
import Info from "../../components/Comments/Info";
import NoComments from "../../assets/images/review/comments.png";

const VideoComments = ({
  openComment,
  comments,
  setOpenMarkup,
  setSelectedMode,
  selectAnnotation,
  selectedAnnotation,
}) => {
  const {
    allComments,
    setSelectedComment,
    setSelectedCommentIndex,
    replyComment,
    setReplyComment,
    selectedComment,
    setNewComment,
    addReply,
    isPlaying,
    setIsPlaying,
  } = useContext(SelectedVideoContext);

  const { theme } = useContext(MyContext);

  const [switchCommentTabs, setSwitchCommentTabs] = useState(true);
  const [commentsFilter, setCommentsFilter] = useState(false);
  const [commentsOptions, setCommentsOptions] = useState(null);
  const [deleteComment, setDeleteComment] = useState(null);

  const resetNewAnnotation = () => {
    // setNewComment("");
    // setOpenMarkup(false);
    // setSelectedMode("default");
  };

  const closeCommentOption = () => {
    setTimeout(() => {
      setCommentsOptions(null);
    }, 20);
  };

  const onReplyInputChange = (e) => {
    setReplyComment(e.target.value);
  };

  return (
    <Box
      flex="1 0 auto"
      className={openComment ? "comments commentsActive" : "comments"}
      backgroundColor="pr.100"
      backdropFilter="blur(25px)"
      position="relative"
    >
      {openComment ? (
        <>
          {switchCommentTabs ? (
            <Box className="genComment">
              <Text color="pr.300">
                This is a general comment for this image. It will always remain
                here as this image is very important
              </Text>
            </Box>
          ) : (
            ""
          )}
          <Flex gap="4" className="commentsTab" backgroundColor="pr.100">
            <Text
              className={
                switchCommentTabs ? "commentsTitle" : "commentsTitle inActive"
              }
              as="h2"
              color="sec.100"
              onClick={() => setSwitchCommentTabs(true)}
            >
              Comments
            </Text>
            <Text
              className={
                switchCommentTabs ? "commentsTitle inActive" : "commentsTitle"
              }
              as="h2"
              color="sec.100"
              onClick={() => setSwitchCommentTabs(false)}
            >
              File info
            </Text>
          </Flex>
          <Box h="100%">
            {switchCommentTabs ? (
              allComments?.length ? (
                <>
                  <Flex
                    className="commentsFilter"
                    backgroundColor="pr.100"
                    color="pr.800"
                  >
                    <Flex position="relative">
                      <Flex
                        as="p"
                        onClick={() => setCommentsFilter(!commentsFilter)}
                      >
                        Filter & sort
                        <DownArrow w="9px" h="5px" color="#f2f4f1" />
                      </Flex>
                      {commentsFilter ? (
                        <CommentFilter setCommentsFilter={setCommentsFilter} />
                      ) : (
                        ""
                      )}
                    </Flex>

                    <Flex
                      as="p"
                      // backgroundColor="pr.600"
                    >
                      <label for="switch">Hide markers</label>
                      <input type="checkbox" id="switch" />
                      <FormLabel
                        borderColor="pr.800"
                        className={
                          theme === "dark"
                            ? "switchLable"
                            : "switchLable lightSwitch"
                        }
                        for="switch"
                      ></FormLabel>
                    </Flex>
                  </Flex>
                  <Flex className="commentsCardContainer">
                    {allComments.map((comment, index) => {
                      const onCommentSelect = () => {
                        setSelectedComment(comment);
                        setSelectedCommentIndex(allComments?.length - index);
                        resetNewAnnotation();
                      };
                      return (
                        <Flex
                          key={comment._id}
                          className="commentsCard"
                          // backgroundColor={
                          //   selectedComment?._id === comment._id
                          //     ? "pr.600"
                          //     : "pr.300"
                          // }
                          borderColor={
                            selectedComment?._id === comment._id
                              ? "#ff570e"
                              : "pr.600"
                          }
                          _hover={{ backgroundColor: "pr.600" }}
                          onClick={onCommentSelect}
                        >
                          <Flex alignItems="center" justify="space-between">
                            <Flex alignItems="center">
                              <Text className="commentsNumber">
                                {comment?.counter < 10
                                  ? `0${comment?.counter}`
                                  : comment?.counter}
                              </Text>
                              <Text color="pr.300">&nbsp;—&nbsp;</Text>
                              <Box pl="4px">
                                <Text
                                  className="commentsCardUser"
                                  color="sec.100"
                                >
                                  {comment?.createdBy?.firstName}{" "}
                                  {comment?.createdBy?.lastName}
                                </Text>
                                <Text
                                  className="commentsTimeStamp"
                                  color="sec.100"
                                >
                                  {moment(
                                    comment?.createdAt,
                                    "YYYYMMDD"
                                  ).fromNow()}
                                </Text>
                              </Box>
                            </Flex>
                            <Flex className="commentsOptions">
                              <Box>
                                <input type="checkbox" id={"tick" + index} />
                                <FormLabel
                                  borderColor="pr.800"
                                  className={
                                    theme === "dark" ? "" : "lightTick"
                                  }
                                  for={"tick" + index}
                                ></FormLabel>
                              </Box>
                              <Box position="relative">
                                <Box
                                  onClick={() => {
                                    if (commentsOptions === index) {
                                      setCommentsOptions(null);
                                    } else {
                                      setCommentsOptions(index);
                                    }
                                  }}
                                >
                                  <OptionsIcon color="pr.800" />
                                </Box>
                                {index === commentsOptions ? (
                                  <Box
                                    className="commentsOptionsPopup"
                                    backgroundColor="pr.100"
                                  >
                                    <UnorderedList>
                                      <ListItem
                                        color="pr.800"
                                        _hover={{
                                          backgroundColor: "prhover.200",
                                        }}
                                        onClick={closeCommentOption}
                                      >
                                        Edit
                                      </ListItem>
                                      <ListItem
                                        color="pr.800"
                                        _hover={{
                                          backgroundColor: "prhover.200",
                                        }}
                                        onClick={() =>
                                          setTimeout(() => {
                                            setDeleteComment(index);
                                            setCommentsOptions(null);
                                          }, 20)
                                        }
                                      >
                                        Delete
                                      </ListItem>
                                    </UnorderedList>
                                  </Box>
                                ) : (
                                  ""
                                )}
                              </Box>
                            </Flex>
                          </Flex>
                          <Text className="commentsDescription" color="sec.200">
                            {comment?.comment}
                          </Text>

                          {comment?.replies?.map((thread) => {
                            return (
                              <Flex flexDirection="column" p={4}>
                                <Flex flexDirection="column">
                                  <Flex align="center" pb="8px">
                                    <ReplyIcon />
                                    <Box className="commentsCardReplyUser">
                                      <Text color="sec.100">
                                        {thread?.createdBy?.firstName}{" "}
                                        {thread?.createdBy?.lastName}
                                      </Text>
                                      <Text as="span" color="sec.100">
                                        2 hours ago
                                      </Text>
                                    </Box>
                                  </Flex>
                                  <Text
                                    className="commentsReplyDescription"
                                    color="sec.200"
                                  >
                                    {thread?.comment}
                                  </Text>
                                </Flex>
                              </Flex>
                            );
                          })}

                          {deleteComment === index ? (
                            <DeleteComment
                              setDeleteComment={setDeleteComment}
                            />
                          ) : (
                            ""
                          )}

                          {selectedComment?._id === comment?._id ? (
                            <Flex
                              className="commentsInputContainer"
                              backgroundColor="sec.500"
                            >
                              <Input
                                type="text"
                                value={replyComment}
                                placeholder="Enter new reply"
                                color="pr.500"
                                _placeholder={{ color: "sec.400" }}
                                onChange={(e) =>
                                  setReplyComment(e.target.value)
                                }
                              />
                              <Send
                                w="9px"
                                h="8px"
                                color="pr.400"
                                cursor="pointer"
                                onClick={() => {
                                  if (replyComment) {
                                    addReply();
                                  }
                                }}
                              />
                            </Flex>
                          ) : (
                            <Flex
                              className="commentsReply"
                              as="p"
                              // backgroundColor="pr.600"
                              color="sec.100"
                              _hover={{ backgroundColor: "pr.100" }}
                              onClick={() => {
                                setSelectedComment(comment);
                              }}
                            >
                              Reply &nbsp;&nbsp; ↖
                              {/* <Send w="9px" h="8px" color="sec.100" /> */}
                            </Flex>
                          )}
                        </Flex>
                      );
                    })}
                  </Flex>
                </>
              ) : (
                <Flex className="noComments">
                  <Image src={NoComments} alt="no comments" />
                  <Text as="p" color="pr.300">
                    No comments yet. Click on the image to add your comment
                  </Text>
                </Flex>
              )
            ) : (
              <Info />
            )}
          </Box>
        </>
      ) : (
        ""
      )}
    </Box>

    // <Box
    //   flex="1 0 auto"
    //   className={openComment ? "fadeInComments" : "initialComments"}
    //   overflow="hidden"
    //   backgroundColor="pr.300"
    //   backdropFilter="blur(25px)"
    // >
    //   <Flex gap="4">
    //     <Text
    //       className="commentsTitle"
    //       as="h2"
    //       color="var(--chakra-colors-gray-500)"
    //     >
    //       Comments
    //     </Text>
    //     <Text
    //       className="commentsTitle inActive"
    //       as="h2"
    //       color="var(--chakra-colors-gray-500)"
    //     >
    //       Info
    //     </Text>
    //   </Flex>
    //   <Box>
    //     <Flex
    //       className="commentsDropdown"
    //       as="p"
    //       // backgroundColor="pr.600"
    //       color="var(--chakra-colors-gray-500)"
    //       _hover={{ backgroundColor: "#AFAFAF" }}
    //     >
    //       Latest{" "}
    //       <DownArrow w="9px" h="5px" color="var(--chakra-colors-gray-500)" />
    //     </Flex>
    //     <Flex className="commentsCardContainer">
    //       {allComments.map((comment, index) => {
    //         const onCommentSelect = () => {
    //           setSelectedComment(comment);
    //           setSelectedCommentIndex(allComments.length - index);
    //           setIsPlaying(false);
    //         };
    //         return (
    //           <Flex
    //             key={comment._id}
    //             className="commentsCard"
    //             backgroundColor={
    //               selectedComment?._id === comment._id ? "pr.600" : "pr.300"
    //             }
    //             borderColor={
    //               selectedComment?._id === comment._id ? "#ff570e" : "pr.600"
    //             }
    //             _hover={{ backgroundColor: "pr.600" }}
    //             onClick={onCommentSelect}
    //           >
    //             <Flex alignItems="center" justify="space-between">
    //               <Flex alignItems="center">
    //                 <Text className="commentsNumber">
    //                   {comment?.counter < 10
    //                     ? `0${comment?.counter}`
    //                     : comment?.counter}
    //                 </Text>
    //                 <Text>&nbsp;—&nbsp;</Text>
    //                 <Text
    //                   className="commentsCardUser"
    //                   color="var(--chakra-colors-gray-500)"
    //                 >
    //                   {comment?.createdBy?.firstName}{" "}
    //                   {comment?.createdBy?.lastName}
    //                 </Text>
    //               </Flex>
    //               <Text
    //                 className="commentsTimeStamp"
    //                 color="var(--chakra-colors-gray-500)"
    //               >
    //                 {moment(comment?.createdAt, "YYYYMMDD").fromNow()}
    //                 {/* 5 hours ago */}
    //               </Text>
    //             </Flex>
    //             <Text className="commentsDescription" color="grey.700">
    //               {comment?.comment}
    //             </Text>

    //             {comment?.replies?.map((thread, replyIndex) => {
    //               return (
    //                 <Flex
    //                   flexDirection="column"
    //                   p={4}
    //                   key={`${comment._id}-reply-${replyIndex}`}
    //                 >
    //                   <Flex flexDirection="column" m={2}>
    //                     <Text
    //                       className="commentsCardUser"
    //                       color="var(--chakra-colors-gray-500)"
    //                     >
    //                       {thread?.createdBy?.firstName}{" "}
    //                       {thread?.createdBy?.lastName}
    //                     </Text>
    //                     <Text className="commentsDescription" color="grey.700">
    //                       {thread?.comment}
    //                     </Text>
    //                   </Flex>
    //                 </Flex>
    //               );
    //             })}

    //             {selectedComment?._id === comment?._id ? (
    //               <Flex
    //                 className="commentsInputContainer"
    //                 backgroundColor="pr.600"
    //               >
    //                 <Input
    //                   type="text"
    //                   value={replyComment}
    //                   placeholder="Enter new reply"
    //                   onChange={onReplyInputChange}
    //                 />
    //                 <Send
    //                   w="9px"
    //                   h="8px"
    //                   color="pr.400"
    //                   cursor="pointer"
    //                   onClick={() => {
    //                     if (replyComment) {
    //                       addReply();
    //                     }
    //                   }}
    //                 />
    //               </Flex>
    //             ) : (
    //               <Flex
    //                 className="commentsReply"
    //                 as="p"
    //                 // backgroundColor="pr.600"
    //                 color="var(--chakra-colors-gray-500)"
    //                 _hover={{ backgroundColor: "grey.600" }}
    //                 onClick={() => {
    //                   setSelectedComment(comment);
    //                 }}
    //               >
    //                 Reply &nbsp;&nbsp; ↖
    //                 {/* <Send w="9px" h="8px" color="var(--chakra-colors-gray-500)" /> */}
    //               </Flex>
    //             )}
    //           </Flex>
    //         );
    //       })}
    //     </Flex>
    //   </Box>
    // </Box>
  );
};

export default VideoComments;
