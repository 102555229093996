import { Box, Button, Text } from "@chakra-ui/react";
import React from "react";

const DeleteComment = ({setDeleteComment}) => {

    const closeDeleteModal = () => { 
        setDeleteComment(null)
     }
  return (
    <Box className="deleteComment">
      <Box className="deleteCommentCard">
        <Text as="p" color="pr.800">
          Are you sure? <br /> This comment thread will be permanently deleted,
          including any replies and attachments.
        </Text>
        <Box className="deleteCommentBtns">
            <Button _hover={{backgroundColor:"pr.100"}} color="pr.800" onClick={closeDeleteModal} className="cancel">Cancel</Button>
            <Button _hover={{backgroundColor:"pr.100"}} onClick={closeDeleteModal} className="delete">Delete comment</Button>
        </Box>
      </Box>
    </Box>
  );
};

export default DeleteComment;
