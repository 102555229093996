import React, { useEffect } from "react";
import { Box, Image } from "@chakra-ui/react";
import { useState } from "react";
import ActionPanel from "./ActionPanel";
import ReviewAsset from "./ReviewAsset";
import ReviewHeader from "./ReviewHeader";
import axios from "axios";
import { v1 as uuid } from "uuid";
import { useParams } from "react-router";
import { NewAnnotationProvider } from "../../context/NewAnnotationContext";
import { ProjectContext } from "../../context/ProjectContext";
import { SelectedImageContext } from "../../context/SelectedImageContext";
import Logo from "../../assets/images/ff_Symbol.svg";

import "./review.scss";

const Review = () => {
  const { setSelectedAssetId } = React.useContext(ProjectContext);
  const {
    imageLoading,
    setImageLoading,
    annotationLoading,
    setAnnotationsLoading,
    commentsLoading,
    setCommentsLoading,
    zoom,
    setZoom,
    allComments,
  } = React.useContext(SelectedImageContext);
  const { imageId } = useParams();

  React.useEffect(() => {
    setSelectedAssetId(imageId);
  }, []);

  const [openMarkup, setOpenMarkup] = useState(false);
  const [openComment, setOpenComment] = useState(true);

  // annotation states
  const [latestAnnotation, setLatestAnnotation] = useState(null);
  const [annotations, setAnnotations] = useState([]);
  const [newAnnotation, setNewAnnotation] = useState([]);
  const [selectedId, selectAnnotation] = useState(null);
  // const [allComments, setAllComments] = useState([]);
  const [assetDetails, setAssetDetails] = useState(null);
  const [projectsView, setProjectsView] = useState("list");
  const addReply = (newComment) => {
    // addComment({
    //   comment: newComment.comment,
    //   annotation: newComment.annotation,
    //   parentId: newComment.id,
    // });
    return;
    // setAllComments(updatedAllComments);
  };


  return (
    <>
      <Box background="pr.100">
        <ReviewHeader
          projectsView={projectsView}
          title={assetDetails?.name || assetDetails?._id}
        />
        {/* <ActionPanel
          zoom={zoom}
          setZoom={setZoom}
          setOpenComment={setOpenComment}
          openComment={openComment}
        /> */}
        <NewAnnotationProvider>
          <ReviewAsset
            setProjectsView={setProjectsView}
            projectsView={projectsView}
            imageLoading={imageLoading}
            setLatestAnnotation={setLatestAnnotation}
            annotations={annotations}
            setAnnotations={setAnnotations}
            newAnnotation={newAnnotation}
            setNewAnnotation={setNewAnnotation}
            selectedId={selectedId}
            selectAnnotation={selectAnnotation}
            assetDetails={assetDetails}
            setOpenMarkup={setOpenMarkup}
            openMarkup={openMarkup}
            openComment={openComment}
            addReply={addReply}
            // comments={allComments}
            selectedAnnotation={selectedId}
            setOpenComment={setOpenComment}
          />
        </NewAnnotationProvider>
      </Box>
    </>
  );
};

export default Review;
