import React, { createContext, useState } from "react";
import axios from "axios";
import { v1 as uuid } from "uuid";
import { ProjectContext } from "./ProjectContext";
import { SelectedImageContext } from "./SelectedImageContext";
import useFinalAxiosWrapper from "../utils/FinalApi";
import { CREATE_COMMENT_API, CREATE_ANNOTATION_API } from "../constants/url";

export const NewAnnotationContext = createContext();

export const NewAnnotationProvider = ({ children }) => {
  const { post } = useFinalAxiosWrapper();
  const { getImageComments, getAnnotations, setSelectedAnnotation } =
    React.useContext(SelectedImageContext);
  const { selectedAssetId } = React.useContext(ProjectContext);
  const [arrowAnnotation, setArrowAnnotation] = useState([]);
  const [lines, setLines] = React.useState([]);
  const [markers, setMarkers] = useState([]);
  const [newComment, setNewComment] = useState(null);
  const [selectedColor, setSelectedColor] = useState("#FF0000");

  const addComment = async (annotaionId) => {
    let commentObj = {
      assetId: selectedAssetId,
      annotationId: annotaionId || null,
      comment: newComment,
      createdBy: "cc314db7-1d2b-4199-83f4-12622b044d20",
      replies: [],
    };
    if (newComment) {
      await post(CREATE_COMMENT_API, commentObj).then(() => {
        setSelectedAnnotation(null);
        setMarkers([]);
        setNewComment(null);
        setLines([]);
        setArrowAnnotation([]);
        getImageComments();
        getAnnotations();
      });
    }
  };

  const addAnnotation = async () => {
    if (newComment) {
      const arrowAnnotations = arrowAnnotation.map((arrow) => {
        return arrow.points;
      });
      const markerPos = markers[0].position;
      const response = await post(CREATE_ANNOTATION_API, {
        assetId: selectedAssetId,
        createdBy: "8da9da6b-5bcc-424c-b677-338c8e21942f",
        position: markerPos,
        annotation: {
          lines: lines,
          arrows: { points: arrowAnnotations },
        },
        _id: uuid(),
      });
      const annotationData = await response.data;
      addComment(annotationData._id);
    }
  };

  return (
    <NewAnnotationContext.Provider
      value={{
        arrowAnnotation,
        setArrowAnnotation,
        lines,
        setLines,
        newComment,
        setNewComment,
        markers,
        setMarkers,
        addAnnotation,
        setSelectedColor,
        selectedColor,
      }}
    >
      {children}
    </NewAnnotationContext.Provider>
  );
};
