//Login success page to extract token from url
import React from "react";
import { Flex, Text } from "@chakra-ui/react";
import { useParams } from "react-router";
import { AuthContext } from "../context/AuthContext";
import { useNavigate } from "react-router";

const LoginSuccess = () => {
  const navigate = useNavigate();
  const { setAuthToken } = React.useContext(AuthContext);
  const { token } = useParams();

  React.useEffect(() => {
    setAuthToken(token);
    setTimeout(() => {
      navigate("/projects");
    }, 1000);
  }, [token]);
  return (
    <Flex
      w="100%"
      h="100vh"
      justifyContent="center"
      alignItems="center"
      background="#0D0D0D"
    >
      <Text as="h1" color="#fff">
        Login Success
      </Text>
    </Flex>
  );
};

export default LoginSuccess;
