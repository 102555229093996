import { Box, Flex, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { LeftArrow, LinkIcon } from "../Icons";

import "./projectReviewCard.scss";

const ProjectReviewCard = () => {
  const [showReviewer, setShowReviewer] = useState(false);
  return (
    <Box className="projectReviewCard" backgroundColor="newPr.1100">
      {/* <Text opacity={showReviewer ? "1" : "0"}>Test reviewer</Text>
      <Flex className="projectReviewCardLevels">
        <Text
          onMouseEnter={() => setShowReviewer(true)}
          onMouseLeave={() => setShowReviewer(false)}
          as="span"
          className="green"
        ></Text>
        <Text
          onMouseEnter={() => setShowReviewer(true)}
          onMouseLeave={() => setShowReviewer(false)}
          as="span"
          className="green"
        ></Text>
        <Text
          onMouseEnter={() => setShowReviewer(true)}
          onMouseLeave={() => setShowReviewer(false)}
          as="span"
          className="orange"
        ></Text>
        <Text
          onMouseEnter={() => setShowReviewer(true)}
          onMouseLeave={() => setShowReviewer(false)}
          as="span"
          className="orange"
        ></Text>
        <Text
          onMouseEnter={() => setShowReviewer(true)}
          onMouseLeave={() => setShowReviewer(false)}
          as="span"
          className="grey"
        ></Text>
      </Flex> */}
      {/* <Flex className="projectReviewCardState projectReviewCardInReview">
        In review
      </Flex>
      <Flex className="projectReviewCardState projectReviewCardAwaiting">
        Awaiting my review <LeftArrow />{" "}
      </Flex> */}
      {/* <Flex className="projectReviewCardDue" color="#585858">
        Due: 23 Apr, 2023
        <LinkIcon color="#585858" />
      </Flex> */}
    </Box>
  );
};

export default ProjectReviewCard;
