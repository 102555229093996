import React, { useEffect, useRef } from "react";
import videojs from "video.js";
import 'videojs-thumbnail-sprite';
import 'videojs-marker-plugin';
import videoJsContribQualityLevels from 'videojs-contrib-quality-levels';
import videoJsHlsQualitySelector from 'videojs-hls-quality-selector'

// Videojs skins
import "video.js/dist/video-js.css";
import "videojs-marker-plugin/dist/videojs-marker-plugin.css"
import '../../theme/videojs/fantasy/index.css'

export const VideoPlayer = (props) => {
  const refVideo = useRef(null);
  const refPlayer = useRef(null);
  const { options, onReady } = props;
  
  
  useEffect(() => {
    //
    videojs.registerPlugin('qualityLevel', videoJsContribQualityLevels)
    videojs.registerPlugin('hlsQualitySelector', videoJsHlsQualitySelector)
    //
    // Make sure Video.js player is only initialized once
    if (!refPlayer.current) {
      // Create parent div
      let parentVideoElement = document.createElement("div");
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
      const videoElement = document.createElement("video-js");
      // Append the div to the component el.
      parentVideoElement.appendChild(videoElement);
      // This ensures that the Video.js player is responsive and centered.
      parentVideoElement.style.width = options.width+'px';
      parentVideoElement.style.height = options.height+'px';
      parentVideoElement.style.display = "flex";
      parentVideoElement.style.justifyContent = "center";
      parentVideoElement.style.alignItems = "center";
      parentVideoElement.style.opacity = "0";
      // Set video element attributes
      videoElement.style.width = "100%";
      videoElement.style.height = "100%";
      videoElement.classList.add("vjs-big-play-centered");
      videoElement.classList.add("vjs-theme-fantasy");
      refVideo.current.appendChild(parentVideoElement);
      // add padding to refVideo
      refVideo.current.style.paddingBottom = "56px";
      const player = (refPlayer.current = videojs(videoElement, options, () => {
        videojs.log("player is ready");
        //player.qualityLevel();
        //player.src(options.sources);
        onReady && onReady(player);
      }));
      //
    } 
    // Update an existing player in the `else` block here
    else {
      let player = refPlayer.current;
      let video = refVideo.current;
      let parentVideoElement = video.firstChild;
      if(parentVideoElement)
        parentVideoElement.style.width = options.width+'px';
    }
  }, [options, refVideo]);

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    const player = refPlayer.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        refPlayer.current = null;
      }
    };
  }, [refPlayer]);

  return (
    <div data-vjs-player>
      <div ref={refVideo} />
    </div>
  );
};

export default VideoPlayer;
