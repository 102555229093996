import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { MyContext } from "../../context";
import {
  Box,
  Button,
  Flex,
  Image,
  ListItem,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import {
  AddIcon,
  AddPlusIcon,
  AssetSortIcon,
  GridviewIcon,
  LeftArrow,
  LinkIcon,
  ListviewIcon,
  PlusSvg,
  ShareIcon,
  SortIcon,
} from "../../components/Icons";

import { useGSAP } from "@gsap/react";
import ProjectCard from "../../components/ProjectCard";
import ProjectSidebar from "./ProjectSidebar";
import Profile from "../../assets/images/projects/profilePic.svg";
import Tick from "../../assets/images/projects/tick.svg";
import ProjectReviewCard from "../../components/ProjectReviewCard";
import { DownloadIcon } from "@chakra-ui/icons";
import ProjectGridView from "../../components/ProjectGridView";
import { Share } from "tabler-icons-react";
import AssetItem from "../projectView/AssetItem";
import ReactCursorPosition from "react-cursor-position";
import { AuthContext } from "../../context/AuthContext";

import ProjectAssetCard from "../../components/ProjectAssetCard/ProjectAssetCard";

const ProjectBody = ({
  setPlayLottie,
  playLottie,
  projects,
  setSelectedProject,
  assets,
  onAssetClick,
  showImageUpload,
  setShowImageUpload,
  selectedProject,
  setdeleteStatus,
  projectsView,
  setProjectsView,
  deleteIds,
  setDeleteIds,
}) => {
  const [selectedFilter, setSelectedFilter] = useState({
    name: "",
    count: null,
  });

  const listAssetsContainer = useRef();
  const reviewStepsRef = useRef(null);
  const headingContainerRef = useRef(null);
  const gridAssetsBody = useRef();

  const [collapseSidebar, setCollapseSidebar] = useState(false);
  const [openFilter, setOpenFilter] = useState(true);
  const [deleteSelection, setDeleteSelection] = useState(false);
  // const [slideCardContent, setSlideCardContent] = useState(false);

  const { staggeredCards } = useContext(MyContext);

  useGSAP(
    () => {
      if (assets) {
        staggeredCards(".projectAssetCard");
        staggeredCards(".projectsReviewBox", -0.015);
      }
    },
    [projectsView, assets],
    listAssetsContainer
  );

  const handleKeyPress = (event) => {
    if (event.keyCode === 71 && event.target.tagName !== "INPUT") {
      if (projectsView === "list") {
        setProjectsView("grid");
      } else if (projectsView === "grid") {
        setProjectsView("list");
      }
    }

    if (
      (event.key === "f" || event.key === "F") &&
      event.target.tagName !== "INPUT"
    ) {
      if (collapseSidebar) {
        setCollapseSidebar(false);
        setPlayLottie(false);
      } else {
        setCollapseSidebar(true);
        setPlayLottie(true);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [projectsView, collapseSidebar]);

  const handleSelectFilter = (val, length) => {
    setOpenFilter(false);
    setSelectedFilter({ name: val, count: length });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const projectCards = document.querySelectorAll(".projectCard");
      const clickedInsideProjectCard = Array.from(projectCards).some((card) =>
        card.contains(event.target)
      );

      if (!clickedInsideProjectCard) {
        setDeleteIds([]);
      }
    };

    // Add the event listener when the component mounts
    document.addEventListener("click", handleClickOutside);

    return () => {
      // Remove the event listener when the component unmounts
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleCommandKeyDown = (e) => {
      if (e.keyCode === 91) {
        setDeleteSelection(true);
      }
    };

    const handleCommandKeyUp = (e) => {
      if (e.keyCode === 91) {
        setDeleteSelection(false);
      }
    };

    // Attach the event listener
    window.addEventListener("keydown", handleCommandKeyDown);
    window.addEventListener("keyup", handleCommandKeyUp);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("keydown", handleCommandKeyDown);
      window.removeEventListener("keyup", handleCommandKeyUp);
    };
  }, []);

  const handleDeleteIds = (id) => {
    setdeleteStatus("");
    setDeleteIds((prev) =>
      deleteIds.includes(id) ? prev.filter((cur) => cur !== id) : [...prev, id]
    );
  };

  useEffect(() => {
    const headingContainer = headingContainerRef.current;
    const boxContainer = reviewStepsRef.current;

    if (!headingContainer || !boxContainer) return;

    const syncScroll = (source, target) => {
      target.scrollLeft = source.scrollLeft;
    };

    const handleHeadingScroll = () =>
      syncScroll(headingContainer, boxContainer);
    const handleBoxScroll = () => syncScroll(boxContainer, headingContainer);

    headingContainer.addEventListener("scroll", handleHeadingScroll);
    boxContainer.addEventListener("scroll", handleBoxScroll);

    return () => {
      headingContainer.removeEventListener("scroll", handleHeadingScroll);
      boxContainer.removeEventListener("scroll", handleBoxScroll);
    };
  }, [projects, projectsView]);

  return (
    <Flex className="projects">
      <ProjectSidebar
        projects={projects}
        setSelectedProject={setSelectedProject}
        setCollapseSidebar={setCollapseSidebar}
        collapseSidebar={collapseSidebar}
        setOpenFilter={setOpenFilter}
        openFilter={openFilter}
        setProjectsView={setProjectsView}
        projectsView={projectsView}
      />
      <Flex
        className={
          collapseSidebar
            ? "projectsContentContainer projectsContentContainerSmall"
            : "projectsContentContainer"
        }
      >
        <Box className="projectsBody" ref={gridAssetsBody}>
          <Flex
            className={openFilter ? "breadCrum activeBreadcrum" : "breadCrum"}
            backgroundColor="newPr.200"
          >
            <Text color="newPr.1500" as="h2">
              {selectedProject ? selectedProject?.name : "loading..."}
            </Text>
            <UnorderedList>
              <ListItem color="newPr.100" className="assetSort">
                Newest File
                <Text>
                  <AssetSortIcon color="newPr.1600" />
                </Text>
              </ListItem>
              <ListItem
                color="newPr.1600"
                onClick={() => handleSelectFilter("Exterior", "10")}
              >
                All
              </ListItem>
              <ListItem
                color="newPr.1600"
                onClick={() => handleSelectFilter("Exterior", "10")}
              >
                Exterior
                <sup> 10</sup>
              </ListItem>
              <ListItem
                color="newPr.1700"
                onClick={() => handleSelectFilter("Interior", "10")}
              >
                Interior
                <sup> 10</sup>
              </ListItem>
              <ListItem
                color="newPr.1600"
                onClick={() => handleSelectFilter("360", "10")}
              >
                Profile
                <sup> 10</sup>
              </ListItem>
              <ListItem className="addIconFilter" p="0">
                <Flex
                  className="addIcon"
                  p="6px"
                  borderRadius="50%"
                  backgroundColor="rgba(28, 28, 28, 0.50)"
                  // _hover={{
                  //   backgroundColor:"rgba(28, 28, 28, 0.40)"
                  // }}
                >
                  <PlusSvg color="#ffffff20" />
                </Flex>
                {/* <span>&nbsp;</span> */}
              </ListItem>
            </UnorderedList>
            {/* <UnorderedList>
            </UnorderedList> */}
          </Flex>

          {projectsView === "list" ? (
            <>
              <Flex className="projectsReviewSteps" backgroundColor="newPr.200">
                <Flex className="projectsFiles" minW={412}></Flex>
                <Flex className="projectsReview" ref={headingContainerRef}>
                  <Flex className="projectsReviewBox" opacity={1}>
                    <Box className="projectsReviewTitle">
                      <Flex as="h4" color="newPr.100">
                        Review step 2{" "}
                        <Text as="span">
                          <LinkIcon color="newPr.100" />
                        </Text>
                      </Flex>
                    </Box>

                    <Box className="projectsReviewTitle">
                      <Flex as="h4" color="newPr.100">
                        Review step 2{" "}
                        <Text as="span">
                          <LinkIcon color="newPr.100" />
                        </Text>
                      </Flex>
                    </Box>

                    <Box className="projectsReviewTitle">
                      <Flex as="h4" color="newPr.100">
                        Review step 2{" "}
                        <Text as="span">
                          <LinkIcon color="newPr.100" />
                        </Text>
                      </Flex>
                    </Box>

                    <Box className="projectsReviewTitle">
                      <Flex as="h4" color="newPr.100">
                        Review step 2{" "}
                        <Text as="span">
                          <LinkIcon color="newPr.100" />
                        </Text>
                      </Flex>
                    </Box>

                    <Box className="projectsReviewTitle">
                      {" "}
                      <Flex as="h4" color="newPr.100">
                        Review step 2{" "}
                        <Text as="span">
                          <LinkIcon color="newPr.100" />
                        </Text>
                      </Flex>
                    </Box>
                  </Flex>
                </Flex>
              </Flex>
              <Flex ref={listAssetsContainer} pt={2}>
                <Flex
                  className="projectsFiles"
                  // maxWidth={slideCardContent ? "332px" : "494px"}
                >
                  {/* <Flex as="h4" className="projectsFilesTitle">
                  Name{" "}
                  <Text as="span">
                    <SortIcon />
                  </Text>
                </Flex> */}

                  {assets.map((folder, index) => {
                    const { thumbnailCount } = folder;
                    if (thumbnailCount > 1) {
                      return (
                        <Box
                          className={
                            deleteIds.includes(folder._id)
                              ? "projectCard projectAssetCard projectCardSelected"
                              : "projectCard projectAssetCard"
                          }
                          onClick={
                            deleteSelection
                              ? () => handleDeleteIds(folder._id)
                              : () => console.log("test")
                          }
                        >
                          <ReactCursorPosition key={folder._id}>
                            <ProjectAssetCard
                              index={index}
                              folder={folder}
                              onAssetClick={onAssetClick}
                              // slideCardContent={slideCardContent}
                            />
                          </ReactCursorPosition>
                        </Box>
                      );
                    } else {
                      return (
                        <Box
                          className={
                            deleteIds.includes(folder._id)
                              ? "projectCard projectAssetCard projectCardSelected"
                              : "projectCard projectAssetCard"
                          }
                          onClick={
                            deleteSelection
                              ? () => handleDeleteIds(folder._id)
                              : () => console.log("test")
                          }
                        >
                          <ProjectAssetCard
                            key={folder._id}
                            index={index}
                            folder={folder}
                            onAssetClick={onAssetClick}
                            // slideCardContent={slideCardContent}
                          />
                        </Box>
                      );
                    }
                  })}
                </Flex>
                <Flex className="projectsReview" ref={reviewStepsRef}>
                  {assets.map((folder, i) => {
                    return (
                      <Flex
                        className="projectsReviewBox"
                        // height={`${folder?.singleThumbnailSize?.height}px`}
                        h="196px"
                      >
                        <Flex className="projectsReviewContent">
                          <Flex
                            className="projectsReviewApproved"
                            backgroundColor="newPr.1100"
                            // border="prborder.300"
                          >
                            {/* <Image src={Tick} alt="Tick" /> */}
                            <Text as="p">Approved</Text>
                          </Flex>
                        </Flex>
                        <Flex className="projectsReviewContent">
                          <Flex className="projectsReviewContentCards">
                            <ProjectReviewCard />
                            {/* <ProjectReviewCard /> */}{" "}
                          </Flex>
                        </Flex>
                        <Flex className="projectsReviewContent">
                          <Flex className="projectsReviewContentCards">
                            <ProjectReviewCard />
                            {/* <ProjectReviewCard /> */}
                          </Flex>
                        </Flex>
                        <Flex className="projectsReviewContent">
                          <Flex className="projectsReviewContentCards">
                            <ProjectReviewCard />
                          </Flex>
                        </Flex>
                        <Flex className="projectsReviewContent">
                          <Flex className="projectsReviewContentCards">
                            <ProjectReviewCard />
                          </Flex>
                        </Flex>
                      </Flex>
                    );
                  })}
                </Flex>
              </Flex>
            </>
          ) : (
            ""
          )}
          {projectsView === "grid" ? (
            <ProjectGridView
              assets={assets}
              onAssetClick={onAssetClick}
              showImageUpload={showImageUpload}
              setShowImageUpload={setShowImageUpload}
              deleteIds={deleteIds}
              setDeleteIds={setDeleteIds}
              setdeleteStatus={setdeleteStatus}
              deleteSelection={deleteSelection}
              setDeleteSelection={setDeleteSelection}
              gridAssetsBody={gridAssetsBody}
            />
          ) : (
            ""
          )}
        </Box>
      </Flex>
    </Flex>
  );
};

export default ProjectBody;
