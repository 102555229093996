import React, { createContext, useState } from "react";

export const ProjectContext = createContext();

export const ProjectContextProvider = ({ children }) => {
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedAssetId, setSelectedAssetId] = useState(null);
  const [selectedAsset, setSelectedAsset] = React.useState(null);
  const [allProjects, setAllProjects] = useState(null);
  return (
    <ProjectContext.Provider
      value={{
        selectedProject,
        setSelectedProject,
        selectedAssetId,
        setSelectedAssetId,
        allProjects,
        setAllProjects,
        selectedAsset,
        setSelectedAsset,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};
