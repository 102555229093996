import { extendTheme } from "@chakra-ui/react";
import { createBreakpoints } from "@chakra-ui/theme-tools";

const breakpoints = createBreakpoints({
  sm: "32em",
  md: "48em",
  lg: "62em",
  xl: "80em",
  "2xl": "96em",
});

const colors = {
  pr: {
    100: "#EEEDED",
    200: "#dfe2e2",
    300: "#363435",
    400: "#ff570f",
    500: "#696B6E",
    600: "#dfe2e2",
    700: "#939598",
    800: "#363435",
    900: "#dfe2e2",
    1000: "#EEEDED",
  },
  sec: {
    100: "#363435",
    200: "#363435",
    300: "#d1d1d1",
    400: "#939598",
    500: "#d1d1d1",
  },
  icon: {
    100: "transparent",
    200: "transparent",
  },
  prhover: {
    100: "#000000",
    200: "#dfe2e2",
  },
  newPr: {
    100: "#696B6E",
    // 200: "#EEEDED",
    200: "#f8f8f8",
    300: "#363435",
    400: "#E5E5E5",
    500: "#9A9A9A",
    600: "#ffffff",
    700: "#BCBCBC",
    800: "#9A9A9A",
    900: "#000000",
    // 900 : "#808080",
    // 1000: "#808080",
    1000 : "#3f3f3f",
    // 1100 : "#E5E5E5",
    1100: "#e9e9e9",
    1200: "#ffffff",
    1300: "#4d4d4d",
    1400: "#DBDBDB",
    1500: "#4d4d4d",
    1600: "#999999",
    1700: "#1F1F1F",
  },
};

const borders = {
  prborder: {
    100: "0.3px solid #d1d1d1",
    200: "0.3px solid #363435",
    300: "0.3px solid #363435",
  },
  icborder: {
    100: "0.3px solid #d1d1d1",
    200: "0.3px solid #363435",
  },
};

const sizes = {};

export const lightTheme = extendTheme({
  colors,
  sizes,
  borders,
  components: { Button: { baseStyle: { _focus: { boxShadow: "none" } } } },
  shadows: { outline: "0 !important" },
  config: {
    useSystemColorMode: false,
    initialColorMode: "light",
  },
  breakpoints,
  fonts: {
    heading: "Raleway",
    body: "Raleway",
  },
});
